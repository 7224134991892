<div class="overflow-x-hidden overflow-y-hidden">
  <div class="w-[100%] h-[3%] p-4" id="searchpart">
    <form [formGroup]="inverterForm" class="w-72" (submit)="callAlert()">
      <div class="w-[100%] flex">
        <div class="h-[90%] w-[95%]">
          <input
            type="text"
            placeholder="Enter Inverter ID"
            class="rounded-md p-2 w-[100%] white border-none"
            #inverterId
            formControlName="inverterId"
            (input)="inverterId.value = inverterId.value.toUpperCase()"
            maxlength="14"
          />
          <div
            *ngIf="
              inverterForm.controls.inverterId.invalid &&
              inverterForm.controls.inverterId.touched
            "
          >
            <span
              *ngIf="inverterForm.controls.inverterId.errors?.['required']"
              class="text-red-500 text-xs"
            >
              Serial no is required
            </span>
            @if (inverterForm.controls.inverterId.errors?.["pattern"]) {
              <span class="text-red-500 text-xs">
                Serial must be alphanumeric only
              </span>
            } @else if (
              inverterForm.controls.inverterId.errors?.["minlength"] ||
              inverterForm.controls.inverterId.errors?.["maxlength"]
            ) {
              <span class="text-red-500 text-xs">
                Serial number must be 14 digits
              </span>
            }
          </div>
        </div>

        <div class="h-[60%] ml-[7%] w-[40%]">
          <button
            class="rounded-lg p-2 w-[100%] border-none bg-sky-600 text-white"
            type="submit"
            [disabled]="!inverterForm.valid"
            [ngClass]="{
              'opacity-50 ': !inverterForm.valid
            }"
          >
            Search
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="w-[100%] justify-center mt-6">
    @if (plantDetail) {
      <table class="text-white mt-[1%] p-2" class="table1st">
        <colgroup>
          <col
            span="6"
            style="
              border-right: solid;
              border-color: rgba(255, 255, 255, 0.7);
              border-width: 0.0001px;
            "
          />
          <col />
        </colgroup>
        <thead class="dark:bg-dimGrey p-5 font-thin">
          <tr class="h-fit">
            <th class="w-[12%]">Plant Name</th>
            <th class="w-[15%]">Device ID</th>
            <th class="w-[14%]">Inverter Serial No.</th>
            <th class="w-[12%]">User Name</th>
            <th class="w-[21%]">Address</th>
            <th class="w-[17%]">Model</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody
          class="bg-dark-table-head text-white p-5 text-center items-center"
        >
          <tr class="w-[100%]">
            <td class="p-2 text-wrap">{{ plantDetail.plantName }}</td>
            <td class="p-2 text-wrap">{{ plantDetail.deviceId }}</td>
            <td class="p-2 text-wrap">{{ plantDetail.inverterSerialNo }}</td>
            <td class="p-2 text-wrap">{{ plantDetail.userName }}</td>
            <td class="p-2 text-wrap">{{ plantDetail.location }}</td>
            <td class="p-2 text-wrap">{{ plantDetail.model }}</td>

            <td>
              <button
                class="w-[70%] h-8 rounded-lg text-white bg-red-500"
                (click)="openDialog()"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    }
  </div>
</div>
