import { Component, Inject, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SeService } from '../../services/se.service';
import { DeletePlantResponse } from '../../../shared/models/delete-plant';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { SiService } from '../../../system-integrator/services/si.service';
import { BaseComponent } from '../../../base/components/base.component';
import { USER_KEY } from '../../../shared/service/storage.service';
import { User } from '../../../shared/models/user';
import { DeleteplantComponent as DeletePlantComponent } from '../deleteplant/deleteplant.component';
import { CommonModule } from '@angular/common';
import {
  NoxDialogParam,
  NoxDialogSize,
  NoxDialogType,
} from '../../../shared/models/nox-dialog-param';
import { NoxDialogComponent } from '../../../shared/components/nox-dialog/nox-dialog.component';
@Component({
  selector: 'app-popup',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  templateUrl: './popup.component.html',
  styleUrl: './popup.component.scss',
})
export class PopupComponent extends BaseComponent {
  seService: SeService = inject(SeService);
  readonly dialog = inject(MatDialog);
  deleteForm = new FormGroup({
    commentBox: new FormControl('', [
      Validators.required,
      Validators.minLength(20),
    ]),
  });
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DeletePlantComponent>,
  ) {
    super();
  }

  showConfirmationDialog() {
    let comment = this.deleteForm.value;
    let inverter: string = this.data['value'];

    let user: User = this.storageService.getObject(USER_KEY);
    let noxDialogData: NoxDialogParam = {
      type: NoxDialogType.confirm,
      title:
        'Once deleted, this plant cannot be restored. Are you sure you want to proceed with the deletion?',

      positiveBtnLabel: 'Yes',
      negativeBtnLabel: 'No',
      dialogSize: NoxDialogSize.small,
    };
    const dialogRef = this.dialog.open(NoxDialogComponent, {
      data: noxDialogData,
    });
    dialogRef.componentInstance.positiveListener = () => {
      this.spinner.show();
      this.seService
        .deletePlant(inverter, comment.commentBox ?? '', user.id)
        .subscribe({
          next: (response) => {
            if (response.status == 200) {
              if (response.message) {
                this.toastr.success(response.message);
                this.deleteForm.reset();
                this.dialogRef.close(1);
              }
            }
            this.spinner.hide();
            dialogRef.close();
          },
          error: (err) => {
            this.toastr.error(err);
            this.spinner.hide();
          },
          complete: () => {
            this.spinner.hide();
          },
        });
    };
    dialogRef.componentInstance.negativeListener = () => {
      dialogRef.close();
      this.dialogRef.close();
    };
  }
  cancelDialog() {
    this.dialogRef.close();
  }
}
