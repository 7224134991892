import {
  ApplicationConfig,
  InjectionToken,
  importProvidersFrom,
} from '@angular/core';
import { provideRouter, withHashLocation } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  HttpBackend,
  HttpClient,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { userInterceptor } from './shared/interceptors/user.interceptor';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { errorInterceptor } from './shared/interceptors/error.interceptor';
import { headerImplInterceptor } from './shared/interceptors/header-impl.interceptor';
import { provideToastr } from 'ngx-toastr';
import { provideLottieOptions } from 'ngx-lottie';

import { provideUserIdleConfig } from 'angular-user-idle';
import player from 'lottie-web';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BnNgIdleService } from 'bn-ng-idle';
/** This is the main configuration for the application.
 * It is used to provide the application with the necessary providers and routes.
 */

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/languages/', '.json');
}

export const LOGGER_CONFIG = new InjectionToken<LoggerModule>('LoggerConfig');

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    provideUserIdleConfig({ idle: 28800, timeout: 40, ping: 10 }),
    BrowserAnimationsModule,
    BnNgIdleService,
    NgxSpinnerModule,
    provideHttpClient(
      withInterceptors([
        errorInterceptor,
        headerImplInterceptor,
      ]),
    ),

    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: (httpBackend: HttpBackend): TranslateHttpLoader =>
            new TranslateHttpLoader(new HttpClient(httpBackend), './assets/languages/', '.json'),
          deps: [HttpBackend],
        },
      }),
    ),
    importProvidersFrom(
      LoggerModule.forRoot({
        level: NgxLoggerLevel.DEBUG,
        serverLogLevel: NgxLoggerLevel.ERROR,
      }),
    ),
    provideToastr({
      timeOut: 2000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    provideLottieOptions({
      player: () => player,
    }),
  ],
};

export function loggerConfigFactory() {
  return {
    serverLoggingUrl: '/api/logs',
    level: NgxLoggerLevel.DEBUG,
    serverLogLevel: NgxLoggerLevel.ERROR,
  };
}
