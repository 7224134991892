<div class="flex flex-col justify-between w-full h-full">
  <div class="flex justify-between items-center pt-3 pl-3">
    <div>
      <span class="gradient-header">
        {{ "today_alerts_title" | translate }}</span
      >
      <img
        src="../../../../assets/images/infoicon.svg "
        class="hidden h-[15px] ml-2 -mb-0.5"
        alt="Info"
      />
    </div>
    <div class="pr-2">
      <a
        class="text-[#0051BA] font-normal text-sm"
        [routerLink]="['../../', 'alerts', alertType]"
        >{{ "today_alerts_more_details" | translate }}</a
      >
    </div>
  </div>
  <div class="flex justify-center flex-grow">
    <div echarts [options]="options!" class="h-[90%] w-[90%]"></div>
  </div>
  <div class="flex justify-between items-center text-white px-2 pb-2">
    <div class="flex">
      <div class="flex flex-row ml-2 items-end">
        <div class="icon-xs bg-[#0051BA] rounded-full my-2"></div>
        <div class="flex flex-col justify-start items-start ml-2">
          <div>
            <span class="value-small">{{ alertsDetails.normalAlerts }}</span>
          </div>
          <span class="label-xs text-dark-gray-content ml-0 text-left"
            >Normal Alerts in
            {{ alertsDetails.normalAlertsPlantCount }} Plants</span
          >
        </div>
      </div>
    </div>

    <div class="flex ml-12">
      <div class="flex">
        <div class="flex flex-row ml-2 items-end">
          <div class="icon-xs bg-[#CC5151] rounded-full my-2"></div>
          <div class="flex flex-col justify-start items-start ml-2">
            <div>
              <span class="value-small">{{
                alertsDetails.abnormalAlerts
              }}</span>
            </div>
            <span class="label-xs text-dark-gray-content ml-0 text-left"
              >Abnormal Alerts in
              {{ alertsDetails.abnormalAlertsPlantCount }} Plants</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
