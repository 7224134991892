import { Component, OnInit, inject } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faCoffee,
  faSearch,
  faStar,
  faBell,
  faAngleDown,
  faClock,
} from '@fortawesome/free-solid-svg-icons';
import { BaseComponent } from '../../../base/components/base.component';
import { USER_KEY } from '../../service/storage.service';
import { User,UserCategory } from '../../models/user';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ClickOutsideDirective } from '../../utils/click.outside.directive';
import { SecondaryRoles } from '../../interfaces/secondary-roles';
import { ProductService } from '../../service/product.service';
import { UserInfoData } from '../../models/user-info-response';
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    FontAwesomeModule,
    CommonModule,
    ClickOutsideDirective,
    RouterModule,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent extends BaseComponent implements OnInit {
  faCoffee = faCoffee;
  faSearch = faSearch;
  faStar = faStar;
  faBell = faBell;
  faAngleDown = faAngleDown;
  faClock = faClock;
  route = inject(ActivatedRoute);
  user: User;
  userType = SecondaryRoles;
  productService = inject(ProductService);
  loggedUser: string = '';
  userInfo?: UserInfoData;
  userCategory = UserCategory;
  constructor() {
    super();

    this.user = this.storageService.getObject(USER_KEY);
  }
  dropdownOpen = false; // State to track if the dropdown is open

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen; // Toggle the dropdown state
  }
  logout() {
    if (this.user && this.user.id) {
      this.spinner.show();
      this.authService.logout(this.user.id).subscribe({
        next: (authResponse) => {
          this.spinner.hide();
          this.productService.clean();
          this.storageService.clean();
          this.router.navigate(['/login']);
        },
        error: (err) => {
          this.logger.error(err);
          this.router.navigate(['/login']);
          this.spinner.hide();
        },
        complete: () => {
          this.spinner.hide();
        },
      });
    } else {
      this.productService.clean();
      this.storageService.clean();
      this.router.navigate(['/login']);
    }
  }
  clickOutside() {
    this.dropdownOpen = false;
  }

  secondaryRoles: any = [];

  ngOnInit(): void {
    let secondary_roles: Array<number> = this.user.secondryRoles;
    let role_route = { val: '', link: '' };
    this.fetchUserProfile();
    secondary_roles?.unshift(this.user.user_type);
    secondary_roles?.forEach((element: number) => {
      switch (element) {
        case this.userType.CUSTOMER:
          role_route = { val: 'Customer', link: 'customer' };
          this.secondaryRoles.push(role_route);
          break;
        case this.userType.SYSTEM_INTEGRATOR:
          role_route = { val: 'System Integrator', link: 'system-integrator' };
          this.secondaryRoles.push(role_route);
          break;
        case this.userType.ADMIN:
          role_route = { val: 'Admin', link: 'customer/dashboard' };
          this.secondaryRoles.push(role_route);
          break;

        default:
          break;
      }
    });
  }
  fetchUserProfile() {
    this.authService.fetchUserInfo(this.user.id).subscribe({
      next: (infoResponse) => {
        if (infoResponse.status == 200 || infoResponse.status == 204) {
          this.userInfo = infoResponse.data;
          this.user.email = this.userInfo.email;
          this.user.phone = this.userInfo.phone;
          this.user.user_image = this.userInfo.user_image;

          this.storageService.setObject(USER_KEY, this.user);
        }
      },
      error: (err) => {
        this.logger.error(err);
      },
      complete: () => {},
    });
  }

  switchUserType(event: Event) {
    let val = (event.target as HTMLInputElement).value;
    switch (val) {
      case 'customer/dashboard':
        this.router.navigate(['/dashboard', 'customer-dashboard']);
        break;
      case 'si/dashboard':
        this.router.navigate(['/si', 'home']);
        break;

      default:
        break;
    }
  }
}
