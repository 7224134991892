import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { TodayAlertsComponent } from '../../today-alerts/today-alerts.component';
import { TrendsComponent } from '../../trends/trends.component';
import { PowercutAndSummaryComponent } from '../../powercut-and-summary/powercut-and-summary.component';
import { RadialBarComponent } from '../../radial-bar/radial-bar.component';
import { PlantDetailsComponent } from '../../plant-details/plant-details.component';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, Subscription, takeUntil } from 'rxjs';
import {
  AllPlantDetails,
  solarPlantResponse,
} from '../../../models/all-plant-details';
import { BaseComponent } from '../../../../base/components/base.component';
import { SiService } from '../../../../system-integrator/services/si.service';
import { PlantHealthDetails } from '../../../models/plant-health-details';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { mapBackendToFrontend } from '../../../utils/common-utils';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { NumberUtil } from '../../../utils/number-util';
import { lifetimeSavings } from '../../../constants/shared.constants';

@Component({
  selector: 'app-solar-dashboard',
  standalone: true,
  templateUrl: './solar-dashboard.component.html',
  styleUrl: './solar-dashboard.component.scss',
  imports: [
    TodayAlertsComponent,
    TrendsComponent,
    PowercutAndSummaryComponent,
    RadialBarComponent,
    PlantDetailsComponent,
    TranslateModule,
    CommonModule,
    LottieComponent,
  ],
})
export class SolarDashboardComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  plantHealthDetailsSubscription!: Subscription;
  allPlantDetailsSubscription!: Subscription;
  todayAlertsSubscription!: Subscription;

  siService: SiService = inject(SiService);

  solarPlantResponse: solarPlantResponse = {
    totalSolarPlants: 0,
    solarPlantsOnline: 0,
    solarPlantsOffline: 0,
    lifetimeSavings: 0,
    roi: 0,
    currentGeneration: '',
    totalSolarCapacity: null,
    todayGeneration: '',
    lifetimeGeneration: '',
    co2EmissionSaved: '',
    equivalentTreesPlanted: 0,
    coalNotBurned: '',
  };
  installer_id!: string;
  solarPlantHealth!: number;

  isData = new Subject<boolean>();
  private destroy$ = new Subject<void>();
  options: AnimationOptions[] = [
    {
      path: '/assets/lottie/pig-save.json',
    },
  ];
  numberUtil = NumberUtil;
  ngOnInit(): void {
    this.baseUserService.sessionService.startBnWatch(this.router);
    this.installer_id = this.authService.loggedUser.installer_id;

    this.fetchApiData();
    /**
     * Timer listener
     */
    this.baseUserService.timerService
      .getTimerObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((fetchData) => {
        if (fetchData) {
          this.fetchApiData();
        }
      });
  }
  fetchApiData() {
    this.loadData(
      [
        this.baseUserService.todayAlertsLoading,
        this.baseUserService.trendsLoading,
        this.baseUserService.powerCutLoading,
        this.baseUserService.plantDetailsLoading,
      ],
      this.isData,
    );

    this.resolveAllSubscriptions([
      this.isData,
      this.getSolarPlantHealthDetails(),
      this.getAllSolarPlantDetails(),
    ]);
  }

  getAllSolarPlantDetails() {
    let getAllPlantDetails = new Subject<boolean>();
    this.allPlantDetailsSubscription = this.siService
      .getAllPlantDetails(this.installer_id, '1')
      .subscribe({
        next: (value: AllPlantDetails) => {
          this.solarPlantResponse = value.data.solarPlantResponse;
          getAllPlantDetails.next(true);
        },
        error: (e: Error) => {
          getAllPlantDetails.next(true);
        },
        complete: () => getAllPlantDetails.complete(),
      });
    return getAllPlantDetails;
  }

  getSolarPlantHealthDetails() {
    let getPlantHealthDetails = new Subject<boolean>();

    this.plantHealthDetailsSubscription = this.siService
      .getPlantHealthDetails(this.installer_id, '2')
      .subscribe({
        next: (value: PlantHealthDetails) => {
          this.solarPlantHealth = Math.floor(value.data.solarPlantsHealth);
          getPlantHealthDetails.next(true);
        },
        error: (e: Error) => {
          this.logger.error(e.message), getPlantHealthDetails.next(true);
        },
        complete: () => getPlantHealthDetails.complete(),
      });
    return getPlantHealthDetails;
  }

  calculateRoiProgress(progress: number): string {
    let pixel = mapBackendToFrontend(progress, -50, 50);
    return pixel + '%';
  }
  convertScientificNotation(input: string,parameter?:string): string {
    if (input.length == 0) {
      return 'N/A';
    } else {
      let unit, numberString;
      parameter === lifetimeSavings ? [unit, numberString] = input.split(' ') : [numberString, unit] = input.split(' ');
      // Convert the numerical value to a number
      const numberValue = parseFloat(numberString);

      // Round the number to three decimal places
      const roundedValue = numberValue.toFixed(2);

      // Return the result as a string with the unit appended
      return parameter === lifetimeSavings ? `${unit} ${roundedValue}`: `${roundedValue} ${unit}`;
    }
  }

  ngOnDestroy(): void {
    this.baseUserService.sessionService.stopBnTimer();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
