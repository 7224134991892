@if (deleteplantresponse == []) {
  <div class="flex justify-center align-middle">
    <p>No data Found</p>
  </div>
} @else {
  <div class="pl-[0.5%]">
    <table class="text-white w-[100%] overflow-y-visible bg-dark-table-header">
      <thead
        class="bg-dark-table-header p-7 position:fixed label-sm font-medium whitespace-nowrap"
      >
        <tr>
          @for (item of headerkeys; track $index) {
            <th class="min-w-[200px] label-sm bg-dark-table-header font-medium">
              {{ headerTitle[item] }}
            </th>
          }
        </tr>
      </thead>

      <tbody
        class="bg-dark-table-background text-white p-7 text-center items-center label-sm"
      >
        @for (item of deleteplantresponse; track $index) {
          <tr>
            <td class="label-xs">
              {{ item.plantName }}
            </td>

            <td class="label-xs">{{ item.deviceId }}</td>
            <td class="label-xs">
              <div>
                <span> {{ item.userName }}</span
                ><br /><span>{{ item.location }}</span>
              </div>
            </td>
            <td class="label-xs">
              <div>
                <span class="font-medium">{{ item.model }}</span
                ><br />{{ item.inverterSerialNo }}
              </div>
            </td>
            <td class="label-xs">{{ item.comment }}</td>
            <td class="label-xs">{{ item.deletedOn }}</td>
          </tr>
        }
      </tbody>
    </table>
  </div>
}
