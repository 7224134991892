import { HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { StatsResponseData } from '../models/stats';
import { PlantListData, PlantListResponseData } from '../models/plants';
import { PowerGenerationResponseData } from '../models/power-generation';
import { NotificationResponseData } from '../models/notification';
import { PowerCutResponse } from '../models/power-cut';
import { HomeConsumptionResponse } from '../models/home-consumption';
import { VideoGuideResponse } from '../models/video-guide';
import { FaqResponse } from '../models/faqs';
import { DosDontResponse } from '../models/dos-and-dont';
import { PlantDetail, PlantDataResponse } from '../models/plant';
import { InverterResponse } from '../models/inverter';
import { BatteryResponse } from '../models/battery';
import { SolarInfoResponse } from '../models/solar-info';
import { DataLoggerResponse } from '../models/data-logger';
import { ConnectedUserResponse } from '../models/connected-user';
import { PlantReportResponse } from '../models/plant-report';
import { StringInfoResponse } from '../models/string-info';
import { ChangePasswordResponse } from '../models/generic';
import { PlantOnlineRatioResponse } from '../models/plant-online-ratio';
import { ExploreResponse } from '../models/explore';
import { BaseUserService } from '../../base/services/base-user.service';
import { AddSecondaryUserResponse } from '../models/add-secondary-response';
import { DataLoggerTrendResponse } from '../models/datalogger-trends';
import { ActivatePlantResponse } from '../models/activate-plant-response';
import { TicketResponse } from '../models/ticket-response';

@Injectable({
  providedIn: 'root',
})
export class ProductService extends BaseUserService {
  plantList = new BehaviorSubject<PlantListData | null>(null);
  selectedPlant = new BehaviorSubject<PlantDetail | null>(null);
  constructor() {
    super();
  }

  clean() {
    this.plantList.next(null);
    this.selectedPlant.next(null);
  }

  /**
   *
   * @param productCategory
   * @param includeFlag
   * @returns
   */
  fetchFaqs(
    productCategory: string,
    includeFlag: string,
  ): Observable<FaqResponse> {
    return this.http.get<FaqResponse>(
      this.env.externalUrls.product.baseUrl + 'util/getFAQ',
      {
        params: {
          product_category: productCategory,
          include_flag: includeFlag,
        },
      },
    );
  }

  /**
   *
   * @returns Observable<VideoGuideResponse>
   */
  fetchVideoGuide(): Observable<VideoGuideResponse> {
    return this.http.get<VideoGuideResponse>(
      this.env.externalUrls.product.baseUrl + 'util/getVideoGuide',
    );
  }

  /**
   *
   * @param productCategory
   * @param includeFlag
   * @returns
   */
  fetchDosAndDont(
    productCategory: string,
    includeFlag: string,
  ): Observable<DosDontResponse> {
    return this.http.get<DosDontResponse>(
      this.env.externalUrls.product.baseUrl + 'util/getDosDonts',
      {
        params: {
          product_category: productCategory,
          include_flag: includeFlag,
        },
      },
    );
  }

  /**
   *
   * @param plantId return plant id of the plant
   * @returns
   */
  getStats(plantId: string): Observable<StatsResponseData> {
    return this.http.get<StatsResponseData>(
      this.env.externalUrls.product.baseUrl + 'plants/' + plantId + '/statsV1',
    );
  }

  /**
   * Fetch Plant Notifications
   * @param plantId
   * @param userId
   * @returns
   */
  getNotifications(plantId: string, userId: string) {
    return this.http.get<NotificationResponseData>(
      this.env.externalUrls.user.baseUrl +
        '/users/system-alert-plant?plantId=' +
        plantId +
        '&userId=' +
        userId,
    );
  }
  /**
   *
   * @param userId
   * @returns
   */
  getPlantDetail(plantId: string): Observable<PlantDataResponse> {
    return this.http
      .get<PlantDataResponse>(
        this.env.externalUrls.product.baseUrl + 'plants/getPlantV1/' + plantId,
      )
      .pipe(
        tap((response: PlantDataResponse) => {
          this.selectedPlant.next(response.data);
        }),
      );
  }

  /**
   *
   * @param plantId
   * @returns
   */
  getInverterDetail(plantId: string): Observable<InverterResponse> {
    return this.http.get<InverterResponse>(
      this.env.externalUrls.product.baseUrl + 'plants/getInverter/' + plantId,
    );
  }
  /**
   *
   * @param plantId
   * @returns
   */

  getBatteryDetail(plantId: string): Observable<BatteryResponse> {
    return this.http.get<BatteryResponse>(
      this.env.externalUrls.product.baseUrl + 'plants/getBattery/' + plantId,
    );
  }

  /**
   *
   * @param plantId
   * @returns
   */

  getSolarDetail(plantId: string): Observable<SolarInfoResponse> {
    return this.http.get<SolarInfoResponse>(
      this.env.externalUrls.product.baseUrl + 'plants/getSolarInfo/' + plantId,
    );
  }

  /**
   *
   * @param plantId
   * @returns
   */

  getDataLogger(plantId: string): Observable<DataLoggerResponse> {
    return this.http.get<DataLoggerResponse>(
      this.env.externalUrls.product.baseUrl + 'plants/getDataLogger/' + plantId,
    );
  }

  /**
   *
   * @param plantId
   * @returns
   */

  getConnectedUser(plantId: string): Observable<ConnectedUserResponse> {
    return this.http.get<ConnectedUserResponse>(
      this.env.externalUrls.product.baseUrl +
        'plants/getConnectedUsers/' +
        plantId,
    );
  }

  /**
   *
   * @param userId
   * @param plantId
   * @returns
   */
  removeConnectedUser(
    userId: string,
    plantId: string,
  ): Observable<HttpResponse<ChangePasswordResponse>> {
    return this.http.delete<HttpResponse<ChangePasswordResponse>>(
      this.env.externalUrls.product.baseUrl +
        'plants/' +
        plantId +
        '/remove_user/' +
        userId,
    );
  }

  /**
   *
   * @param plantId
   * @param timeFlag
   * @param timeZone
   * @returns
   */
  downloadPlantReport(
    plantId: string,
    timeFlag: number,
    timeZone: string,
  ): Observable<PlantReportResponse> {
    let params = new HttpParams();

    params = params.set('timeFlag', timeFlag);
    params = params.set('timezone', timeZone);
    return this.http.get<PlantReportResponse>(
      this.env.externalUrls.product.baseUrl +
        'plants/' +
        plantId +
        '/downloadReportV1',
      { params },
    );
  }

  /**
   *
   * @param plantId
   * @returns
   */
  deactivatePlant(plantId: string): Observable<PlantReportResponse> {
    return this.http.delete<PlantReportResponse>(
      this.env.externalUrls.product.baseUrl + 'plants/delete/' + plantId,
    );
  }

  /**
   *
   * @param id can be plant id or installer id
   * @returns Observable StatsResponseData
   */
  getPowerGenerationTrends(
    id: string,
    date: number | null,
    startDate: number | null,
    endDate: number | null,
    year: number | null,
    type?: string | undefined,
  ): Observable<PowerGenerationResponseData> {
    let params = new HttpParams();

    if (date) {
      params = params.set('date', date);
    }

    if (year) {
      params = params.set('year', year);
    }

    if (startDate && endDate) {
      params = params.set('start_date', startDate);
      params = params.set('end_date', endDate);
    }

    if (type) {
      params = params.set('installerId', id);
      params = params.set('inverterType', type);
      return this.http.get<PowerGenerationResponseData>(
        this.env.externalUrls.system_integration.baseUrl + '/power-generations',
        { params },
      );
    } else {
      return this.http.get<PowerGenerationResponseData>(
        this.env.externalUrls.product.baseUrl +
          'plants/' +
          id +
          '/power-generations',
        { params },
      );
    }
  }

  /**
   *
   * @param plantId
   * @returns Observable StatsResponseData
   */
  getPowerConsumptionTrends(
    id: string,
    date: number | null,
    startDate: number | null,
    endDate: number | null,
    year: number | null,
    type?: string,
  ): Observable<HomeConsumptionResponse> {
    let params = new HttpParams();

    if (date) {
      params = params.set('date', date);
    }

    if (year) {
      params = params.set('year', year);
    }

    if (startDate && endDate) {
      params = params.set('start_date', startDate);
      params = params.set('end_date', endDate);
    }

    if (type) {
      params = params.set('installerId', id);
      params = params.set('inverterType', type);
      return this.http.get<HomeConsumptionResponse>(
        this.env.externalUrls.system_integration.baseUrl + '/home-consumption',
        { params },
      );
    } else {
      return this.http.get<HomeConsumptionResponse>(
        this.env.externalUrls.product.baseUrl +
          'plants/' +
          id +
          '/home-consumption',
        { params },
      );
    }
  }

  /**
   *
   * @param plantId
   * @returns Observable StatsResponseData
   */
  getPowerCutGeneration(
    id: string,
    date: number | null,
    startDate: number | null,
    endDate: number | null,
    year: number | null,
    type?: string,
  ): Observable<PowerCutResponse> {
    let params = new HttpParams();

    if (date) {
      params = params.set('date', date);
    }

    if (year) {
      params = params.set('year', year);
    }

    if (startDate && endDate) {
      params = params.set('start_date', startDate);
      params = params.set('end_date', endDate);
    }
    if (type) {
      params = params.set('installerId', id);
      params = params.set('inverterType', type);
      return this.http.get<PowerCutResponse>(
        this.env.externalUrls.system_integration.baseUrl + '/power-cut',
        { params },
      );
    } else {
      return this.http.get<PowerCutResponse>(
        this.env.externalUrls.product.baseUrl + 'plants/' + id + '/power-cut',
        { params },
      );
    }
  }

  /**
   *
   * @returns get plant list using login user detail
   */
  getPlantList(
    userId: string,
  ): Observable<HttpResponse<PlantListResponseData>> {
    return this.http
      .get<PlantListResponseData>(
        this.env.externalUrls.product.baseUrl + 'plants/getAllV1/' + userId,
        { observe: 'response' },
      )
      .pipe(
        tap((response: HttpResponse<PlantListResponseData>) => {
          if (response.status == 204) {
            this.plantList.next({
              primaryPlant: [],
              secondaryPlant: [],
            });
          } else {
            if (response.body) this.plantList.next(response.body.data);
          }
        }),
      );
  }

  /**
   *
   * @param plantId
   * @param date
   * @returns
   */
  getStringDetail(
    plantId: string,
    date: number,
  ): Observable<StringInfoResponse> {
    return this.http.get<StringInfoResponse>(
      this.env.externalUrls.product.baseUrl +
        'plants/' +
        plantId +
        '/stringDetailsTrends?date=' +
        date,
    );
  }

  /**
   *
   * @param userId
   * @returns
   */
  getPlantOnlineRatio(plantId: string): Observable<PlantOnlineRatioResponse> {
    return this.http.get<PlantOnlineRatioResponse>(
      this.env.externalUrls.product.baseUrl +
        'plants/plantOnlineRatio?plantId=' +
        plantId,
    );
  }

  /**
   *
   * @returns
   */
  fetchExplorePost(): Observable<ExploreResponse> {
    return this.http.get<ExploreResponse>(
      this.env.externalUrls.product.baseUrl + 'util/getExplore',
    );
  }
  /**
   * Add secondary User
   */

  inviteSecondaryUser(
    data: { email: string; name: string; phone: string },
    plantId: string,
  ): Observable<AddSecondaryUserResponse> {
    return this.http.post<AddSecondaryUserResponse>(
      this.env.externalUrls.product.baseUrl +
        'plants/' +
        plantId +
        '/addSecondaryUser',
      data,
    );
  }

  /**
   *
   * @param plantId
   * @returns
   */

  getDataLoggerTrends(
    plantId: string,
    date: number,
  ): Observable<DataLoggerTrendResponse> {
    return this.http.get<DataLoggerTrendResponse>(
      this.env.externalUrls.product.baseUrl +
        'plants/' +
        plantId +
        '/data-logger-wifi-trends?date=' +
        date,
    );
  }

  /**
   * activate plant
   */

  activatePlant(plantId: string): Observable<ActivatePlantResponse> {
    return this.http.put<ActivatePlantResponse>(
      this.env.externalUrls.product.baseUrl + 'plants/activate/' + plantId,
      {},
    );
  }

  fetchTicketList(customerId: string): Observable<TicketResponse> {
    return this.http.get<TicketResponse>('https://jsonkeeper.com/b/JW78');
  }

  createTicket(customerId: string): Observable<TicketResponse> {
    return this.http.post<TicketResponse>('https://jsonkeeper.com/b/JW78', {});
  }
}
