import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { BaseComponent } from '../../../base/components/base.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ProductService } from '../../../shared/service/product.service';
import { USER_KEY } from '../../../shared/service/storage.service';
import { Md5 } from 'ts-md5';
import { Subscription } from 'rxjs';
import { User } from '../../../shared/models/user';

@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    TranslateModule,
    ReactiveFormsModule,
    CommonModule,
  ],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss',
})
export class ChangePasswordComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  changePasswordForm = new FormGroup({
    currentPassword: new FormControl('', [Validators.required]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
    confirmPassword: new FormControl('', Validators.required),
  });
  prodService = inject(ProductService);
  updatePasswordSubscription?: Subscription;
  user: User;

  constructor() {
    super();
    this.user = this.storageService.getObject(USER_KEY);
  }

  ngOnInit(): void {
    this.baseUserService.sessionService.startBnWatch(this.router);
  }
  goBack(): void {
    this.baseUserService.back();
  }
  override switchLanguage(language: string) {
    this.translateService.use(language);
  }

  onSubmit() {
    if (this.changePasswordForm.valid) {
      const currentPassword = this.changePasswordForm.value.currentPassword;
      const password = this.changePasswordForm.value.password;
      const confirmPassword = this.changePasswordForm.value.confirmPassword;
      if (password && currentPassword && confirmPassword) {
        if (password === confirmPassword) {
          let encryptedPassword = Md5.hashStr(confirmPassword);
          let encryptedOldPassword = Md5.hashStr(currentPassword);
          const userId = this.storageService.getObject(USER_KEY).id;
          this.spinner.show();
          this.updatePasswordSubscription = this.authService
            .updatedPassword(userId, encryptedOldPassword, encryptedPassword)
            .subscribe({
              next: (response) => {
                if (response?.status === 200) {
                  this.changePasswordForm.reset();
                  this.toastr.success('Password changed successfully');
                  if (this.user && this.user.id) {
                    this.spinner.show();
                    this.authService.logout(this.user.id).subscribe({
                      next: (_) => {
                        this.spinner.hide();
                        this.storageService.clean();
                        this.router.navigate(['/login']);
                        this.spinner.hide();
                      },
                      error: (err) => {
                        this.logger.error(err);
                        this.router.navigate(['/login']);
                        this.spinner.hide();
                      },
                    });
                  } else {
                    this.storageService.clean();
                    this.router.navigate(['/login']);
                    this.spinner.hide();
                  }
                }
              },
              error: (err) => {
                this.logger.error(err);
                this.spinner.hide();
              },
            });
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.updatePasswordSubscription?.unsubscribe();
    this.baseUserService.sessionService.stopBnTimer();
  }
}
