import { Component, OnInit, inject } from '@angular/core';
import { SessionService } from '../../../service/session.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bms-dashboard',
  standalone: true,
  imports: [],
  templateUrl: './bms-dashboard.component.html',
  styleUrl: './bms-dashboard.component.scss'
})
export class BmsDashboardComponent implements OnInit{
sessionService: SessionService = inject(SessionService);
router: Router = inject(Router);
ngOnInit(): void {
  this.sessionService.startWatch();
  this.sessionService.timerStart();
  this.sessionService.killSession(this.router)
}
}
