import { Component, OnDestroy, OnInit, inject, input } from '@angular/core';
import { BaseComponent } from '../../../base/components/base.component';
import { TotalPlantsSI } from '../../models/total-plants-si';
import { PlantDetailsHeaderVal } from '../../interfaces/plant-details-header-val';
import { SiService } from '../../../system-integrator/services/si.service';
import { Subscription } from 'rxjs';
import {
  plantDetailsHeaderData,
  sharedheaderKeyMap,
} from '../../constants/shared.constants';

@Component({
  selector: 'app-plant-details',
  standalone: true,
  imports: [],
  templateUrl: './plant-details.component.html',
  styleUrl: './plant-details.component.scss',
})
export class PlantDetailsComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  headerData: Array<PlantDetailsHeaderVal> = [...plantDetailsHeaderData];

  headerKeyMap = sharedheaderKeyMap;

  siService: SiService = inject(SiService);
  installer_id!: string;
  categoryId = input.required<string>();
  totalPlantsSubscription!: Subscription;

  ngOnInit(): void {
    this.installer_id = this.authService.loggedUser.installer_id;
    this.getTotalPlants();
  }

  getTotalPlants() {
    this.totalPlantsSubscription = this.siService
      .getTotalPlantsSI(this.installer_id, this.categoryId())
      .subscribe({
        next: (value: TotalPlantsSI) => {
          this.headerData.forEach((item: PlantDetailsHeaderVal) => {
            switch (item.description) {
              case this.headerKeyMap['totalPlants']:
                item.value = value.data.totalPlants;
                break;
              case this.headerKeyMap['plantsOnline']:
                item.value = value.data.plantsOnline;
                break;
              case this.headerKeyMap['plantsOffline']:
                item.value = value.data.plantsOffline;
                break;
              case this.headerKeyMap['plantsInstalledLastMonth']:
                item.value = value.data.plantsInstalledLastMonth;
                break;
              case this.headerKeyMap['plantsInstalledThisMonth']:
                item.value = value.data.plantsInstalledThisMonth;
                break;
            }
          });
          this.baseUserService.plantDetailsLoading.next(true);
        },
        error: (e: Error) => {
          this.toastr.error(e.message);
        },
        complete: () => {
          this.baseUserService.plantDetailsLoading.next(true);
        },
      });
  }

  ngOnDestroy(): void {
    this.totalPlantsSubscription.unsubscribe();
  }
}
