import { plainObject } from '../../shared/interfaces/plain-obj';

export const deleteplanthistoryHeaderKeys: Array<string> = [
  'plantName',
  'dataloggerSN',
  'customerDetails',
  'inverterdetails',
  'comment',
  'deletedOn',
];
export const headertitle: plainObject = {
  plantName: 'Plant Name',
  dataloggerSN: 'Data Logger SN',
  customerDetails: 'Customer Details',
  inverterdetails: 'Inverter Details',
  comment: 'Comment',
  deletedOn: 'Deleted On',
};
