import { MappingProtocol, PlantTypeEnum } from './stats';

export class NoxDropdown {
  key: string;
  value: string;
  constructor(key: string, value: string) {
    this.key = key;
    this.value = value;
  }
}

export function getTrendsDropDown(
  id?: string | undefined,
  mappingProtocol?: MappingProtocol,
  inverterType?:string|undefined
): NoxDropdown[] {
  let noxDropdownList: NoxDropdown[] = [
    new NoxDropdown(TrendDropdownKey.generation, 'Generation'),
    new NoxDropdown(TrendDropdownKey.consumption, 'Consumption'),
    new NoxDropdown(TrendDropdownKey.powerCut, 'Power Cut'),
  ];
  /**
   * hide generation if non solar(2) and hide consumption if mappingProtocol is BITWISE
   */
  if (id && id === '2') {
    // remove generation from list
    noxDropdownList = noxDropdownList.filter(
      (item) => item.key !== TrendDropdownKey.generation,
    );
  }
  if (mappingProtocol && mappingProtocol === MappingProtocol.BITWISE) {
    // remove consumption from list
    noxDropdownList = noxDropdownList.filter(
      (item) => item.key !== TrendDropdownKey.consumption,
    );
  }


  


  return noxDropdownList;
}
export enum TrendDropdownKey {
  consumption = 'consumption',
  generation = 'generation',
  powerCut = 'power-cut',
}
