import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WeatherResponse } from '../models/weather';
import { environment } from '../../../environments/environment';
import { WeatherForecastResponse } from '../models/weather-forecast';

@Injectable({
  providedIn: 'root',
})
export class WeatherService {
  constructor(private http: HttpClient) {}

  /**
   *  getWeatherByLatLng
   * @param lat
   * @param lng
   * @param units
   * @param appid
   * @returns
   */
  getWeatherByLatLng(
    lat: number,
    lng: number,
    units: string,
    appid: string,
  ): Observable<WeatherResponse> {
    let params = new HttpParams();
    params = params.set('lat', lat);
    params = params.set('lon', lng);
    params = params.set('units', units);
    params = params.set('appid', appid);
    return this.http.get<WeatherResponse>(
      environment.externalUrls.openWeather.baseUrl + 'weather',
      { params },
    );
  }

  /**
   *  getWeatherByLatLng
   * @param lat
   * @param lng
   * @param units
   * @param appid
   * @returns
   */
  getWeatherForecastLatLng(
    lat: number,
    lng: number,
    units: string,
    appid: string,
  ): Observable<WeatherForecastResponse> {
    let params = new HttpParams();
    params = params.set('lat', lat);
    params = params.set('lon', lng);
    params = params.set('units', units);
    params = params.set('appid', appid);
    return this.http.get<WeatherForecastResponse>(
      environment.externalUrls.openWeather.baseUrl + 'forecast',
      { params },
    );
  }
}
