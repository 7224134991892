<div
  [class.open]="isOpen"
  [ngClass]="{
    sizefirst: uploadCounter.length == 2,
    sizesecond: uploadCounter.length == 3,
    sizethird: uploadCounter.length == 4
  }"
  class="side-sheet fixed top-[21%] p-6 bottom-0 h-[73%] w-[61%] z-[1000] right-[-100%] bg-[#22282F] rounded-lg gradient-border overflow-auto"
>
  <div class="flex flex-col w-[100%] h-full overflow-y-auto p-2">
    <div class="w-[100%] h-[100%]">
      <div class="mb-[1.3%]">
        <h2 class="text-white text-xl font-semibold">
          {{ plantName }}
        </h2>
      </div>

      <form [formGroup]="ticketForm" id="uploadForm">
        <div
          class="grid grid-cols-4 gap-7 w-[100%] mb-[2%]"
          [ngClass]="{
            width1: uploadCounter.length == 2,
            width2: uploadCounter.length == 3,
            width3: uploadCounter.length == 4
          }"
        >
          <div>
            <label for="category" class="block text-gray-400 text-sm ml-[0.5%]"
              >Category</label
            >
            <select
              id="category"
              formControlName="category"
              class="w-full box-border border-[0.1px] mt-[4%] border-gray-400 rounded-2xl py-[8px] px-4 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
            >
              <option value="" disabled selected>Select</option>
              <option *ngFor="let option of categories" [value]="option">
                {{ option }}
              </option>
            </select>
            <div
              *ngIf="
                ticketForm.get('category')?.touched &&
                ticketForm.get('category')?.invalid
              "
              class="text-red-500 text-xs ml-[2%]"
            >
              This field is required.
            </div>
          </div>

          <div>
            <label for="assignee" class="block text-gray-400 text-sm"
              >Assignee</label
            >
            <select
              id="assignee"
              formControlName="assignee"
              class="w-full box-border border-[0.1px] mt-[4%] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
            >
              <option value="" disabled selected>Select</option>
              <option *ngFor="let option of assignees" [value]="option">
                {{ option }}
              </option>
            </select>
            <div
              *ngIf="
                ticketForm.get('assignee')?.touched &&
                ticketForm.get('assignee')?.invalid
              "
              class="text-red-500 text-xs ml-[2%]"
            >
              This field is required.
            </div>
          </div>

          <div>
            <label for="priority" class="block text-gray-400 text-sm"
              >Priority</label
            >
            <select
              id="Priority"
              formControlName="priority"
              class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl mt-[4%] py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
            >
              <option value="" disabled selected>Select</option>
              <option *ngFor="let option of priorities" [value]="option">
                {{ option }}
              </option>
            </select>
            <div
              *ngIf="
                ticketForm.get('priority')?.touched &&
                ticketForm.get('priority')?.invalid
              "
              class="text-red-500 text-xs ml-[2%]"
            >
              This field is required.
            </div>
          </div>

          <div>
            <label for="ticketStatus" class="block text-gray-400 text-sm"
              >Ticket Status</label
            >
            <select
              id="ticketStatus"
              formControlName="ticketStatus"
              class="w-full box-border border-[0.1px] border-gray-400 mt-[4%] rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
            >
              <option value="" disabled selected>Select</option>
              <option *ngFor="let option of ticketStatus" [value]="option">
                {{ option }}
              </option>
            </select>
            <div
              *ngIf="
                ticketForm.get('ticketStatus')?.touched &&
                ticketForm.get('ticketStatus')?.invalid
              "
              class="text-red-500 text-xs ml-[2%]"
            >
              This field is required.
            </div>
          </div>
        </div>

        <div class="mb-[2%] w-[100%]">
          <label for="subject" class="block text-gray-400 text-sm"
            >Subject</label
          >
          <input
            type="text"
            id="subject"
            formControlName="subject"
            placeholder="Add subject"
            [ngClass]="{
              sub1: uploadCounter.length == 2,
              sub2: uploadCounter.length == 3,
              sub3: uploadCounter.length == 4
            }"
            class="w-[48.5%] box-border border-[0.1px] mt-[1.5%] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
          />
          <div
            *ngIf="
              ticketForm.get('subject')?.touched &&
              ticketForm.get('subject')?.invalid
            "
            class="text-red-500 text-xs ml-[2%]"
          >
            This field is required.
          </div>
        </div>

        <div class="w-[100%] h-[50%] flex mb-[2%] items-stretch">
          <div
            class="w-[50%] h-[100%]"
            [ngClass]="{
              dis1: uploadCounter.length == 2,
              dis2: uploadCounter.length == 3,
              dis3: uploadCounter.length == 4
            }"
          >
            <label
              for="description"
              class="block resize-none text-gray-400 text-sm"
              >Description</label
            >
            <textarea
              id="description"
              formControlName="description"
              rows="10"
              placeholder="Add description"
              class="w-[100%] h-[60%] mt-[3%] box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
            ></textarea>
            <div
              *ngIf="
                ticketForm.get('description')?.touched &&
                ticketForm.get('description')?.invalid
              "
              class="text-red-500 text-xs ml-[2%]"
            >
              This field is required.
            </div>
          </div>

          <div
            class="flex w-[50%] ml-1 items-center"
            id="uploaderbox"
            [ngClass]="{
              upl1: uploadCounter.length == 2,
              upl2: uploadCounter.length == 3,
              upl3: uploadCounter.length == 4
            }"
          >
            @for (item of images; track $index) {
              <div
                class="flex-col w-[38%] h-[100%] items-center align-middle"
                [ngClass]="{
                  inp1: uploadCounter.length == 2,
                  inp2: uploadCounter.length == 3,
                  inp3: uploadCounter.length == 4
                }"
              >
                <div
                  *ngIf="!images[$index].preview"
                  class="w-[100%] h-[100%] mt-[52%]"
                  [class.inpbox]="uploadCounter.length == 4"
                >
                  <input
                    type="file"
                    class="h-[10%]"
                    accept=".jpeg,.png"
                    id="file-{{ $index }}"
                    (change)="onFileSelected($event, $index)"
                  />
                </div>
                <div
                  class="h-[90%] w-[90%] ml-[4%] flex-grow border-white border-dashed"
                  *ngIf="images[$index].preview"
                >
                  <img
                    [src]="images[$index].preview"
                    class="w-[90%] h-[80%] ml-[10%]"
                  />
                </div>
                <div
                  *ngIf="images[$index].preview && !images[$index].isUploaded"
                  [class.wid]="images[$index].preview"
                  class="h-[30%] w-[99%]"
                >
                  <button
                    type="button"
                    (click)="uploadImage($index)"
                    class="p-1.5 box-border w-[100%] border-[0.1px] bg-white border-gray-400 rounded-2xl text-black text-xs mt-[2%]"
                  >
                    <div class="flex justify-center align-middle">
                      <div>
                        <img
                          src="../../../../../assets/images/ticketupload.svg"
                          class="mt-[2%]"
                        />
                      </div>
                      <div class="mt-1">Upload</div>
                    </div>
                  </button>
                </div>
              </div>
            }

            <div
              (click)="addImage()"
              class="text-black bg-white text-center ml-3 h-[10%]"
              [class.none]="images.length == 4"
            >
              <img
                src="../.../../../../../../assets/images/ticketaddmore.svg"
              />

              <span>Add More</span>
            </div>
          </div>
        </div>

        <div
          class="mb-[2%]"
          id="textarea"
          [ngClass]="{
            comm0: uploadCounter.length == 1,
            comm1: uploadCounter.length == 2,
            comm2: uploadCounter.length == 3,
            comm3: uploadCounter.length == 4
          }"
        >
          <textarea
            id="commentbox"
            formControlName="comment"
            placeholder="Add Comment"
            rows="2"
            class="box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
          ></textarea>
          <div
            *ngIf="
              ticketForm.get('comment')?.touched &&
              ticketForm.get('comment')?.invalid
            "
            class="text-red-500 text-xs ml-[2.5%]"
          >
            This field is required.
          </div>
        </div>

        <div
          class="self-end mt-[20%]"
          id="buttons"
          [class.margin]="uploadCounter.length == 4"
        >
          <button
            type="submit"
            (click)="submitFormData()"
            [disabled]="ticketForm.invalid"
            class="box-borde border-[0.1px] border-slate-100 rounded-2xl py-[8px] px-5 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
          >
            Save
          </button>
          <button
            type="button"
            (click)="toggle()"
            class="box-border ml-[2%] border-[0.1px] border-slate-100 rounded-2xl py-[8px] px-5 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
          >
            Cancel
          </button>
        </div>

        <!-- <div *ngIf="ticketForm.invalid" class="text-red-500 text-xs">
          Complete Mandatory Fields
        </div> -->
      </form>
    </div>
  </div>
</div>

<div *ngIf="isOpen" class="overlay" (click)="toggle()"></div>
