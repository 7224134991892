import { Component, OnInit, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { WeatherService } from '../../service/weather.service';
import { environment } from '../../../../environments/environment';
import {
  WeatherForecastResponse,
  WeatherList,
} from '../../models/weather-forecast';
import { CommonModule } from '@angular/common';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-weather-forecast',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    CommonModule,
    FontAwesomeModule,
  ],
  templateUrl: './weather-forecast.component.html',
  styleUrl: './weather-forecast.component.scss',
})
export class WeatherForecastComponent implements OnInit {
  isOpen = false;
  faClose = faClose;
  weatherService = inject(WeatherService);
  weatherData: WeatherList[] = [];
  constructor() {}

  ngOnInit(): void {
    this.fetchWeatherForecastData();
  }
  fetchWeatherForecastData() {
    this.weatherData = [];
    this.weatherService
      .getWeatherForecastLatLng(
        28.70406,
        77.102493,
        'metric',
        environment.externalUrls.openWeather.apiKey,
      )
      .subscribe((data) => {
        data.list.forEach((weatherResponse) => {
          const iconUrl = `https://openweathermap.org/img/wn/${weatherResponse.weather[0].icon}@2x.png`; // Construct the icon URL
          weatherResponse.iconUrl = iconUrl;
          this.weatherData.push(weatherResponse);
        });
      });
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
  }
}
