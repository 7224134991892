import { Component, Input } from '@angular/core';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
@Component({
  selector: 'app-excel-download',
  standalone: true,
  imports: [],
  template: `<div (click)="downloadExcel()"> <img
    src="../../../../assets/svg/Vector.svg"
    alt="arrow"
  /></div>`,
})

export class ExcelDownloadComponent<T extends Record<string, any>> {
  @Input() columns!: Array<{ header: string; field: string; sequence: number }>;
  @Input() apiResponse!: T[]; // Use the generic type here
  @Input() headerStyling: { [key: string]: any } = {}; // Default to an empty object
  @Input() fileName: string = 'download.xlsx'; // Default file name
  @Input() sheetName: string = 'Sheet1'; // Default sheet name

  downloadExcel() {
    // Sort columns by the sequence property
    const sortedColumns = this.columns.sort((a, b) => a.sequence - b.sequence);
    const headers = sortedColumns.map(col => col.header); // Get header names

    const formattedData = this.apiResponse.map(item => {
      const row: any = {};
      // Populate the row with values based on field names
      sortedColumns.forEach(col => {
        row[col.field + '_' + col.sequence] = item[col.field]; // Use unique key for each field
      });
      return row;
    });

    // Create a new worksheet with the formatted data
    const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: Object.keys(formattedData[0]) });
    const workbook = XLSX.utils.book_new();

    // Apply header styling if provided
    const ref = worksheet['!ref'];
    if (ref) {
      const range = XLSX.utils.decode_range(ref);
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell = worksheet[XLSX.utils.encode_cell({ r: 0, c: C })];
        if (cell) {
          cell.s = { font: { bold: true }, ...(this.headerStyling['s'] || {}) };
        }
      }
    }

    // Replace the first row with the headers
    headers.forEach((header, index) => {
      const cell = worksheet[XLSX.utils.encode_cell({ r: 0, c: index })];
      if (cell) {
        cell.v = header; // Set the cell value to the header name
      }
    });

    XLSX.utils.book_append_sheet(workbook, worksheet, this.sheetName || 'Sheet1'); // Use a fallback if undefined
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
    saveAs(blob, this.fileName);
  }
}