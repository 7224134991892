import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { StorageService, TOKEN, USER_KEY } from '../service/storage.service';
import { User } from '../models/user';

export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const storageService = inject(StorageService);

  const localData = storageService.get(TOKEN);
  if (localData != null) {
    return true;
  } else {
    router.navigateByUrl('/login');
    return false;
  }
};
export const authGuardSI: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const storageService = inject(StorageService);

  let user: User = storageService.getObject(USER_KEY);
  let authorization = storageService.get(TOKEN);
  if (user.id !== null && authorization !== null) {
    return true;
  } else {
    router.navigateByUrl('/login');
    return false;
  }
};
