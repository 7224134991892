import { Component, inject } from '@angular/core';
import { BaseComponent } from '../../../base/components/base.component';
import { ActivatedRoute, RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Subject, Subscription } from 'rxjs';
import { ProductService } from '../../../shared/service/product.service';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { AddSecondaryUserResponse } from '../../../shared/models/add-secondary-response';

@Component({
  selector: 'app-add-secondary-user',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    TranslateModule,
    ReactiveFormsModule,
    CommonModule,
    LottieComponent,
  ],
  templateUrl: './add-secondary-user.component.html',
  styleUrl: './add-secondary-user.component.scss',
})
export class AddSecondaryUserComponent extends BaseComponent {
  options: AnimationOptions[] = [
    {
      path: '/assets/lottie/add-user.json',
    },
  ];

  sendInviteForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phoneNumber: new FormControl('', Validators.required),
  });
  prodService = inject(ProductService);
  updatePasswordSubscription?: Subscription;
  private route = inject(ActivatedRoute);
  plantId?: string;
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.baseUserService.sessionService.startBnWatch(this.router);
    this.route.queryParams.subscribe((params) => {
      this.plantId = params['plantId'];
    });
  }
  override switchLanguage(language: string) {
    this.translateService.use(language);
  }

  onSubmit() {
    if (this.sendInviteForm.valid) {
      const name = this.sendInviteForm.value.name;
      const email = this.sendInviteForm.value.email;
      const phoneNumber = this.sendInviteForm.value.phoneNumber;
      if (name && email && phoneNumber) {
        let data = {
          email,
          name,
          phone: phoneNumber,
        };
        this.resolveAllSubscriptions([this.inviteSecondaryUser(data)]);
      }
    }
  }

  inviteSecondaryUser(data: {
    email: string;
    name: string;
    phone: string;
  }): Subject<boolean> {
    let loaderSubject = new Subject<boolean>();
    if (this.plantId) {
      this.prodService.inviteSecondaryUser(data, this.plantId).subscribe({
        next: (response: AddSecondaryUserResponse) => {
          if (response?.data == true) {
            this.toastr.success('Invitation send successfully');
            this.sendInviteForm.reset();
            this.baseUserService.back();
          }
          loaderSubject.next(true);
        },
        error: () => {
          loaderSubject.next(true);
          loaderSubject.complete();
        },
        complete: () => loaderSubject.complete(),
      });
    } else {
      this.toastr.error('Plant id is missing');
      loaderSubject.complete();
    }

    return loaderSubject;
  }

  goBack(): void {
    this.baseUserService.back();
  }

  ngOnDestroy(): void {
    this.updatePasswordSubscription?.unsubscribe();
    this.baseUserService.sessionService.stopBnTimer();
  }
}
