import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { generateNounce, loadScript } from './shared/utils/common-utils';
import { environment } from '../environments/environment';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterModule, NgxSpinnerModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'nox-frontend';

  constructor() {}
  ngOnInit(): void {
    // loadScript(
    //   'https://maps.googleapis.com/maps/api/js?key=' +
    //     environment.googleMapApiKey +
    //     '&loading=async',
    //   'dfsiV0kxU1pXdmRwUWtYbA',
    // );
  }
}
