import {
  Component,
  OnInit,
  Output,
  computed,
  inject,
  signal,
} from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
  RouterModule,
} from '@angular/router';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { SubHeaderComponent } from '../sub-header/sub-header.component';
import { HeaderComponent } from '../header/header.component';
import { ProductService } from '../../service/product.service';
import { BaseComponent } from '../../../base/components/base.component';
import { SecondaryRoles } from '../../interfaces/secondary-roles';
import { USER_KEY } from '../../service/storage.service';
import { User, UserType } from '../../models/user';

@Component({
  selector: 'app-layout',
  standalone: true,
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
  imports: [
    MatSidenavModule,
    RouterModule,
    SidebarComponent,
    SubHeaderComponent,
    HeaderComponent,
  ],
})
export class LayoutComponent extends BaseComponent implements OnInit {
  @Output() collapsed = signal<boolean>(true);
  sideNavWidth = computed(() => (this.collapsed() ? '60px' : '200px'));
  prodService = inject(ProductService);
  activatedRoute = inject(ActivatedRoute);

  isDashboard: boolean = false;
  isViewAsCustomer = false;
  user: User;

  userType = SecondaryRoles;
  constructor() {
    super();
    this.user = this.storageService.getObject(USER_KEY);

    let url = this.router.url;
    this.isViewAsCustomer =
      url.includes('/customer/dashboard') &&
      this.user.user_type === UserType.SYSTEM_INTEGRATOR;
  }
  ngOnInit(): void {
    let route = this.router.url.split('/');
    let curTab = route[route.length - 2];

    this.changeTab(curTab);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        let route = event.url.split('/');
        let curTab = route[route.length - 2];
        this.changeTab(curTab);
      }
      if (event instanceof NavigationEnd) {
        let route = event.urlAfterRedirects.split('/');
        let curTab = route[route.length - 2];
        this.changeTab(curTab);
      }
    });
  }

  changeTab(curTab: string) {
    switch (curTab) {
      case 'dashboard':
        this.isDashboard = true;
        break;
      default:
        this.isDashboard = false;
        break;
    }
  }
}
