import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { HeaderTabComponent } from './header-tab/header-tab.component';
import { TranslateModule } from '@ngx-translate/core';
import { TicketComponent } from './ticket/ticket.component';
import { TicketDialogComponent } from './ticket-dialog/ticket-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NgApexchartsModule } from 'ng-apexcharts';

import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { AnalyticComponent } from './analytic/analytic.component';
import { CustomerService } from '../../services/customer.service';

import { faChevronRight, faWifi } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
@Component({
  selector: 'app-analytics',
  standalone: true,
  imports: [
    CommonModule,
    HeaderTabComponent,
    TranslateModule,
    TicketComponent,
    MatDialogModule,
    TicketDialogComponent,
    NgApexchartsModule,
    NgxEchartsDirective,
    AnalyticComponent,
    FontAwesomeModule,
  ],
  providers: [provideEcharts()],

  templateUrl: './analytics.component.html',
  styleUrl: './analytics.component.scss',
})
export class AnalyticsComponent implements OnInit {
  mainTabs = ['Analytical', 'Ticket History Log'];
  signal = localStorage.getItem('signalstrength');
  signalStrength = this.signal !== null ? parseInt(this.signal, 10) : null;
  activeMainTab = 0;
  customerService: CustomerService = inject(CustomerService);
  plantName = localStorage.getItem('plantname');
  category: string = 'solar';
  deviceOffline!: boolean;
  strength!: number;
  faWifi = faWifi;
  ngOnInit(): void {
    this.deviceOffline = this.getDeviceStatusFromLocalStorage();
    this.strength = this.getStrengthFromLocalStorage();
  }

  private getDeviceStatusFromLocalStorage(): any {
    const deviceStatusString = localStorage.getItem('devicestatus');
    if (deviceStatusString) {
      try {
        return JSON.parse(deviceStatusString);
      } catch (e) {
        console.error('Error parsing device status from localStorage:', e);
      }
    }
    return null; // Default value in case of errors or missing data
  }

  private getStrengthFromLocalStorage(): number {
    const strengthString = localStorage.getItem('strength');
    if (strengthString) {
      const parsedStrength = parseInt(strengthString, 10);
      if (!isNaN(parsedStrength)) {
        return parsedStrength;
      } else {
        console.error(
          'Error parsing strength from localStorage. Value is not a number.',
        );
      }
    }
    return 0; // Default value
  }

  getStrengthImage(strength: number | undefined) {
    let imageName = 'strength-' + strength;

    return imageName;
  }

  setCategory(category: string) {
    this.category = category;
  }

  onMainTabChange(index: number) {
    this.activeMainTab = index;
  }

  getMainTabClass(index: number): string {
    return this.activeMainTab === index
      ? 'bg-blue-600 text-white'
      : 'bg-gray-800 text-gray-400 hover:bg-gray-700';
  }
}
export enum ChartType {
  upTime,
  downTime,
}
