<div
  [class.open]="isOpen"
  class="side-sheet fixed top-[10rem] bottom-0 h-[72%] w-[55%] p-10 z-30 right-[-100%] bg-[#22282F] rounded-lg my-4 py-3 px-5 gradient-border"
>
  <div class="flex flex-col pr-32">
    <div class="w-full">
      <h2 class="text-xl font-semibold mb-4">{{ plantName }}</h2>

      <div class="grid grid-cols-4 w-[120%] text-white -ml-[1%]">
        <div class="flex-col ml-2">
          <div>
            <label for="category" class="block text-sm mb-2">Category</label>
          </div>
          <div class="mt-[3%]">
            <span class="text-center">{{ plantInformation?.category }} </span>
          </div>
        </div>
        <div class="flex-col">
          <div>
            <label for="category" class="block text-sm mb-2">Assignee</label>
          </div>
          <div class="mt-[3%]">
            <span>{{ plantInformation?.assignee }}</span>
          </div>
        </div>
        <div class="flex-col">
          <div>
            <label for="category" class="block text-sm mb-2">Priority</label>
          </div>
          <div class="mt-[3%]">
            <span>{{ plantInformation?.priority }}</span>
          </div>
        </div>
        <div class="flex-col">
          <div>
            <label for="category" class="block text-sm mb-2"
              >Ticket Status</label
            >
          </div>
          <div class="mt-[3%]">
            <span>{{ plantInformation?.ticketStatus }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="flex-col mt-[4%]">
      <div><label for="subject" class="block text-sm mb-2">Subject</label></div>
      <div class="mt-[2%]">
        <p>{{ plantInformation?.subject }}</p>
      </div>
    </div>

    <div class="flex-col mt-[4%]">
      <div>
        <label for="subject" class="block text-sm mb-2"> Description </label>
      </div>
      <div class="mt-[2%]">
        <p>{{ plantInformation?.description }}</p>
      </div>
    </div>
    <!-- @for (item of counter; track $index) {
        <div class="flex-col">
          <img src="../../assets/images/connectx.png" />
        </div>
      } -->

    <div class="flex-col mt-[4%]">
      <div><label for="subject" class="block text-sm mb-2">Comment</label></div>
      <div class="mt-[2%]">
        <p>{{ plantInformation?.comment }}</p>
      </div>
    </div>

    <!-- <div *ngIf="imageArray" class="flex">
      <div *ngFor="let item of imageArray" class="w-[60%] h-[60%]">
        <img src="item" class="w-[100%] h-[100%]" alt="error loading image" />
      </div>
    </div> -->
    <div class="flex justify-start space-x-3">
      <button
        type="button"
        (click)="toggle()"
        class="box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-5 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
      >
        Cancel
      </button>
    </div>
  </div>
</div>

<div *ngIf="isOpen" class="overlay" (click)="toggle()"></div>
