<div class="relative w-full h-full">
  <div
    class="center-circle absolute h-[75%] w-[65%] rounded-full bg-[#2E353F]"
  ></div>
  <div id="radialChart" class="w-full h-full">
    @if (chartOptions &&
    chartOptions.chart?.type && chartOptions.series && chartOptions.series.length > 0) {
    <apx-chart
      [series]="chartOptions.series!"
      [chart]="chartOptions.chart!"
      [plotOptions]="chartOptions.plotOptions!"
      [labels]="chartOptions.labels!"
      [fill]="chartOptions.fill!"
      [grid]="chartOptions.grid!"
      [responsive]="chartOptions.responsive!"
      class="h-full w-full"
    ></apx-chart>
    }
  </div>
</div>
