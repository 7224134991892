export interface StatsResponseData {
  status: number;
  message: string;
  data: StatsData;
}
export interface StatsData {
  stats: Stats;
  lifetimeGeneration: string;
  co2EmissionSaved: string;
  equivalentTreesPlanted: number;
  totalSavings: number;
  lat: number;
  lon: number;
  solarPecentInConsumption?: string;
  roi: number;
  batteryVoltage: string;
  outputVoltage: string;
  solarVoltage: string;
  solarSetting: string;
  deviceId: string;
  operatingMode: string;
  inverterModel: string;
  doubleBlocker: string;
  coalNotBurned: string;
  todayConsumption?: string;
  lifetimeConsumption?: string;
  plantType: PlantTypeEnum | string;
  inverterType: InverterType | string;
  modelName: string;
  totalPowerBackup?: string;
  animationFlow?: string;
  currencySymbol?: string;
  inverterRating?: string;
  solarPVInstalled?: string;
}
export interface Stats {
  id: string;
  generation: string;
  consumption: string;
  discharge: string;
  batteryDischargePercentage: string;
  inverterState: string;
  consumptionValue: string;
  gridCTCurrent: string;
  inverterCurrent: string;
  batteryState: string;
  lifetimeGeneration: string;
  mappingProtocol?: MappingProtocol;
  pvCurrent: string;
  feed_in: string;
  available_backup: string;
  input_voltage: string;
  battery_charge_percentage: string;
  time_remaining_for_charging: string;
  time_remaining_for_discharging: string;
  current_running_load_percentage: string;
  solar_power: string;
  solar_state: string;
  grid_state: string;
  last_updated_timestamp: number;
  wifi_signal_strength: number;
  charging_current: string;
  system_state_flag: string;
  battery_discharge_percentage: string;
  totalEnergy: string;
  energyThisMonth: string;
  energyLastMonth: string;
  energyToday: string;
  energyYesterday: string;
  energyThisYear: string;
  energyLastYear: string;
  pvVoltage1: string;
  pvVoltage2: string;
  pvVoltage3: string;
  pvVoltage4: string;
  pvCurrent1: string;
  pvCurrent2: string;
  pvCurrent3: string;
  pvCurrent4: string;
  pvPower1: string;
  pvPower2: string;
  pvPower3: string;
  pvPower4: string;
  stringDetailsStats: StringDetailsStats;
  phase_a_voltage: string;
  phase_b_voltage: string;
  phase_c_voltage: string;
  ac_output_type: string;
  discharging_current: string;
}

export interface StringDetailsStats {
  pvVoltages: string[];
  pvCurrents: string[];
  pvPowers: string[];
  pvNames: string[];
}

export enum PlantTypeEnum {
  GTI = 'GTI',
  SOLAR = 'SOLAR',
  NON_SOLAR = 'NON-SOLAR',
}

export enum InverterType {
  GTI = 'GTI',
  SOLARVERTERPRO = 'SOLARVERTERPRO',
  SOLAR = 'SOLAR',
  NON_SOLAR = 'NON-SOLAR',
  HYBRID = 'HYBRID',
  PCU = 'PCU',
  ON_GRID = 'On Grid', // On Grid meant GTI plant
}
export enum MappingProtocol {
  PCU = 'PCU',
  BITWISE = 'BITWISE',
}
