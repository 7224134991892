import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import {
  InverterType,
  PlantTypeEnum,
  Stats,
  StatsData,
} from '../../../shared/models/stats';
import { BaseComponent } from '../../../base/components/base.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { DateUtils } from '../../../shared/utils/date_utils';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { StringInfoComponent } from '../../../shared/components/string-info/string-info.component';
import { NoxDialogComponent } from '../../../shared/components/nox-dialog/nox-dialog.component';
import {
  NoxDialogParam,
  NoxDialogType,
} from '../../../shared/models/nox-dialog-param';
import { ProductService } from '../../../shared/service/product.service';
import { PlantDetail } from '../../../shared/models/plant';
import { NoxAlertType } from '../../../shared/models/enum';
import { NoxDatePipe } from '../../../shared/pipe/nox-date.pipe';
import {
  mapBackendPercentToFrontendValue,
  mapBackendToFrontend,
} from '../../../shared/utils/common-utils';

@Component({
  selector: 'app-energy-flow',
  standalone: true,
  templateUrl: './energy-flow.component.html',
  styleUrl: './energy-flow.component.scss',
  imports: [
    TranslateModule,
    CommonModule,
    FontAwesomeModule,
    MatDialogModule,
    StringInfoComponent,
    NoxDatePipe,
  ],
})
export class EnergyFlowComponent extends BaseComponent implements OnInit {
  calculateBatteryProgress(arg0: Stats | undefined) {
    let percent = 0;
    if (Number(arg0?.batteryState) != 0) {
      percent = Number(arg0?.battery_charge_percentage) ?? 0;
    } else {
      if (Number(arg0?.discharge) > 1 || Number(arg0?.discharging_current)) {
        Number(arg0?.battery_discharge_percentage);
        percent = Number(arg0?.battery_discharge_percentage) ?? 0;
      } else {
        percent = Number(arg0?.battery_charge_percentage) ?? 0;
      }
    }
    return percent + '%';
  }
  getState(arg0: Stats | undefined) {
    if (Number(arg0?.discharge) > 1 || Number(arg0?.discharging_current)) {
      return 'Discharging';
    } else {
      return 'Charging';
    }
  }
  getNumber(arg0: string | undefined) {
    return Number(arg0);
  }
  @Input() statData?: StatsData;
  selectedPlant?: PlantDetail | null;
  plantType = PlantTypeEnum;
  faChevronRight = faChevronRight;
  inverterType = InverterType;
  animationFlow: AnimationFlow = new AnimationFlow();
  isDeviceOffline: boolean = true;
  noxAlert?: NoxAlertType | null;
  alertType = NoxAlertType;
  dialog = inject(MatDialog);
  isOpen = false;
  productService = inject(ProductService);
  @ViewChild(StringInfoComponent) sideSheet!: StringInfoComponent;

  toggleSidebar() {
    this.isOpen = !this.isOpen;
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.productService.selectedPlant.subscribe((plant) => {
      this.selectedPlant = plant;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.animationFlow = new AnimationFlow();
    this.noxAlert = null;
    if (!this.checkDeviceOffline()) {
      this.isDeviceOffline = false;
      if (this.statData?.doubleBlocker) {
        this.buildAlertBlocker(this.statData.doubleBlocker);
      } else {
        this.createAnimationFlow();
      }
    } else {
      this.isDeviceOffline = true;
    }
  }
  /**
   * Alert Blocker
   * @param doubleBlocker
   */
  buildAlertBlocker(doubleBlocker: string) {
    switch (doubleBlocker) {
      case NoxAlertType.SHORT_CIRCUIT:
        this.noxAlert = NoxAlertType.SHORT_CIRCUIT;
        break;
      case NoxAlertType.INVERTER_OVERHEATED:
        this.noxAlert = NoxAlertType.INVERTER_OVERHEATED;
        break;
      case NoxAlertType.SHORT_CIRCUIT_AND_INVERTER_OVERHEATED:
        this.noxAlert = NoxAlertType.SHORT_CIRCUIT_AND_INVERTER_OVERHEATED;
        break;
      case NoxAlertType.OFFLINE:
        this.isDeviceOffline = true;
        break;
    }
  }
  /**
   *
   * @returns boolean
   */
  private checkDeviceOffline(): boolean {
    if (!this.statData?.stats) {
      return true;
    }
    // Current timestamp
    const currentTime: number = Date.now();

    // Response timestamp
    const responseTime: number =
      this.statData?.stats.last_updated_timestamp * 1000;

    // Difference in hours
    const hours: number = DateUtils.calculateHoursDifference(
      currentTime,
      responseTime,
    );

    // If offline for more than 1 hour, short circuit and turn off wifi
    if (hours > 1) {
      return true;
    }
    return false;
  }

  /**
   * Create Animation Flow
   */
  createAnimationFlow() {
    if (this.statData) {
      switch (this.statData.animationFlow) {
        case '1.1':
        case '2.1':
        case '3.1':
        case '4.1':
          this.animationFlow.solarToCenter = true;
          this.animationFlow.centerToHome = true;
          break;
        case '1.2':
        case '2.2':
        case '3.2':
        case '4.2':
          this.animationFlow.solarToCenter = true;
          this.animationFlow.centerToHome = true;
          this.animationFlow.centerToBattery = true;
          break;
        case '1.3':
        case '2.3':
        case '3.3':
        case '4.3':
          this.animationFlow.solarToCenter = true;
          this.animationFlow.centerToHome = true;
          this.animationFlow.batteryToCenter = true;
          break;
        case '1.4':
        case '2.4':
        case '3.4':
        case '4.4':
        case '6.2':
        case '7.2':
          this.animationFlow.batteryToCenter = true;
          this.animationFlow.centerToHome = true;
          break;
        case '1.5':
        case '2.5':
        case '3.5':
        case '4.5':
        case '6.3':
        case '7.3':
          this.animationFlow.gridToCenter = true;
          this.animationFlow.centerToHome = true;
          break;
        case '1.7':
        case '2.7':
        case '3.7':
        case '4.7':
        case '6.4':
        case '7.4':
          this.animationFlow.gridToCenter = true;
          this.animationFlow.centerToBattery = true;
          this.animationFlow.centerToHome = true;
          break;

        case '1.8':
        case '2.8':
        case '3.8':
        case '4.8':
          this.animationFlow.solarToCenter = true;
          this.animationFlow.centerToBattery = true;
          break;
        case '4.9':
        case '6.5':
          this.animationFlow.gridToCenter = true;
          this.animationFlow.centerToBattery = true;
          break;
        case '1.11':
        case '2.11':
        case '3.11':
        case '4.11':
          this.animationFlow.solarToCenter = true;
          this.animationFlow.centerToBattery = true;
          this.animationFlow.gridToCenter = true;
          this.animationFlow.centerToHome = true;
          break;
        case '1.12':
        case '2.12':
        case '3.12':
          this.animationFlow.solarToCenter = true;
          this.animationFlow.gridToCenter = true;
          this.animationFlow.centerToHome = true;
          break;
        case '4.12':
          this.animationFlow.solarToCenter = true;
          this.animationFlow.centerToBattery = true;
          this.animationFlow.centerToGrid = true;
          this.animationFlow.centerToHome = true;
          break;
        case '4.13':
          this.animationFlow.solarToCenter = true;
          this.animationFlow.centerToBattery = true;
          this.animationFlow.centerToGrid = true;
          break;
        case '4.14':
        case '5.1':
          this.animationFlow.solarToCenter = true;
          this.animationFlow.centerToGrid = true;
          break;
        case '4.15':
          this.animationFlow.solarToCenter = true;
          this.animationFlow.centerToHome = true;
          this.animationFlow.centerToGrid = true;
          break;
        default:
          break;
      }
    }
  }

  stringInfoDialog() {
    const dialogRef = this.dialog.open(StringInfoComponent);

    dialogRef.afterClosed().subscribe((result) => {});
  }

  openDialog() {
    let noxDialogData: NoxDialogParam = {
      type: NoxDialogType.none,
      title: 'Please check the following items',
      messageList: [
        'Ensure that your device is powered on.',
        'Check the functionality of the router connected to your device. ',
        'Check if the router has internet connection.',
        'Confirm your device is in range of the router. ',
        'Confirm the signal strength is good, or if any obstruction is affecting data transmission.',
        "Confirm whether the wifi router's name or password has been changed. If yes, then please Re-Configure the device. ",
      ],
      disclaimer:
        "During configuration, ensure you have the latest ConnectX app installed and that you're near the device with a strong WiFi signal.",
    };
    const dialogRef = this.dialog.open(NoxDialogComponent, {
      data: noxDialogData,
    });
  }
}
class AnimationFlow {
  solarToCenter: boolean = false;
  centerToHome: boolean = false;
  centerToBattery: boolean = false;
  batteryToCenter: boolean = false;
  gridToCenter: boolean = false;
  centerToGrid: boolean = false;
  // create constructor with
  constructor() {}
}
