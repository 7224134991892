import { plainObject } from '../interfaces/plain-obj';
import { PlantDetailsHeaderVal } from '../interfaces/plant-details-header-val';
import { getMonthNameByMonth } from '../utils/date_utils';

export const sharedheaderKeyMap: plainObject = {
  totalPlants: 'Total Plant',
  plantsOnline: 'Online',
  plantsOffline: 'Offline',
  plantsInstalledLastMonth: 'Last Month',
  plantsInstalledThisMonth: 'This Month',
  notification: 'Notification',
  plantName: 'Plant Name',
  modelName: 'Model Name',
  importance: 'Importance',
  dataLogger: 'Data Logger',
  ocurred: 'Ocurred',
  resolved: 'Resolved',
};

export const plantDetailsHeaderData: Array<PlantDetailsHeaderVal> = [
  {
    icon: 'assets/svg/total_plants.svg',
    description: 'Total Plant',
    value: 88,
  },
  {
    icon: 'assets/svg/online_plants.svg',
    description: 'Online',
    value: 99,
  },
  {
    icon: 'assets/svg/offline_plants.svg',
    description: 'Offline',
    value: 22,
  },
  {
    icon: 'assets/svg/circle.svg',
    description: 'Last Month',
    overlayIcon: 'assets/svg/calendar.svg',
    overlayString: getMonthNameByMonth(new Date().getMonth()),
    value: 33,
  },
  {
    icon: 'assets/svg/circle.svg',
    description: 'This Month',
    overlayIcon: 'assets/svg/calendar.svg',
    overlayString: getMonthNameByMonth(new Date().getMonth() + 1),
    value: 44,
  },
];

export const alertHeaders: Array<string> = [
  'Plant Name',
  'Alerts',
  'Type',
  'Model Name',
  'Datalogger',
  'Occured',
  'Resolved',
  'Customer Details',
];

export const lifetimeSavings = "lifetimeSavings";
export const INR_THOUSAND = 1000;
export const INR_LAKH = 100 * INR_THOUSAND;
export const INR_CRORE = 100 * INR_LAKH;
