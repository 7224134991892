<div
  class="relative flex flex-grow items-center justify-center h-full w-full m-auto dark-gradient-bg"
>
  <div
    class="flex flex-col flex-grow justify-start items-start bg-[#1d232b] rounded-lg absolute top-0 bottom-0 left-0 right-0 mx-5 my-5"
  >
    <div
      class="flex flex-row items-center mt-10 cursor-pointer"
      (click)="goBack()"
    >
      <img
        class="ml-10"
        src="../../../../assets/images/customer_screens/back arrow.svg"
        alt="
        Back"
      />
      <span class="text-white ml-4">Back</span>
    </div>
    <div class="flex flex-col mt-10 items-center h-full w-full">
      <div>
        <h2 class="text-lg font-medium gradient-header underline">
          Contact us
        </h2>
      </div>

      <div class="flex flex-grow items-center justify-center w-full h-full">
        <div class="flex flex-row w-[60%] text-white justify-evenly">
          <div>
            <img
              src="../../../../assets/images/customer_screens/envelop.svg"
              alt="Email"
            />
          </div>
          <div class="linee h-64 mt-4 opacity-30 bg-slate-50"></div>
          <div class="grid gap-9 text-dark-content">
            <div class="flex flex-row p-1 ml-6 items-center">
              <div>
                <img
                  class="imgs"
                  src="../../../../assets/svg/location_gray.svg"
                  alt="Map"
                />
              </div>
              <div class="flex flex-col ml-8 text-s justify-center">
                <div><span class="text-sm font-medium">Address</span></div>
                <div>
                  <span>Plot No. 150, Sector 44, Gurgaon,Haryana-122003</span>
                </div>
              </div>
            </div>
            <div class="flex flex-row p-1 ml-6 items-center">
              <div>
                <img
                  class="imgs"
                  src="../../../../assets/images/customer_screens/phone.svg"
                  alt="Phone"
                />
              </div>
              <div class="flex flex-col ml-8 text-s justify-center">
                <div>
                  <span class="text-sm font-medium">Sales Support</span>
                </div>
                <div><span>+91-9999933039</span></div>
              </div>
            </div>
            <div class="flex flex-row p-1 ml-6 items-center">
              <div>
                <img
                  class="imgs"
                  src="../../../../assets/images/customer_screens/message.svg"
                  alt="Message"
                />
              </div>
              <div class="flex flex-col ml-8 text-s justify-center">
                <div><span class="font-semibold">E-mail</span></div>
                <div class="cursor-pointer">
                  <span
                    [innerHTML]="'care&#64;luminousindia.com'"
                    (click)="sendEmail()"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
