import { Component, inject } from '@angular/core';
import { SeService } from '../../services/se.service';
import { USER_KEY } from '../../../shared/service/storage.service';
import { User } from '../../../shared/models/user';
import { BaseComponent } from '../../../base/components/base.component';
import { CommonModule } from '@angular/common';
import {
  DeletePlantHistory,
  DeletePlantHistoryResponse,
} from '../../../shared/models/delete-plant-history';
import {
  deleteplanthistoryHeaderKeys,
  headertitle,
} from '../../se.constants.ts/constants';
import { plainObject } from '../../../shared/interfaces/plain-obj';
import { Subject, Subscription } from 'rxjs';
@Component({
  selector: 'app-history',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './history.component.html',
  styleUrl: './history.component.scss',
})
export class HistoryComponent extends BaseComponent {
  public seService: SeService = inject(SeService);
  headerkeys: Array<string> = deleteplanthistoryHeaderKeys;
  headerTitle: plainObject = headertitle;
  deleteplantresponse!: Array<DeletePlantHistoryResponse>;

  deleteplanthistorysubscription!: Subscription;

  ngOnInit(): void {
    this.fetchAPIData();
  }

  fetchAPIData() {
    this.resolveAllSubscriptions([this.deleteplanthistory()]);
  }
  deleteplanthistory() {
    let deleteplanthistory = new Subject<boolean>();
    let user: User = this.storageService.getObject(USER_KEY);
    this.deleteplanthistorysubscription = this.seService
      .deleteHistory(user.id)
      .subscribe({
        next: (response: DeletePlantHistory) => {
          this.deleteplantresponse = response?.data;
          deleteplanthistory.next(true);
        },
        error: (e: Error) => {
          this.router.navigate(['/login']);
          this.logger.error(e.message);
        },

        complete: () => deleteplanthistory.complete(),
      });
    return deleteplanthistory;
  }
}
