import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import moment from 'moment';
import { ClickOutsideDirective } from '../../utils/click.outside.directive';
import { NoxDatePipe } from '../../pipe/nox-date.pipe';

@Component({
  selector: 'app-nox-date-picker',
  standalone: true,
  imports: [CommonModule, FormsModule, ClickOutsideDirective, NoxDatePipe],
  templateUrl: './nox-date-picker.component.html',
  styleUrl: './nox-date-picker.component.scss',
})
export class NoxDatePickerComponent implements OnInit {
  getDate(day: number, year: number, month: number) {
    return new Date(year, month, day);
  }

  checkNextMonthStatus(day: number, month: number, year: number) {
    let date = new Date(year, month, day);
    if (this.todayDate > date) {
      return true;
    }
    return false;
  }
  @Input() selectedDate?: Date;
  @Output() onDatePicked = new EventEmitter<Date>();
  formattedDate!: string;
  year!: number;
  month!: number;
  daysInMonth: number[] = [];
  daysInPrevMonth: number[] = [];
  daysInNextMonth: number[] = [];
  enableDatePicker: boolean = false;
  todayDate = new Date();
  ngOnInit() {
    this.formattedDate = moment(this.selectedDate).format('DD/MM/YYYY');
    if (this.selectedDate) {
      this.year = this.selectedDate?.getFullYear();
      this.month = this.selectedDate?.getMonth();
    }

    this.displayDates();
  }

  displayDates() {
    this.daysInMonth = [];
    this.daysInPrevMonth = [];
    this.daysInNextMonth = [];
    this.daysInMonth = this.getDaysInMonth(this.year, this.month);
    this.daysInPrevMonth = this.getDaysInPrevMonth(this.year, this.month);
    this.daysInNextMonth = this.getDaysInNextMonth(this.year, this.month);
  }

  getDaysInMonth(year: number, month: number): number[] {
    const date = new Date(year, month, 1);
    const days = [];
    while (date.getMonth() === month) {
      days.push(date.getDate());
      date.setDate(date.getDate() + 1);
    }
    return days;
  }

  getDaysInPrevMonth(year: number, month: number): number[] {
    const date = new Date(year, month, 0);
    const days = [];

    for (let i = 0; i <= date.getDay(); i++) {
      const text = date.getDate() - date.getDay() + i;
      days.push(text);
    }

    return days;
  }

  getDaysInNextMonth(year: number, month: number): number[] {
    const date = new Date(year, month + 1, 1);
    const days = [];
    while (date.getDay() > 0) {
      days.push(date.getDate());

      date.setDate(date.getDate() + 1);
    }
    return days;
  }

  selectDate(day: number) {
    this.selectedDate = new Date(this.year, this.month, day);
    this.formattedDate = moment(this.selectedDate).format('DD/MM/YYYY');
    this.onDatePicked.emit(this.selectedDate);
    this.enableDatePicker = false;
  }

  nextMonth() {
    if (this.month === 11) {
      this.year++;
      this.month = 0;
    } else {
      this.month++;
    }
    this.displayDates();
  }

  prevMonth() {
    if (this.month === 0) {
      this.year--;
      this.month = 11;
    } else {
      this.month--;
    }
    this.displayDates();
  }

  changeMonth(month: number) {
    this.month = month;
    this.displayDates();
  }

  changeYear(year: number) {
    this.year = year;
    this.displayDates();
  }

  toggleDatePicker() {
    this.enableDatePicker = !this.enableDatePicker;
  }
  clickOutside() {
    this.enableDatePicker = false;
  }
}
