import { Component, OnInit, inject, signal } from '@angular/core';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { Subject, of, takeUntil, timer } from 'rxjs';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../../base/components/base.component';
import { User, UserType } from '../../models/user';
import { USER_KEY } from '../../service/storage.service';
import { BaseUserService } from '../../../base/services/base-user.service';
@Component({
  selector: 'app-sub-header',
  standalone: true,
  imports: [
    MatToolbarModule,
    MatIconModule,
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    TranslateModule,
    RouterModule,
  ],
  templateUrl: './sub-header.component.html',
  styleUrl: './sub-header.component.scss',
})
export class SubHeaderComponent extends BaseComponent {
  user: User;
  date: Date;
  baseService = inject(BaseUserService);
  subHeaderMenuItem = signal<MenuItem[]>([
    {
      icon: 'home',
      route: 'dashboard/home',
    },
    {
      label: this.translateService.instant('solar'),
      route: 'dashboard/solar',
    },
    {
      label: this.translateService.instant('non_solar'),
      route: 'dashboard/non-solar',
    },
  ]);
  isViewAsCustomer = false;
  constructor() {
    super();
    this.user = this.storageService.getObject(USER_KEY);
    this.date = new Date();
    let url = this.router.url;
    this.isViewAsCustomer =
      url.includes('/customer/dashboard') &&
      this.user.user_type === UserType.SYSTEM_INTEGRATOR;

    this.baseService.timerService
      .getTimerObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.date = new Date();
      });
  }
  private destroy$ = new Subject<void>();

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

export type MenuItem = {
  icon?: string;
  label?: string;
  route: string;
};
