import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { ProductService } from '../../../shared/service/product.service';
import { NGXLogger } from 'ngx-logger';
import { BaseComponent } from '../../../base/components/base.component';
import { FaqData } from '../../../shared/models/faqs';
import { FAQType, NoxInverterType } from '../../../shared/models/enum';
import {
  INVERTER_TYPE,
  StorageService,
} from '../../../shared/service/storage.service';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Subject, every, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { SessionService } from '../../../shared/service/session.service';
@Component({
  selector: 'app-faqs',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule],
  templateUrl: './faqs.component.html',
  styleUrl: './faqs.component.scss',
})
export class FAQsComponent extends BaseComponent implements OnInit, OnDestroy {
  private productService = inject(ProductService);
  faqData: FaqData[] = [];
  faqDefaultData: FaqData[] = [];
  dosList: string[] = [];
  dosDefaultList: string[] = [];
  dontList: string[] = [];
  dontDefaultList: string[] = [];

  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  selectedType: FAQType = FAQType.faq;
  faqType = FAQType;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private _location: Location) {
    super();
  }

  ngOnInit(): void {
    this.baseUserService.sessionService.startBnWatch(this.router);
    let inverterType = this.fetchInverterType();
    this.resolveAllSubscriptions([
      this.fetchFaqsAndDosAndDonts(inverterType),
      this.fetchDonsAndDont(inverterType),
    ]);
  }
  fetchDonsAndDont(category: string): Subject<boolean> {
    let returnSubject = new Subject<boolean>();
    this.productService
      .fetchDosAndDont(category, 'false')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (dosDontResponse) => {
          {
            this.dosList = [];
            this.dontList = [];
            dosDontResponse.data.dos.forEach((element) => {
              this.dosList.push(element);
            });

            dosDontResponse.data.dont.forEach((element) => {
              this.dontList.push(element);
            });
            this.dosDefaultList = this.dosList;
            this.dontDefaultList = this.dontList;
            returnSubject.next(true);
          }
        },
        error: (err) => {
          this.logger.error(err);
          returnSubject.next(true);
        },
        complete: () => {
          returnSubject.complete();
        },
      });
    return returnSubject;
  }
  fetchInverterType(): string {
    let inverterType = this.storageService.get(INVERTER_TYPE);

    let category: string = '4';
    switch (inverterType) {
      case NoxInverterType.GTI:
        category = '1';
        break;
      case NoxInverterType.PCU:
        category = '2';
        break;
      case NoxInverterType.REGALIA_W:
        category = '3';
        break;
      case NoxInverterType.REGALIA_F:
        category = '4';
        break;
      case NoxInverterType.ZELIO:
        category = '6';
        break;
      case NoxInverterType.ZELIO_WIFI_V2:
        category = '7';
        break;
      case NoxInverterType.ICRUZE:
        category = '8';
        break;
      case NoxInverterType.SOLARVERTERPRO:
        category = '9';
        break;
      case NoxInverterType.HYBRID:
        category = '10';
        break;
      case NoxInverterType.LIIONSOLARVERTER:
        category = '11';
        break;
      case NoxInverterType.LITHIUMVERTER:
        category = '12';
        break;
      case NoxInverterType.OPTIMUS:
        category = '13';
        break;
      case NoxInverterType.BATTERY:
        category = '15';
        break;
      default:
        category = '14';
        break;
    }
    return category;
  }

  toggleAnswer(faq: FaqData) {
    faq.expand = !faq.expand;
  }
  /**
   * Fetch FAQs and Dos and Donts data
   */
  fetchFaqsAndDosAndDonts(category: string): Subject<boolean> {
    let loaderSubject = new Subject<boolean>();
    this.productService
      .fetchFaqs(category, 'false')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (faqResponse) => {
          let faqs: FaqData[] = [];
          faqResponse.data.forEach((element) => {
            faqs.push({
              question: element.question,
              answer: element.answer,
              expand: false,
            });
          });
          this.faqDefaultData = faqs;
          this.faqData = this.faqDefaultData;
          loaderSubject.next(true);
        },
        error: (err) => {
          this.logger.error(err);
          loaderSubject.next(true);
        },
        complete: () => loaderSubject.complete(),
      });

    return loaderSubject;
  }
  /**
   *
   * @param type Update selected type
   */
  updateSelectedType(type: FAQType) {
    switch (type) {
      case FAQType.faq:
        this.selectedType = FAQType.faq;
        break;
      case FAQType.dos:
        this.selectedType = FAQType.dos;
        break;
      case FAQType.dont:
        this.selectedType = FAQType.dont;
        break;
      default:
        break;
    }
  }

  /**
   *  onSearchFilter
   */
  onSearchFilter(value: string) {
    this.faqData = this.faqDefaultData;
    this.dosList = this.dosDefaultList;
    this.dontList = this.dontDefaultList;

    switch (this.selectedType) {
      case FAQType.faq:
        this.faqData = this.faqDefaultData.slice().filter((faq) => {
          return faq.question.toLowerCase().includes(value.toLowerCase());
        });
        break;
      case FAQType.dos:
        this.dosList = this.dosDefaultList.slice().filter((dos) => {
          return dos.toLowerCase().includes(value.toLowerCase());
        });
        break;
      case FAQType.dont:
        this.dontList = this.dontDefaultList.slice().filter((dont) => {
          return dont.toLowerCase().includes(value.toLowerCase());
        });

        break;
      default:
        break;
    }
  }
  goBack(): void {
    this._location.back();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.baseUserService.sessionService.stopBnTimer();
  }

  data = [
    {
      description:
        'Lorem Ipsum is simply dummy the printing and typesetting industry',
      icon: 'assets/images/customer_screens/half-arrow.svg',
    },
    {
      description:
        'Lorem Ipsum is simply dummy the printing and typesetting industry',
      icon: 'assets/images/customer_screens/half-arrow.svg',
    },
    {
      description:
        'Lorem Ipsum is simply dummy the printing and typesetting industry',
      icon: 'assets/images/customer_screens/half-arrow.svg',
    },
    {
      description:
        'Lorem Ipsum is simply dummy the printing and typesetting industry',
      icon: 'assets/images/customer_screens/half-arrow.svg',
    },
    {
      description:
        'Lorem Ipsum is simply dummy the printing and typesetting industry',
      icon: 'assets/images/customer_screens/half-arrow.svg',
    },
    {
      description:
        'Lorem Ipsum is simply dummy the printing and typesetting industry',
      icon: 'assets/images/customer_screens/half-arrow.svg',
    },
    {
      description:
        'Lorem Ipsum is simply dummy the printing and typesetting industry',
      icon: 'assets/images/customer_screens/half-arrow.svg',
    },
    {
      description:
        'Lorem Ipsum is simply dummy the printing and typesetting industry',
      icon: 'assets/images/customer_screens/half-arrow.svg',
    },
  ];
}
