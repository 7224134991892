export interface NoxDialogParam {
  /**
   * Pass the type of dialog to be displayed. eg alert, confirm, prompt, info
   */
  type: NoxDialogType;
  /**
   * Pass the category of dialog to be displayed. eg stringInfo
   */
  category?: NoxDialogCategory;

  /**
   * Pass the title of dialog to be displayed. eg "Alert"
   */
  title?: string;
  /**
   * Pass the message of dialog to be displayed. eg "Are you sure you want to delete?"
   */
  message?: string;

  /**
   * Pass the message, if you want to display multiple messages in dialog. eg ["Message1", "Message2"]
   */
  messageList?: string[];

  /**
   * enabledPositiveBtn: Pass true if you want to enable positive button, else pass false
   */
  enabledPositiveBtn?: boolean;

  /**
   * enabledNegativeBtn: Pass true if you want to enable negative button, else pass false
   */
  enabledNegativeBtn?: boolean;

  /**
   * enabledNeutralBtn: eg action button yes,delete, confirm
   */
  positiveBtnLabel?: string;
  /**
   * negativeBtnLabel: eg action button no, cancel
   */
  negativeBtnLabel?: string;

  /**
   * disclaimer: Pass the disclaimer message to be displayed. eg "This action cannot be undone."
   */
  disclaimer?: string;

  /**
   * disclaimerList: Pass the disclaimer message, if you want to display multiple disclaimer messages. eg ["Disclaimer1", "Disclaimer2"]
   */
  dialogSize?: NoxDialogSize;

  /**
   * When dialog type is image, send image url
   */
  imageUrl?: string;
}

export enum NoxDialogType {
  alert = 'alert',
  confirm = 'confirm',
  prompt = 'prompt',
  info = 'info',
  image = 'image',
  none = 'none',
}

export enum NoxDialogCategory {
  stringInfo = 'stringInfo',
}

export enum NoxDialogSize {
  small = 1,
  medium = 2,
  large = 3,
}
