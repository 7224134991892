import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { TicketDialogComponent } from '../ticket-dialog/ticket-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { EChartsOption } from 'echarts';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import {
  ApexAxisChartSeries,
  ApexNonAxisChartSeries,
  ChartComponent,
  NgApexchartsModule,
} from 'ng-apexcharts';
import {
  ChartOptions,
  ChartOptionsNonAxis,
  NoxChartDta,
  NoxChartXY,
} from '../../../../shared/models/nox-chart';
import { DateUtils } from '../../../../shared/utils/date_utils';
import moment from 'moment';
import {
  AnalyticsDetailsdata,
  AnalyticsDetails,
} from '../../../../shared/models/analytics-details';
import { CustomerService } from '../../../services/customer.service';
import { BaseComponent } from '../../../../base/components/base.component';
import { Subject } from 'rxjs';
import { plainObject } from '../../../../shared/interfaces/plain-obj';
import { GraphData } from '../../../../shared/models/graph-data';

@Component({
  selector: 'app-analytic',
  standalone: true,
  imports: [
    TicketDialogComponent,
    TranslateModule,
    CommonModule,
    NgApexchartsModule,
    NgxEchartsDirective,
  ],
  providers: [provideEcharts()],

  templateUrl: './analytic.component.html',
  styleUrl: './analytic.component.scss',
})
export class AnalyticComponent extends BaseComponent implements OnInit {
  @ViewChild(TicketDialogComponent) sideSheet!: TicketDialogComponent;
  @ViewChild('chart') chart!: ChartComponent;
  barsystemdowntimegraph: Partial<ChartOptions> = {};
  chartOptions: Partial<ChartOptions> = {};
  series: ApexAxisChartSeries = [];
  chartOptionsUpTime: Partial<ChartOptions> = {};
  chartOptionsCurtailment: Partial<ChartOptions> = {};
  seriesUpTime: ApexAxisChartSeries = [];
  seriesCurtailment: ApexAxisChartSeries = [];
  public chartOptionPerformance!: Partial<ChartOptions>;
  public chartOptionNonAxis!: Partial<ChartOptionsNonAxis>;
  public seriesPerformance: ApexNonAxisChartSeries = [81];
  chartDom: HTMLElement | null = null;
  myChart: echarts.ECharts | null = null;
  updateInterval: any;
  spinnerShowing = false;

  analyticalTabs = ['All'];
  activeAnalyticalTab = 0;
  eChartOption?: EChartsOption;
  chartOptionCylindrical: Partial<ChartOptions> = {};
  customerService: CustomerService = inject(CustomerService);

  arraysystemDowntime: Array<number> = [];
  arraypeakSystemHours: Array<number> = [];
  arraygridUptime: Array<number> = [];
  analyticsData: AnalyticsDetailsdata = {
    systemDowntime: null,
    performance: 'string',
    insulationFault: 'string',
    speicifYield: 'string',
    sinceCleaning: 'string',
    mpptLoss: { string: 0 },
    alerts: ['string'],
    peakSystemHours: null,
    soilingLoss: 0,
    shading: 'string',
    communicationLoss: 'string',
    missMatchLoss: 0,
    stringLoss: 'string',
    lowVoltageError: null,
    highVoltageError: null,
    gridUptime: 0,
  };
  graphDataValues!: GraphData;

  graphdate: Array<number> = [];
  systemDowntimeData: { value: number; date: number }[] = [];
  peakSystemHoursData: { value: number; date: number }[] = [];
  gridUpTimeData: { value: number; date: number }[] = [];

  ngOnInit(): void {
    this.resolveAllSubscriptions([
      this.getAnalyticsData(),
      this.getGraphAnalytics(),
    ]);
  }

  getAnalyticsData() {
    let loaderSubject = new Subject<boolean>();
    let deviceId = localStorage.getItem('deviceId') ?? '';
    this.customerService.getAnalyticsDetails(deviceId).subscribe({
      next: (analyticsData: AnalyticsDetails) => {
        loaderSubject.next(true);
        this.analyticsData = analyticsData.data;
        this.plotGauge(+this.analyticsData.performance.split('.')[0]);
        this.plotCylinderChart(this.analyticsData.mpptLoss);
      },
      error: (error: Error) => {},
      complete: () => {
        loaderSubject.complete();
      },
    });
    return loaderSubject;
  }

  plotCurtailmentLineChart(data: NoxChartDta[]) {
    this.seriesCurtailment = data.map((item) => {
      return {
        name: item.name,
        data: item.noxXY.map((item) => {
          return {
            x: item.x,
            y: item.y.toFixed(3),
          };
        }),
      };
    });

    /**
     * Line chart
     */
    this.chartOptionsCurtailment = {
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'vertical',
          shadeIntensity: 0.5,
          gradientToColors: ['#53A15F', '#84A1C6'],
          inverseColors: true,
          opacityFrom: 0.3,
          opacityTo: 0.8,
        },
      },
      chart: {
        fontFamily: 'Roboto, sans-serif',
        type: 'line',
        height: '160',
        toolbar: {
          show: true,
          tools: {
            pan: true,
          },
          autoSelected: 'pan',
          export: {
            csv: {
              dateFormatter(timestamp) {
                return moment(timestamp).format('YYYY-MM-DD HH:mm:ss a');
              },
            },
          },
        },
        events: {
          beforeZoom: (e, { xaxis }) => {
            let maindifference =
              new Date(data[0].noxXY[data[0].noxXY.length - 1].x).valueOf() -
              new Date(data[0].noxXY[0].x).valueOf();
            let zoomdifference = xaxis.max - xaxis.min;

            if (zoomdifference > maindifference)
              return {
                // dont zoom out any further
                xaxis: {
                  min: data[0].noxXY[0].x,
                  max: data[0].noxXY[data[0].noxXY.length - 1].x,
                },
              };
            else {
              return {
                // keep on zooming
                xaxis: {
                  min: xaxis.min,
                  max: xaxis.max,
                },
              };
            }
          },
        },
      },
      grid: {
        padding: {
          bottom: 5,
        },

        yaxis: {
          lines: {
            show: true,
            offsetX: 5,
          },
        },
      },

      series: this.seriesCurtailment,

      stroke: {
        width: 1,
      },
      dataLabels: {
        style: {
          colors: ['#F44336', '#E91E63', '#9C27B0'],
        },
      },
      markers: {
        size: 0,
      },
      xaxis: {
        type: 'datetime',
        tickAmount: 5,

        labels: {
          rotateAlways: true,
          rotate: 360,
          offsetY: 10,
          formatter: function (value, timestamp: number) {
            return DateUtils.formatDateFromLocalEpoch(timestamp, 'DD/MM');
          },
          style: {
            colors: '#94989E',
            fontSize: '10',
          },
        },
        title: {
          offsetY: -10,
          text: 'DD/MM',
          style: {
            color: '#FFFFFF',
            fontSize: '10',
            fontWeight: '500',
          },
        },
      },
      yaxis: {
        title: {
          text: 'Hour',

          style: {
            color: '#FFFFFF',
            fontSize: '10',
            fontWeight: '500',
          },
        },
        labels: {
          style: {
            colors: '#94989E',
            fontSize: '10',
            fontWeight: '500',
          },
        },
      },
      tooltip: {
        x: { show: false },
        y: {
          title: {
            formatter: () => ``,
          },
          formatter: (value: number) => `${value} kW`,
        },
      },
    };
  }
  plotCylinderChart(data: plainObject | null) {
    let pvData: Array<number> = [];

    if (data == null) {
      this.analyticsData.mpptLoss = {};
      pvData = [0, 0, 0, 0];
    } else {
      let abc = ['pv1', 'pv2', 'pv3', 'pv4'];
      let tempData: plainObject = {};
      abc.forEach((x: any) => {
        tempData[x] = data[x];
      });

      for (let item in tempData) {
        if (data[item] !== null) {
          pvData.push(data[item]);
        }
        if (data[item] == null) {
          pvData.push(0);
        }
      }
    }

    this.chartOptionCylindrical = {
      series: [
        {
          data: pvData,
        },
      ],
      chart: {
        type: 'bar',
        height: '200px',
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '30%',
          borderRadius: 5,
          borderRadiusApplication: 'end',
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + '%';
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#ffffff'],
        },
      },
      xaxis: {
        categories: ['PV1', 'PV2', 'PV3', 'PV4'],
        position: 'bottom',
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: '#ffffff',
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
      fill: {
        colors: ['#FCD34D', '#EF4444', '#A78BFA', '#60A5FA'],
      },
      tooltip: {
        enabled: false,
      },
    };
  }
  plotGauge(data: number) {
    this.eChartOption = {
      series: [
        {
          type: 'gauge',
          startAngle: 180,
          endAngle: 0,
          min: 0,
          max: 100,
          radius: '100%',
          axisLine: {
            lineStyle: {
              width: 30,
              color: [
                [0.3, '#C04260'],
                [0.7, '#BD9355'],
                [1, '#71C15D'],
              ],
            },
          },
          pointer: {
            itemStyle: {
              color: 'auto',
            },
          },
          axisLabel: {
            color: 'inherit',
            distance: 30,
            fontSize: 10,
          },
          detail: {
            valueAnimation: true,
            formatter: '',
            color: 'transparent',
          },
          data: [
            {
              value: data,
            },
          ],
        },
      ],
    };
  }

  plotUpTimeLineChart(data: NoxChartDta[]) {
    this.seriesUpTime = data.map((item) => {
      return {
        name: item.name,
        data: item.noxXY.map((item) => {
          return {
            x: item.x,
            y: item.y.toFixed(3),
          };
        }),
      };
    });
    /**
     * Line chart
     */
    this.chartOptionsUpTime = {
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'vertical',
          shadeIntensity: 0.5,
          gradientToColors: ['#53A15F', '#84A1C6'],
          inverseColors: true,
          opacityFrom: 0.3,
          opacityTo: 0.8,
        },
      },
      chart: {
        fontFamily: 'Roboto, sans-serif',
        type: 'area',
        height: '200',
        toolbar: {
          show: true,
          tools: {
            pan: true,
          },
          autoSelected: 'pan',
          export: {
            csv: {
              dateFormatter(timestamp) {
                return moment(timestamp).format('YYYY-MM-DD HH:mm:ss a');
              },
            },
          },
        },
        events: {
          beforeZoom: (e, { xaxis }) => {
            let maindifference =
              new Date(data[0].noxXY[data[0].noxXY.length - 1].x).valueOf() -
              new Date(data[0].noxXY[0].x).valueOf();
            let zoomdifference = xaxis.max - xaxis.min;

            if (zoomdifference > maindifference)
              return {
                // dont zoom out any further
                xaxis: {
                  min: data[0].noxXY[0].x,
                  max: data[0].noxXY[data[0].noxXY.length - 1].x,
                },
              };
            else {
              return {
                // keep on zooming
                xaxis: {
                  min: xaxis.min,
                  max: xaxis.max,
                },
              };
            }
          },
        },
      },
      grid: {
        padding: {
          bottom: 10,
        },

        yaxis: {
          lines: {
            show: true,
            offsetX: 6,
          },
        },
      },

      series: this.seriesUpTime,
      stroke: {
        width: 1,
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      xaxis: {
        type: 'datetime',
        tickAmount: 5,

        labels: {
          rotateAlways: true,
          rotate: 360,
          offsetY: 10,
          datetimeUTC: false,
          formatter: function (value, timestamp: number) {
            return DateUtils.formatDateFromLocalEpoch(timestamp, 'DD/MM');
          },
          style: {
            colors: '#94989E',
            fontSize: '10',
          },
        },
        title: {
          offsetY: -10,
          text: 'DD/MM',
          style: {
            color: '#FFFFFF',
            fontSize: '10',
            fontWeight: '500',
          },
        },
      },
      yaxis: {
        title: {
          text: 'Uptime %',
          style: {
            color: '#FFFFFF',
            fontSize: '10',
            fontWeight: '500',
          },
        },
        labels: {
          style: {
            colors: '#94989E',
            fontSize: '10',
            fontWeight: '500',
          },
        },
      },
      tooltip: {
        x: { show: false },
        y: {
          title: {
            formatter: () => ``,
          },
          formatter: (value: number) => `${value} kW`,
        },
      },
    };
  }

  plotPerformanceChart() {
    this.chartOptionNonAxis = {
      series: this.seriesPerformance,
      chart: {
        height: 200,
        width: '100%',
        type: 'radialBar',
        background: 'transparent',
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          hollow: {
            margin: 0,
            size: '70%',
          },
          track: {
            background: '#374151',
            strokeWidth: '100%',
            margin: 0,
          },
          dataLabels: {
            show: false,
          },
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: ['#4ade80'],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
        },
      },
      stroke: {
        lineCap: 'round',
      },
      labels: ['Performance'],
    };
  }

  onAnalyticalTabChange(index: number) {
    this.activeAnalyticalTab = index;
  }
  getAnalyticalTabClass(index: number): string {
    return this.activeAnalyticalTab === index
      ? 'text-blue-500'
      : 'text-gray-400 hover:text-gray-300';
  }

  plotDownTimeLineChart(data: NoxChartDta[]) {
    this.series = data.map((item) => {
      return {
        name: item.name,
        data: item.noxXY.map((item) => {
          return {
            x: item.x,
            y: item.y.toFixed(3),
          };
        }),
      };
    });
    /**
     * Line chart
     */

    this.chartOptions = {
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'vertical',
          shadeIntensity: 0.5,
          gradientToColors: ['#0051BA', '#2774D8'],
          inverseColors: true,
          opacityFrom: 0.3,
          opacityTo: 0.8,
        },
      },
      chart: {
        fontFamily: 'Roboto, sans-serif',
        type: 'area',
        height: '200px',
        width: '100%',
        toolbar: {
          show: true,
          tools: {
            pan: true,
          },
          autoSelected: 'pan',
          export: {
            csv: {
              dateFormatter(timestamp) {
                return moment(timestamp).format('YYYY-MM-DD HH:mm:ss a');
              },
            },
          },
        },
        events: {
          beforeZoom: (e, { xaxis }) => {
            let maindifference =
              new Date(data[0].noxXY[data[0].noxXY.length - 1].x).valueOf() -
              new Date(data[0].noxXY[0].x).valueOf();
            let zoomdifference = xaxis.max - xaxis.min;

            if (zoomdifference > maindifference)
              return {
                // dont zoom out any further
                xaxis: {
                  min: data[0].noxXY[0].x,
                  max: data[0].noxXY[data[0].noxXY.length - 1].x,
                },
              };
            else {
              return {
                // keep on zooming
                xaxis: {
                  min: xaxis.min,
                  max: xaxis.max,
                },
              };
            }
          },
        },
      },
      grid: {
        padding: {
          bottom: 10,
        },

        yaxis: {
          lines: {
            show: true,
            offsetX: 6,
          },
        },
      },

      series: this.series,
      stroke: {
        width: 1,
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      xaxis: {
        type: 'datetime',
        tickAmount: 5,

        labels: {
          datetimeUTC: false,
          rotate: 360,
          rotateAlways: true,
          offsetY: 10,
          formatter: function (value, timestamp: number) {
            return DateUtils.formatDateFromLocalEpoch(timestamp, 'DD/MM');
          },

          style: {
            colors: '#94989E',
            fontSize: '10',
          },
        },
        title: {
          offsetY: -10,
          text: 'DD/MM',
          style: {
            color: '#FFFFFF',
            fontSize: '10',
            fontWeight: '500',
          },
        },
      },
      yaxis: {
        title: {
          text: 'Downtime %',
          style: {
            color: '#FFFFFF',
            fontSize: '10',
            fontWeight: '500',
          },
        },
        labels: {
          style: {
            colors: '#94989E',
            fontSize: '10',
            fontWeight: '500',
          },
        },
      },
      tooltip: {
        enabled: true,
        x: { show: false },
        y: {
          title: {
            formatter: () => ``,
          },
          formatter: (value: number) => `${value} kW`,
        },
      },
    };
  }

  plotSystemDownTimeChart() {
    let noxChartData: NoxChartDta[] = [];
    let noxChartXY: NoxChartXY[] = [];

    this.systemDowntimeData.forEach((item) => {
      noxChartXY.push({
        x: item.date,
        y: item.value ? item.value : 0,
      });
    });

    noxChartData.push({
      name: "Today's Power Cut",
      noxXY: noxChartXY,
    });
    this.plotDownTimeLineChart(noxChartData);
  }
  plotPeakSystemHoursChart() {
    let noxUpTimeChartData: NoxChartDta[] = [];
    let noxUpTimeChartXY: NoxChartXY[] = [];
    this.peakSystemHoursData.forEach((item) => {
      noxUpTimeChartXY.push({
        x: item.date,
        y: item.value ? item.value : 0,
      });
    });

    noxUpTimeChartData.push({
      name: "Today's Maximum Consumption",
      noxXY: noxUpTimeChartXY,
    });

    this.plotCurtailmentLineChart(noxUpTimeChartData);
  }
  plotGridUpTimeChart() {
    let noxUpTimeChartData: NoxChartDta[] = [];
    let noxUpTImeChartXY: NoxChartXY[] = [];
    this.gridUpTimeData.forEach((item) => {
      noxUpTImeChartXY.push({
        x: item.date,
        y: item.value ? item.value : 0,
      });
    });

    noxUpTimeChartData.push({
      name: "Today's Grid Uptime",
      noxXY: noxUpTImeChartXY,
    });

    this.plotUpTimeLineChart(noxUpTimeChartData);
  }

  getGraphAnalytics() {
    let loaderSubject = new Subject<boolean>();
    let deviceId = localStorage.getItem('deviceId') ?? '';
    this.customerService.getGraphData(deviceId).subscribe({
      next: (response) => {},
    });
    this.customerService.getGraphData(deviceId).subscribe({
      next: (graphdata: GraphData) => {
        this.graphDataValues = graphdata;

        loaderSubject.next(true);
        let sortkeyvalue = Object.entries(this.graphDataValues.data).sort();

        let graphxaxis = sortkeyvalue.map((item) => {
          return item[0];
        });
        this.graphdate = graphxaxis.map((item) => {
          return Date.parse(item);
        });
        for (let item of sortkeyvalue) {
          if (item && item[1]) {
            this.arraysystemDowntime.push(item[1].systemDowntime ?? 0);
            this.arraypeakSystemHours.push(item[1].peakSystemhours ?? 0);
            this.arraygridUptime.push(item[1].gridUptime ?? 0);
          }
        }
        this.systemDowntimeData = this.initializevalues(
          this.arraysystemDowntime,
        );
        this.plotSystemDownTimeChart();
        this.peakSystemHoursData = this.initializevalues(
          this.arraypeakSystemHours,
        );
        this.plotPeakSystemHoursChart();
        this.gridUpTimeData = this.initializevalues(this.arraygridUptime);
        this.plotGridUpTimeChart();
      },
      error: (error: Error) => {},
      complete: () => {
        loaderSubject.complete();
      },
    });
    return loaderSubject;
  }
  initializevalues(grapharray: Array<number>) {
    const res = [];
    for (let i = 0; i < grapharray.length; i++) {
      res.push({
        value: grapharray[i] ?? grapharray[i] | 0,
        date: this.graphdate[i],
      });
    }
    return res;
  }
}
