
<div class="shadow-none">
  <div class="shadow-none">
    <div class="flex items-center justify-between p-3">
      <div class="flex items-center space-x-4">
        @for (item of subHeaderMenuItem(); track $index) {
          <div
            role="button"
            [routerLink]="item.route"
            routerLinkActive="selected-menu-item"
            #rla="routerLinkActive"
            class="ps-3 cursor-pointer"
          >
            @if (item.icon) {
              <div class="flex relative flex-col items-center">
                <img
                  src="assets/svg/{{ item.icon }}.svg"
                  alt="icon"
                  class="opacity-50"
                />
                <div class="indicator mt-[-5px]"></div>
              </div>
            }
            @if (item.label) {
              <div class="flex flex-col items-center">
                <span class="label-sm ml-0 font-normal text-[#89898B]">{{
                  item.label | translate
                }}</span>
                <div class="indicator w-[33px]"></div>
              </div>
            }
          </div>
        }
      </div>

      <div class="flex-grow"></div>
      <div>
        <span class="text-xs dark:text-[#898a8c] gradient-text"
          >System Integrator ID : {{ user.installer_id }}</span
        >

        <span class="text-xs text-[#898a8c] px-1 gradient-text">
          | &nbsp;Last Updated:
          {{ date | date: "dd/MM/yyyy hh:mm:ss a" }}
        </span>
      </div>

      <div class="hidden flex items-center space-x-4">
        <div class="flex items-center space-x-3">
          <span class="ps-3 text-white">{{ "map_view" | translate }}</span>
          <img src="assets/svg/map.svg" alt="icon" />
        </div>
        <div class="flex items-center space-x-3">
          <span class="ps-3 text-white">{{ "solar" | translate }}</span>
          <img src="assets/svg/filter.svg" alt="icon" />
        </div>
      </div>
    </div>
  </div>
</div>
