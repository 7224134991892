import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class BaseUtilService {
  constructor() {}

  unixToDate(val: number): string {
    let date = moment.unix(val);
    return date.format('DD/MM/YYYY hh:mm A');
  }

  getSpecificKeys(args: Array<string>, o: Object) {
    let x: object = {};
    args.forEach((i) => {
      x[i as keyof object] = o[i as keyof object];
    });
    return x;
  }
}
