<div
  class="side-sheet w-4/5 z-30 fixed left-[-100%] bg-[#35333B] rounded-lg"
  [class.open]="isOpen"
>
  <div class="w-full">
    <div class="p-5">
      <!-- Your side sheet content goes here -->
      <div class="flex flex-row justify-start items-center space-x-2">
        <fa-icon
          [icon]="faClose"
          class="p-2 text-white cursor-default"
          (click)="toggle()"
        ></fa-icon>
        <span class="text-white text-[15px]">Weather Forecast</span>
      </div>
      <div class="overflow-x-auto whitespace-nowrap py-2">
        <div
          *ngFor="let item of weatherData"
          class="inline-block w-32 justify-center items-center mt-2"
        >
          <div class="flex flex-col space-y-2 mt-4">
            <span class="text-white">{{
              item.dt_txt | date: "EEE,dd HH:mm"
            }}</span>
            @if (item != undefined && item.iconUrl != null) {
              <div class="flex flex-row items-center justify-center my-5">
                <img [src]="item!.iconUrl" alt="Weather" class="w-10 h-10" />
              </div>
            }
            <div class="flex flex-row space-x-4">
              <span class="text-white text-xs">Max</span
              ><span class="text-white text-sm"
                >{{ item.main.temp_max }} °C</span
              >
            </div>
            <div class="flex flex-row space-x-4">
              <span class="text-white text-xs">Min</span
              ><span class="text-white text-sm"
                >{{ item.main.temp_max }} °C</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Overlay -->
<div *ngIf="isOpen" class="overlay" (click)="toggle()"></div>
