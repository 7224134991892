import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
  inject,
  input,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  ApexAxisChartSeries,
  ApexXAxis,
  NgApexchartsModule,
} from 'ng-apexcharts';
import { NoxDatePickerComponent } from '../nox-date-picker/nox-date-picker.component';
import {
  NoxDropdown,
  TrendDropdownKey,
  getTrendsDropDown,
} from '../../models/dropdown';
import { ChartOptions, NoxChartDta, NoxChartXY } from '../../models/nox-chart';
import { TrendSelection, TrendType } from '../../models/enum';
import { DateUtils, NoxMonth, NoxYear } from '../../utils/date_utils';
import { ProductService } from '../../service/product.service';
import { ClickOutsideDirective } from '../../utils/click.outside.directive';
import { BaseComponent } from '../../../base/components/base.component';
import { Subject, takeUntil } from 'rxjs';
import {
  INVERTER_TYPE,
  PLANT_TYPE,
  USER_KEY,
} from '../../service/storage.service';
import {
  InverterType,
  MappingProtocol,
  PlantTypeEnum,
} from '../../models/stats';
import moment from 'moment';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-trends',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    TranslateModule,
    NgApexchartsModule,
    NoxDatePickerComponent,
    ClickOutsideDirective,
    NgxSpinnerModule,
  ],
  templateUrl: './trends.component.html',
  styleUrl: './trends.component.scss',
})
export class TrendsComponent
  extends BaseComponent
  implements OnDestroy, OnInit
{
  enableNextDate(selectedDate: Date, todayDate: Date) {
    let nextDay = new Date(selectedDate);
    nextDay.setDate(selectedDate.getDate() + 1);
    return nextDay < todayDate;
  }

  enableTrendDropdown = false;
  trendDropdown: NoxDropdown[] = getTrendsDropDown();
  selectedTrend: NoxDropdown = this.trendDropdown[0];
  series: ApexAxisChartSeries = [];
  chartOptions: Partial<ChartOptions> = {};

  trendSelection = TrendSelection.DAY;
  trendType = TrendType.POWER_GENERATION;
  noxMonthsData = NoxMonth.noxMonthsData();

  prodService = inject(ProductService);
  dropdownVisible = false;
  yearDropdownVisible = false;
  notificationDropdownVisible = false;
  yearList = NoxYear.yearList();
  selectedDate = new Date();
  todayDate = new Date();

  noxChartData: NoxChartDta[] = [];
  noxChartXY: NoxChartXY[] = [];
  ngUnsubscribe: Subject<void> = new Subject<void>();
  spinnerTag: string = 'trendsSpinner';
  @Input() plantId?: string;
  @Input() plantType?: string;
  @Input() mappingProtocol?: MappingProtocol;
  siId: string;
  categoryId = input<string>();
  trendSpinner: NgxSpinnerService = inject(NgxSpinnerService);
  spinnerShowing: boolean = false;
  constructor() {
    super();
    let user = this.storageService.getObject(USER_KEY);
    this.siId = user.installer_id;
  }

  ngOnInit(): void {
    /**
     * Default fetch power generation data for today
     */

    if (this.categoryId()) {
      let todayTimeStamp = DateUtils.getStartDayTimeStampFromDate(
        this.selectedDate,
      );
      this.trendDropdown = getTrendsDropDown(this.categoryId());
      this.selectedTrend = this.trendDropdown[0];

      this.trendSelection = TrendSelection.DAY;
      if (this.categoryId() == '2') {
        this.trendType = TrendType.POWER_CONSUMPTION;
        this.fetchPowerConsumptionTrends(
          this.siId, //'75d54eea-235b-4880-954e-ea235b8880e2',
          todayTimeStamp, //1706725800,
          null,
          null,
          null,
          this.categoryId(),
        );
      } else {
        this.trendType = TrendType.POWER_GENERATION;
        this.fetchPowerGenerationTrends(
          this.siId, //'75d54eea-235b-4880-954e-ea235b8880e2',
          todayTimeStamp, //1706725800,
          null,
          null,
          null,
          this.categoryId(),
        );
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['plantId'] &&
      changes['plantId'].currentValue &&
      changes['plantId'].currentValue.toString().length > 0
    ) {
      let plantType = this.storageService.get(PLANT_TYPE);

      let inverterType = this.storageService.get(INVERTER_TYPE);
      this.selectedDate = new Date();
      this.todayDate = new Date();
      let todayTimeStamp = DateUtils.getStartDayTimeStampFromDate(
        this.selectedDate,
      );
      this.trendSelection = TrendSelection.DAY;

      if (plantType == PlantTypeEnum.NON_SOLAR) {
        this.trendDropdown = getTrendsDropDown('2', this.mappingProtocol);
        this.selectedTrend = this.trendDropdown[0];
        if (this.selectedTrend.key === TrendDropdownKey.powerCut) {
          this.trendType = TrendType.POWER_CUT;
          this.fetchPowerCutTrends(
            this.plantId!, //'75d54eea-235b-4880-954e-ea235b8880e2',
            todayTimeStamp, //1706725800,
            null,
            null,
            null,
          );
        } else {
          this.trendType = TrendType.POWER_CONSUMPTION;
          this.fetchPowerConsumptionTrends(
            this.plantId!, //'75d54eea-235b-4880-954e-ea235b8880e2',
            todayTimeStamp, //1706725800,
            null,
            null,
            null,
          );
        }
      } else {
        this.selectedDate = new Date();
        this.todayDate = new Date();
        this.trendDropdown = getTrendsDropDown('1', this.mappingProtocol);
        this.selectedTrend = this.trendDropdown[0];
        this.trendType = TrendType.POWER_GENERATION;
        this.fetchPowerGenerationTrends(
          this.plantId!, //'75d54eea-235b-4880-954e-ea235b8880e2',
          todayTimeStamp, //1706725800,
          null,
          null,
          null,
        );
        // todo here
        if (inverterType === InverterType.GTI) {
          this.trendDropdown = this.trendDropdown.filter(
            (item: NoxDropdown) => item.key !== TrendDropdownKey.consumption,
          );
        }
      }
    }
  }

  /**
   *
   * Update trends base on date,month and year
   * @param date
   * @param month
   * @param year
   */
  updateTrends(date: Date | null, month: number | null, year: number | null) {
    let startDate = null;
    let todayTimeStamp = null;
    let endDate = null;

    if (date != null) {
      this.trendSelection = TrendSelection.DAY;
      todayTimeStamp = DateUtils.getStartDayTimeStampFromDate(date);
    } else if (year != null) {
      this.trendSelection = TrendSelection.YEARLY;

      let firstDayOfMonth = DateUtils.getFirstDay(year);
      let lastDayOfMonth = DateUtils.getLastDay(year);

      startDate = DateUtils.getStartDayTimeStampFromDate(firstDayOfMonth);
      endDate = DateUtils.getStartDayTimeStampFromDate(lastDayOfMonth);
    } else if (month != null) {
      this.trendSelection = TrendSelection.MONTHLY;

      let firstDayOfMonth = DateUtils.getFirstDay(
        this.selectedDate.getFullYear(),
        month,
      );
      let lastDayOfMonth = DateUtils.getLastDay(
        this.selectedDate.getFullYear(),
        month,
      );

      startDate = DateUtils.getStartDayTimeStampFromDate(firstDayOfMonth);
      endDate = DateUtils.getStartDayTimeStampFromDate(lastDayOfMonth);
    }

    if (this.plantId || this.siId) {
      this.trendSpinner.show(this.spinnerTag);
      this.spinnerShowing = true;

      switch (this.trendType) {
        case TrendType.POWER_GENERATION:
          this.fetchPowerGenerationTrends(
            this.plantId ?? this.siId, // '75d54eea-235b-4880-954e-ea235b8880e2',
            todayTimeStamp,
            startDate,
            endDate,
            year,
            this.categoryId(),
          );
          break;
        case TrendType.POWER_CONSUMPTION:
          this.fetchPowerConsumptionTrends(
            this.plantId ?? this.siId, //'75d54eea-235b-4880-954e-ea235b8880e2',
            todayTimeStamp,
            startDate,
            endDate,
            year,
            this.categoryId(),
          );
          break;
        case TrendType.POWER_CUT:
          this.fetchPowerCutTrends(
            this.plantId ?? this.siId, // '75d54eea-235b-4880-954e-ea235b8880e2',
            todayTimeStamp,
            startDate,
            endDate,
            year,
            this.categoryId(),
          );
          break;
      }
    }
  }

  /**
   * switch trend between generation,consumption and power cut
   * @param noxDropdown
   */
  switchTrends(noxDropdown: NoxDropdown) {
    this.trendSelection = TrendSelection.DAY;
    this.selectedDate = new Date();
    this.selectedTrend = noxDropdown;
    let todayTimeStamp = DateUtils.getStartDayTimeStampFromDate(
      this.selectedDate,
    );
    if (this.plantId || this.siId) {
      switch (noxDropdown.key) {
        case TrendDropdownKey.consumption:
          this.trendType = TrendType.POWER_CONSUMPTION;
          this.fetchPowerConsumptionTrends(
            this.plantId ?? this.siId, //'d52bd09b-e0e6-4241-abd0-9be0e64241b0',
            todayTimeStamp,
            null,
            null,
            null,
            this.categoryId(),
          );

          break;
        case TrendDropdownKey.powerCut:
          this.trendType = TrendType.POWER_CUT;
          this.fetchPowerCutTrends(
            this.plantId ?? this.siId, //'d52bd09b-e0e6-4241-abd0-9be0e64241b0',
            todayTimeStamp,
            null,
            null,
            null,
            this.categoryId(),
          );
          break;
        case TrendDropdownKey.generation:
          this.trendType = TrendType.POWER_GENERATION;
          this.fetchPowerGenerationTrends(
            this.plantId ?? this.siId, //'75d54eea-235b-4880-954e-ea235b8880e2',
            todayTimeStamp,
            null,
            null,
            null,
            this.categoryId(),
          );
          break;
      }
    }
  }
  fetchPowerGenerationTrends(
    id: string,
    date: number | null,
    startDate: number | null,
    endDate: number | null,
    year: number | null,
    type?: string | undefined,
  ) {
    let getPowerGenerationTrends = new Subject<boolean>();
    this.clearGraphData();
    this.prodService
      .getPowerGenerationTrends(id, date, startDate, endDate, year, type)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (trendsResponse) => {
          this.clearGraphData();
          if (trendsResponse) {
            this.noxChartXY = [];
            this.noxChartData = [];
            let powerGenerationData = trendsResponse.data;
            if (Object.keys(powerGenerationData).length > 0) {
              switch (this.trendSelection) {
                case TrendSelection.DAY:
                  if (
                    powerGenerationData.instantiateGeneration &&
                    powerGenerationData.instantiateGeneration.length > 0
                  ) {
                    powerGenerationData.instantiateGeneration.sort(
                      (first, second) => first.time - second.time,
                    );
                    powerGenerationData.instantiateGeneration.forEach(
                      (item) => {
                        this.noxChartXY.push({
                          x: item.time * 1000, // convert to milliseconds
                          y: Number(item.generation || 0),
                        });
                      },
                    );
                    this.noxChartData.push({
                      name: "Today's  Power Generation",
                      noxXY: this.noxChartXY,
                    });
                    this.plotLineChart(this.noxChartData);
                  }

                  break;
                case TrendSelection.CUSTOM:

                case TrendSelection.MONTHLY:
                  if (
                    powerGenerationData.generations &&
                    powerGenerationData.generations.length > 0
                  ) {
                    powerGenerationData.generations.sort(
                      (first, second) => first.date - second.date,
                    );
                    powerGenerationData.generations.forEach((item) => {
                      this.noxChartXY.push({
                        x: item.date * 1000, // convert to milliseconds
                        y: Number(Number(item.generation).toFixed(3)),
                      });
                    });
                    this.noxChartData.push({
                      name: 'Monthly Power Generation',
                      noxXY: this.noxChartXY,
                    });
                    this.plotBarChart(this.noxChartData);
                  }

                  break;
                case TrendSelection.YEARLY:
                  if (
                    powerGenerationData.generations &&
                    powerGenerationData.generations.length > 0
                  ) {
                    powerGenerationData.generations.sort(
                      (first, second) => first.date - second.date,
                    );
                    powerGenerationData.generations.forEach((item) => {
                      if (item.month) {
                        this.noxChartXY.push({
                          x: item.month, // convert to milliseconds
                          y: Number(item.generation),
                        });
                      }
                    });
                    this.noxChartData.push({
                      name: 'Yearly Power Generation',
                      noxXY: this.noxChartXY,
                    });
                    this.plotBarChart(this.noxChartData);
                  }

                  break;
              }
            } else {
              this.logger.info('No data found');
            }
            getPowerGenerationTrends.next(true);
          }
          this.hideSpinner();
        },
        error: (err) => {
          getPowerGenerationTrends.next(true);
          this.logger.error(err);
          this.hideSpinner();
        },
        complete: () => {
          this.hideSpinner();
          this.baseUserService.trendsLoading.next(true);
          getPowerGenerationTrends.complete();
        },
      });
  }
  hideSpinner() {
    this.trendSpinner.hide(this.spinnerTag);
    this.spinnerShowing = false;
  }
  showSpinner() {
    this.trendSpinner.show(this.spinnerTag);
    this.spinnerShowing = true;
  }

  fetchPowerConsumptionTrends(
    plantId: string,
    date: number | null,
    startDate: number | null,
    endDate: number | null,
    year: number | null,
    type?: string,
  ) {
    this.clearGraphData();
    this.prodService
      .getPowerConsumptionTrends(plantId, date, startDate, endDate, year, type)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (powerConsumptionResponse) => {
          this.clearGraphData();
          let powerConsumptionData = powerConsumptionResponse?.data;
          if (
            powerConsumptionData &&
            Object.keys(powerConsumptionData).length > 0
          ) {
            switch (this.trendSelection) {
              case TrendSelection.DAY:
                if (
                  powerConsumptionData.instantiateConsumption &&
                  powerConsumptionData.instantiateConsumption.length > 0
                ) {
                  powerConsumptionData.instantiateConsumption.sort(
                    (first, second) => first.time - second.time,
                  );
                  powerConsumptionData.instantiateConsumption.forEach(
                    (item) => {
                      this.noxChartXY.push({
                        x: item.time * 1000, // convert to milliseconds
                        y: Number(Number(item.consumption).toFixed(3)),
                      });
                    },
                  );
                  this.noxChartData.push({
                    name: "Today's Power Consumption",
                    noxXY: this.noxChartXY,
                  });
                  this.plotLineChart(this.noxChartData);
                }

                break;
              case TrendSelection.CUSTOM:

              case TrendSelection.MONTHLY:
                if (
                  powerConsumptionData.consumptions &&
                  powerConsumptionData.consumptions.length > 0
                ) {
                  powerConsumptionData.consumptions.sort(
                    (first, second) => first.date - second.date,
                  );
                  powerConsumptionData.consumptions.forEach((item) => {
                    this.noxChartXY.push({
                      x: item.date * 1000, // convert to milliseconds
                      y: Number(Number(item.consumption).toFixed(3)),
                    });
                  });
                  this.noxChartData.push({
                    name: 'Monthly Power Consumption',
                    noxXY: this.noxChartXY,
                  });
                  this.plotBarChart(this.noxChartData);
                }

                break;
              case TrendSelection.YEARLY:
                if (
                  powerConsumptionData.consumptions &&
                  powerConsumptionData.consumptions.length > 0
                ) {
                  powerConsumptionData.consumptions.sort(
                    (first, second) => first.month - second.month,
                  );
                  powerConsumptionData.consumptions.forEach((item) => {
                    this.noxChartXY.push({
                      x: item.month,
                      y: Number(item.consumption),
                    });
                  });
                  this.noxChartData.push({
                    name: 'Yearly Power Consumption',
                    noxXY: this.noxChartXY,
                  });
                  this.plotBarChart(this.noxChartData);
                } else {
                  this.logger.info('No data found');
                }

                break;
            }
          } else {
            this.logger.info('No data found');
          }
          this.hideSpinner();
        },
        error: (err) => {
          this.logger.error(err);
          this.hideSpinner();
        },
        complete: () => {
          this.hideSpinner();
          this.baseUserService.trendsLoading.next(true);
        },
      });
  }
  clearGraphData() {
    this.chartOptions = {};
    this.noxChartXY = [];
    this.noxChartData = [];
  }

  fetchPowerCutTrends(
    id: string,
    date: number | null,
    startDate: number | null,
    endDate: number | null,
    year: number | null,
    type?: string,
  ) {
    this.clearGraphData();
    this.prodService
      .getPowerCutGeneration(id, date, startDate, endDate, year, type)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (powerCutResponse) => {
          this.clearGraphData();
          let powerCutData = powerCutResponse.data;

          if (powerCutData && Object.keys(powerCutData).length > 0) {
            switch (this.trendSelection) {
              case TrendSelection.DAY:
                powerCutData.sort((first, second) => first.hour - second.hour);
                powerCutData.forEach((item) => {
                  let timestamp = DateUtils.getTimestampsForHours(item.hour);
                  this.noxChartXY.push({
                    x: timestamp, // convert to milliseconds
                    y: Number(item.minutes),
                  });
                });
                this.noxChartData.push({
                  name: "Today's  Power Cut",
                  noxXY: this.noxChartXY,
                });
                this.plotBarChart(this.noxChartData);
                break;
              case TrendSelection.CUSTOM:

              case TrendSelection.MONTHLY:
                powerCutData.sort((first, second) => first.date - second.date);
                powerCutData.forEach((item) => {
                  this.noxChartXY.push({
                    x: item.date * 1000, // convert to milliseconds
                    y: Number(item.minutes),
                  });
                });
                this.noxChartData.push({
                  name: 'Monthly Power Cut',
                  noxXY: this.noxChartXY,
                });
                this.plotBarChart(this.noxChartData);
                break;
              case TrendSelection.YEARLY:
                powerCutData.sort(
                  (first, second) => first.month - second.month,
                );
                powerCutData.forEach((item) => {
                  this.noxChartXY.push({
                    x: item.month,
                    y: Number(item.hours + '.' + item.minutes),
                  });
                });
                this.noxChartData.push({
                  name: 'Yearly Power Cut',
                  noxXY: this.noxChartXY,
                });
                this.plotBarChart(this.noxChartData);
                break;
            }
          }
          this.hideSpinner();
        },
        error: (err) => {
          this.logger.error(err);
          this.hideSpinner();
        },
        complete: () => {
          this.hideSpinner();
          this.baseUserService.trendsLoading.next(true);
        },
      });
  }

  toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible;
  }
  clickOutside(selection: string) {
    if (selection === 'YEARLY') {
      this.yearDropdownVisible = false;
    } else if (selection === 'MONTHLY') {
      this.dropdownVisible = false;
    } else if (selection === 'NOTIFICATION') {
      this.notificationDropdownVisible = false;
    } else if (selection === 'TREND_TYPE') {
      this.enableTrendDropdown = false;
    }
  }
  toggleYearDropdown() {
    this.yearDropdownVisible = !this.yearDropdownVisible;
  }
  prevDate() {
    this.trendSelection = TrendSelection.DAY;
    this.selectedDate = new Date(
      this.selectedDate.setDate(this.selectedDate.getDate() - 1),
    );

    this.updateTrends(this.selectedDate, null, null);
    this.logger.info('Picked date: ', this.selectedDate);
  }
  nextDate() {
    this.trendSelection = TrendSelection.DAY;
    this.selectedDate = new Date(
      this.selectedDate.setDate(this.selectedDate.getDate() + 1),
    );
    this.updateTrends(this.selectedDate, null, null);
    this.logger.info('Picked date: ', this.selectedDate);
  }
  onDateSelected($date: Date) {
    this.trendSelection = TrendSelection.DAY;
    this.logger.info('Picked date: ', $date);
    this.selectedDate = $date;
    this.updateTrends($date, null, null);
  }

  /**
   * Plot bar chart
   * @param data
   */
  plotBarChart(data: NoxChartDta[]) {
    switch (this.trendType) {
      /**
       * power cut is handled here
       */
      case TrendType.POWER_CUT:
        this.series = data.map((item) => {
          return {
            name: item.name,
            data: item.noxXY.map((item) => {
              let xData;

              switch (this.trendSelection) {
                case TrendSelection.DAY:
                  xData = DateUtils.formatDateFromLocalEpoch(item.x, 'hh a');
                  break;
                case TrendSelection.MONTHLY:
                  xData = DateUtils.formatDateFromLocalEpoch(item.x, 'DD/MM');
                  break;
                case TrendSelection.YEARLY:
                  xData = DateUtils.getMonthName(item.x);
                  break;
                default:
                  xData = DateUtils.formatDateFromLocalEpoch(item.x, 'hh a');
                  break;
              }
              return {
                x: xData,
                y: item.y.toFixed(3),
              };
            }),
          };
        });
        break;
      /**
       * todo handle power generation and consumption here
       */
      default:
        this.series =
          this.trendSelection === TrendSelection.YEARLY
            ? data.map((item) => {
                return {
                  name: item.name,
                  data: item.noxXY.map((item) => {
                    return Number(item.y.toFixed(3));
                  }),
                };
              })
            : data.map((item) => {
                return {
                  name: item.name,
                  data: item.noxXY.map((item) => {
                    return {
                      x: item.x,
                      y: item.y.toFixed(3),
                    };
                  }),
                };
              });
        break;
    }

    /**
     * Default xAxisValue detail
     */
    let xAxisValue: ApexXAxis = {
      type: 'datetime',
      tickAmount: 8,
      min: data[0].noxXY[0].x,
      max: data[0].noxXY[data[0].noxXY.length - 1].x,
      labels: {
        datetimeUTC: false,
        formatter: function (value, timestamp: number) {
          return DateUtils.formatDateFromLocalEpoch(timestamp);
        },
        style: {
          colors: '#94989E',
          fontSize: '12 ',
          fontWeight: 500,
        },
      },

      title: {
        offsetY: 10,
        text: this.getTitle(),
        style: {
          color: '#FFFFFF',
          fontSize: '12',
          fontFamily: 'Roboto, sans-serif',
          fontWeight: 500,
        },
      },
    };

    /**
     * For power cut trend type
     */
    if (this.trendType === TrendType.POWER_CUT) {
      xAxisValue = {
        type: 'category',
        categories: data[0].noxXY.map((item) => {
          let data;
          switch (this.trendSelection) {
            case TrendSelection.DAY:
              data = DateUtils.formatDateFromLocalEpoch(item.x, 'hh a');

              break;
            case TrendSelection.MONTHLY:
              data = DateUtils.formatDateFromLocalEpoch(item.x, 'DD/MM');
              break;
            case TrendSelection.YEARLY:
              data = DateUtils.getMonthName(item.x);
              break;
            default:
              data = DateUtils.formatDateFromLocalEpoch(item.x, 'hh a');
          }
          return data;
        }),
        labels: {
          showDuplicates: false,

          style: {
            colors: '#94989E',
            fontSize: '10',
            fontWeight: '500',
          },
        },

        title: {
          text: this.getTitle(),

          style: {
            color: '#FFFFFF',
            fontSize: '12',
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 400,
          },
        },
      };
    } else {
      /**
       * For other trend type
       */
      if (
        this.trendSelection === TrendSelection.MONTHLY ||
        this.trendSelection === TrendSelection.CUSTOM
      ) {
        xAxisValue.labels = {
          datetimeUTC: false,
          format: 'dd/MM',

          style: {
            colors: '#94989E',
            fontSize: '10',
          },
        };
      } else if (this.trendSelection === TrendSelection.YEARLY) {
        xAxisValue = {
          tickAmount: 8,
          categories: data[0].noxXY.map((item) =>
            DateUtils.getMonthName(item.x),
          ),
          labels: {
            datetimeUTC: false,
            style: {
              colors: '#94989E',
              fontSize: '10',
            },
          },
          title: {
            offsetY: 10,
            text: this.getTitle(),
            style: {
              color: '#FFFFFF',
              fontSize: '12',
              fontFamily: 'Roboto, sans-serif',
              fontWeight: 400,
            },
          },
        };
      }
    }

    this.chartOptions = {
      series: this.series,
      chart: {
        type: 'bar',
        height: '96%',
        offsetY: 0,
        toolbar: {
          show: true,
          tools: {
            pan: true,
          },
          autoSelected: 'pan',
        },

        events: {
          beforeZoom: (e, { xaxis }) => {
            let maindifference =
              new Date(data[0].noxXY[data[0].noxXY.length - 1].x).valueOf() -
              new Date(data[0].noxXY[0].x).valueOf();
            let zoomdifference = xaxis.max - xaxis.min;
            if (zoomdifference > maindifference) {
              return {
                // dont zoom out any further
                xaxis: {
                  min: data[0].noxXY[0].x,
                  max: data[0].noxXY[data[0].noxXY.length - 1].x,
                },
              };
            } else {
              return {
                // keep on zooming
                xaxis: {
                  min: xaxis.min,
                  max: xaxis.max,
                },
              };
            }
          },
        },
      },
      grid: {
        padding: {
          bottom: 5,
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          colorStops: [
            {
              offset: 0,
              color: '#527563',
              opacity: 1,
            },
            {
              offset: 20,
              color: '#65aabe',
              opacity: 1,
            },
          ],
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: 20,
          borderRadius: 5,
          borderRadiusApplication: 'end',
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        title: {
          text: this.updateYAxisLabel(),
          style: {
            color: '#FFFFFF',
            fontSize: '12',
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 500,
          },
        },
        labels: {
          style: {
            colors: '#94989E',
            fontSize: '12',
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 500,
          },
        },
      },
      xaxis: xAxisValue,
      tooltip: {
        x: { show: false },
        y: {
          title: {
            formatter: () => ``,
          },
          formatter: (value: number) =>
            `${value} ${this.selectedTrend.value === 'Power Cut' ? 'mins' : 'kW'}`,
        },
      },
    };
  }
  updateYAxisLabel(): string {
    let label = 'Solar Power (kW)';

    switch (this.trendType) {
      case TrendType.POWER_GENERATION:
        if (this.trendSelection === TrendSelection.DAY)
          label = 'Solar Power (kW)';
        else if (this.trendSelection === TrendSelection.MONTHLY)
          label = 'Solar Power (kWh)';
        else label = 'Solar Power (kWh)';
        break;
      case TrendType.POWER_CONSUMPTION:
        if (this.trendSelection === TrendSelection.DAY)
          label = 'Power Consumption (kW)';
        else if (this.trendSelection === TrendSelection.MONTHLY)
          label = 'Power Consumption (kWh)';
        else label = 'Power Consumption (kWh)';
        break;
      case TrendType.POWER_CUT:
        if (this.trendSelection === TrendSelection.DAY) label = 'Time (Min)';
        else if (this.trendSelection === TrendSelection.MONTHLY)
          label = 'Time (Min)';
        else label = 'Time (Hrs)';

        break;
    }

    return label;
  }
  getTitle(): string {
    let title = '';
    switch (this.trendType) {
      case TrendType.POWER_GENERATION:
        if (this.trendSelection === TrendSelection.DAY) title = 'Time (hh:mm)';
        else if (this.trendSelection === TrendSelection.MONTHLY)
          title = 'Daily Power Generation';
        else if (this.trendSelection === TrendSelection.YEARLY)
          title = 'Monthly Power Generation';
        break;
      case TrendType.POWER_CONSUMPTION:
        if (this.trendSelection === TrendSelection.DAY) title = 'Time (hh:mm)';
        else if (this.trendSelection === TrendSelection.MONTHLY)
          title = 'Daily Power Consumption';
        else if (this.trendSelection === TrendSelection.YEARLY)
          title = 'Monthly Power Consumption';
        break;
      case TrendType.POWER_CUT:
        if (this.trendSelection === TrendSelection.DAY) title = 'Time (hh:mm)';
        else if (this.trendSelection === TrendSelection.MONTHLY)
          title = 'Daily Power Cut';
        else title = 'Monthly Power Cut';

        break;
    }
    return title;
  }
  plotLineChart(data: NoxChartDta[]) {
    this.series = data.map((item) => {
      return {
        name: item.name,
        data: item.noxXY.map((item) => {
          return {
            x: item.x,
            y: item.y.toFixed(3),
          };
        }),
      };
    });
    /**
     * Line chart
     */
    this.chartOptions = {
      chart: {
        fontFamily: 'Roboto, sans-serif',
        type: 'area',
        height: '96%',
        toolbar: {
          show: true,
          tools: {
            pan: true,
          },
          autoSelected: 'pan',
          export: {
            csv: {
              dateFormatter(timestamp) {
                return moment(timestamp).format('YYYY-MM-DD HH:mm:ss a');
              },
            },
          },
        },
        events: {
          beforeZoom: (e, { xaxis }) => {
            let maindifference =
              new Date(data[0].noxXY[data[0].noxXY.length - 1].x).valueOf() -
              new Date(data[0].noxXY[0].x).valueOf();
            let zoomdifference = xaxis.max - xaxis.min;

            if (zoomdifference > maindifference)
              return {
                // dont zoom out any further
                xaxis: {
                  min: data[0].noxXY[0].x,
                  max: data[0].noxXY[data[0].noxXY.length - 1].x,
                },
              };
            else {
              return {
                // keep on zooming
                xaxis: {
                  min: xaxis.min,
                  max: xaxis.max,
                },
              };
            }
          },
        },
      },
      grid: {
        padding: {
          bottom: 5,
        },

        yaxis: {
          lines: {
            show: true,
            offsetX: 5,
          },
        },
      },

      series: this.series,
      stroke: {
        width: 1,
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      xaxis: {
        type: 'datetime',
        tickAmount: 8,
        labels: {
          datetimeUTC: false,
          formatter: function (value, timestamp: number) {
            return DateUtils.formatTimeFromLocalEpoch(timestamp);
          },
          style: {
            colors: '#94989E',
            fontSize: '10',
          },
        },
        title: {
          offsetY: 4,
          text: 'Time (hh:mm)',
          style: {
            color: '#FFFFFF',
            fontSize: '10',
            fontWeight: '500',
          },
        },
      },
      yaxis: {
        title: {
          text: this.updateYAxisLabel(),
          style: {
            color: '#FFFFFF',
            fontSize: '10',
            fontWeight: '500',
          },
        },
        labels: {
          style: {
            colors: '#94989E',
            fontSize: '10',
            fontWeight: '500',
          },
        },
      },
      tooltip: {
        x: { show: false },
        y: {
          title: {
            formatter: () => ``,
          },
          formatter: (value: number) => `${value} kW`,
        },
      },
    };
  }
  toggleTrendDropdown() {
    this.enableTrendDropdown = !this.enableTrendDropdown;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
