<div class="w-full h-full relative">
  <google-map height="100%" width="100%" [options]="options">
    @for (location of plantDetails; track location; let index = $index) {
      <map-advanced-marker
        #markerElem="mapAdvancedMarker"
        [position]="{ lat: location.lat, lng: location.lon }"
        [content]="location.content!"
        [title]="'Location ' + (index + 1)"
        (mapClick)="onMarkerClick(markerElem)"
      />
      <map-info-window #infoWindow>
        <div class="px-2 pr-4 pb-3">
          @if (plantData?.plantPhoto) {
            <div class="flex justify-center items-center mb-2">
              <img
                [src]="
                  plantData?.plantPhoto ||
                  '../../../../assets/images/customer_screens/plant.jpeg'
                "
                alt="preview"
                class="w-28 h-20 xl:w-36 xl:h-28 rounded-lg"
              />
            </div>
          }
          <div class="grid grid-cols-3 grid-rows-2">
            <div class="col-span-2">Plant Name</div>
            <div class="col-span-1">
              {{ plantData?.plantName }}
            </div>
            <div class="col-span-2">Installation Date</div>
            <div class="col-span-1">
              {{ plantData?.installationDate | date: "dd/MM/yyyy" }}
            </div>
          </div>
        </div>
      </map-info-window>
    }
  </google-map>
  <div class="absolute top-2 left-2">
    <div class="space-x-2 cursor-pointer" (click)="baseUserService.back()">
      <img
        src="../../../../assets/svg/arrow_back.svg"
        class="rotate-180"
        alt="Back"
      />
      <span class="text-gray-100 gradient-text">{{ "back" | translate }}</span>
    </div>
  </div>
</div>
