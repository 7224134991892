<nav class="w-full">
  <div class="flex dark:bg-dimGrey justify-between items-center p-1 text-white">
    <div class="flex justify-between items-center p-1 flex-wrap">
      @if (user) {
        <img
          [src]="'../../../../assets/svg/customer-logo.svg'"
          class="mr-3"
          alt=""
        />
      }
    </div>
    <div class="flex justify-around items-center flex-wrap">
      <div class="flexBetween mr-3 flex-wrap">
        <div class="flexBetween p-1"></div>
      </div>
      <div class="flexCenter space-x-2">
        <div class="flexCenter">
          @if (this.user.userCategory == userCategory.PREMIUM ){
            <div>
              <img
                class="imgs"
                src="../../../../assets/images/Group 48095330.svg"
                alt="Phone"
              />
            </div>
          }
      
          <img
            [src]="
              user.user_image
                ? user.user_image
                : '../../../../assets/svg/default-user.svg'
            "
            alt="Avator"
            routerLink="user-profile"
            class="h-7 w-7 rounded-full"
          />
        </div>

        <div
          class="relative flex cursor-default gap-x-1"
          (click)="toggleDropdown()"
          onClickOutside
          (onClickOutside)="clickOutside()"
        >
          <div class="flex flex-col leading-normal">
            <div class="flexCenter">
              @if (user) {
                <span class="text-sm">{{
                  userInfo ? userInfo.name : user.name
                }}</span>
              }
            </div>
          </div>
          <fa-icon
            [icon]="faAngleDown"
            class="mr-3 text-sm"
            [ngClass]="{
              'rounded-xl': !dropdownOpen,
              'rounded-t-xl': dropdownOpen
            }"
          ></fa-icon>
          <div
            class="absolute right-0 top-8 z-20 w-[140px] bg-dimGrey shadow-lg focus:outline-none rounded-b-xl"
            *ngIf="dropdownOpen"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabindex="-1"
          >
            <div class="py-1" role="none">
              <span
                class="text-gray-700 dark:text-white block px-4 py-2 text-xs hover:dark:bg-gray-500 hover:bg-slate-50 hover:bg-opacity-80 rounded-lg cursor-default"
                role="menuitem"
                tabindex="-1"
                routerLink="user-profile"
                >User Info</span
              >
              <span
                class="text-gray-700 dark:text-white block px-4 py-2 text-xs hover:dark:bg-gray-500 hover:bg-slate-50 rounded-lg cursor-default"
                role="menuitem"
                tabindex="-1"
                (click)="logout()"
                >Logout</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
