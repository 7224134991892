export interface ReportInterval {
  key: ReportIntervalEnum;
  value: string;
}
export enum ReportIntervalEnum {
  last_seven = 'last_seven',
  last_month = 'last_month',
  last_three_month = 'last_three_month',
  last_six_month = 'last_six_month',
  this_year = 'this_year',
}
