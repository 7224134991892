<div
  class="w-full h-full items-center flex flex-row justify-center space-x-[1%]"
>
  @for (item of headerData; track $index) {
    <div
      class="w-[20%] h-[80%] dark:bg-btTh flex justify-between items-center rounded-full text-white xl:px-8 lg:px-6 px-4"
    >
      <div class="flex items-center">
        <div>
          @if (item.overlayIcon) {
            <div class="outer-container">
              <img
                src="{{ item.icon }}"
                alt=""
                class="outer-image lg:h-9 lg:w-9 h-8 w-8"
              />
              <div class="inner-container">
                <img
                  src="{{ item.overlayIcon }}"
                  alt="Icon"
                  class="inner-image lg:h-6 lg:w-6 h-5 w-5"
                />
              </div>
              <div class="inner-text">
                <span class="lg:text-[8px] md:text-[6px] text-[5px] mr-1">{{
                  item.overlayString
                }}</span>
              </div>
            </div>
          } @else {
            <img src="{{ item.icon }}" alt="" class="lg:h-9 lg:w-9 h-8 w-8" />
          }
        </div>
        <div class="lg:ml-4 md:ml-3 ml-2">
          <span class="label-sm text-[#94989E]">{{ item.description }}</span>
        </div>
      </div>
      <div>
        <span class="value-medium">{{ item.value }}</span>
      </div>
    </div>
  }
</div>
