<div class="flex flex-col justify-between w-full h-full">
  <div class="flex justify-start items-center pt-3 pl-3">
    <div>
      <span class="gradient-header">
        {{ "powercuts_and_backup_summary" | translate }}</span
      >
      <img
        src="../../../../assets/images/infoicon.svg "
        class="hidden h-[15px] ml-2 -mb-0.5"
        alt="Info"
      />
    </div>
  </div>

  <div class="flex flex-col justify-center flex-grow m-2">
    <div class="grid grid-cols-2 grid-rows-2 text-white gap-x-2 flex-grow">
      <div class="col-span-1 p-1 flex flex-row justify-between items-center">
        <div class="flexDirCol items-start">
          <div class="flex items-start">
            <span class="value-small text-left">{{
              powercutDetailsandSummary?.backupDuration ?? "NA"
            }}</span>
          </div>
          <div class="mt-1 text-start w-[50%]">
            <span class="label-sm text-dark-gray-content">{{
              "backup_duration" | translate
            }}</span>
          </div>
        </div>
        <div class="mx-2">
          <img
            class="icon-lg"
            src="assets/svg/back-duration.svg"
            alt="Duration"
          />
        </div>
      </div>
      <div class="col-span-1 p-1 flex flex-row justify-between items-center">
        <div class="flexDirCol items-start">
          <div class="flex items-start">
            <span class="value-small text-start">{{
              powercutDetailsandSummary?.chargingDuration ?? "NA"
            }}</span>
          </div>
          <div class="mt-1 text-start w-[50%]">
            <span class="label-sm text-dark-gray-content">{{
              "charging_duration" | translate
            }}</span>
          </div>
        </div>
        <div class="mx-2">
          <img class="icon-lg" src="assets/svg/charging-duration.svg" />
        </div>
      </div>
      <div class="col-span-1 p-1 flex flex-row justify-between items-center">
        <div class="flexDirCol items-start">
          <div class="flex items-start">
            <span class="value-small text-start ml-0">{{
              powercutDetailsandSummary?.totalPowerCuts ?? "NA"
            }}</span>
          </div>
          <div class="mt-1 text-start w-[65%]">
            <span class="label-sm text-dark-gray-content">
              {{ "total_powercuts" | translate }}</span
            >
          </div>
        </div>
        <div class="mx-2">
          <img class="icon-lg" src="assets/svg/power-cut.svg" />
        </div>
      </div>
      <div class="col-span-1 p-1 flex flex-row justify-between items-center">
        <div class="flexDirCol items-start">
          <div class="flex items-start">
            <span class="value-small text-start">{{
              powercutDetailsandSummary?.totalPowerCutDuration ?? "NA"
            }}</span>
          </div>
          <div class="mt-1 text-start w-[65%]">
            <span class="label-sm text-dark-gray-content">{{
              "powercut_duration" | translate
            }}</span>
          </div>
        </div>
        <div class="mx-2">
          <img class="icon-lg" src="assets/svg/power-cut-duration.svg" />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="hidden flexDirCol">
  <div class="flex items-center ml-2">
    <div class="gradient-header power-cut-details-title">
      {{ "powercuts_and_backup_summary" | translate }}
    </div>
    <div class="ml-1.5 mt-1.5">
      <img
        src="../../../../assets/images/infoicon.svg"
        class="hidden h-[15px] mt-1"
      />
    </div>
  </div>
  <div class="flex justify-around">
    <!-- 1 -->
    <div class="power-cut-details-parent p-1">
      <div class="flexDirCol">
        <div>
          <span
            class="power-cut-details-value md:text-xs mon_sm:text-xs mon_md:text-lg mon_lg:text-lg w-[50%]"
            >{{ powercutDetailsandSummary?.backupDuration ?? "NA" }}</span
          >
        </div>
        <div class="mt-1">
          <span class="power-cut-details-description">{{
            "backup_duration" | translate
          }}</span>
        </div>
      </div>
      <div class="ml-4">
        <img
          class="imges power-cut-images w-[100%]"
          src="assets/images/TCP_BS/BKD.png"
        />
      </div>
    </div>
    <!-- 2 -->

    <div class="power-cut-details-parent p-1">
      <div class="flexDirCol">
        <div>
          <span
            class="power-cut-details-value md:text-xs mon_sm:text-xs mon_md:text-lg mon_lg:text-lg"
            >{{ powercutDetailsandSummary?.chargingDuration ?? "NA" }}</span
          >
        </div>
        <div class="mt-1">
          <span class="power-cut-details-description">{{
            "charging_duration" | translate
          }}</span>
        </div>
      </div>
      <div class="ml-4">
        <img
          class="imges power-cut-images w-[100%]"
          src="assets/images/TCP_BS/TPC.png"
        />
      </div>
    </div>

    <!-- 3 -->
    <div class="power-cut-details-parent p-1">
      <div class="flexDirCol">
        <div>
          <span
            class="power-cut-details-value md:text-xs mon_sm:text-xs mon_md:text-lg mon_lg:text-lg"
            >{{ powercutDetailsandSummary?.totalPowerCuts ?? "NA" }}</span
          >
        </div>
        <div class="mt-1 w-[65%]">
          <span class="power-cut-details-description">
            {{ "total_powercuts" | translate }}</span
          >
        </div>
      </div>
      <div class="ml-4">
        <img
          class="imges power-cut-images w-[100%]"
          src="assets/images/TCP_BS/BL.png"
        />
      </div>
    </div>
    <div class="power-cut-details-parent p-1">
      <div class="flexDirCol">
        <div>
          <span
            class="power-cut-details-value md:text-xs mon_sm:text-xs mon_md:text-lg mon_lg:text-lg"
            >{{
              powercutDetailsandSummary?.totalPowerCutDuration ?? "NA"
            }}</span
          >
        </div>
        <div class="mt-1">
          <span class="power-cut-details-description">{{
            "powercut_duration" | translate
          }}</span>
        </div>
      </div>
      <div class="ml-4">
        <img
          class="imges power-cut-images w-[100%]"
          src="assets/images/TCP_BS/PCD.png"
        />
      </div>
    </div>
  </div>
</div>
