import { Component, OnDestroy, OnInit, inject, input } from '@angular/core';
import {
  TodayPowerCutDetails,
  TodayPowerCutDetailsData,
} from '../../models/today-power-cut-details';
import { BaseComponent } from '../../../base/components/base.component';
import { Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { SiService } from '../../../system-integrator/services/si.service';
import { AuthService } from '../../service/auth.service';
import { User } from '../../models/user';
import { USER_KEY } from '../../service/storage.service';

@Component({
  selector: 'app-powercut-and-summary',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './powercut-and-summary.component.html',
  styleUrl: './powercut-and-summary.component.scss',
})
export class PowercutAndSummaryComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  powercutDetailsandSummary?: TodayPowerCutDetailsData = {
    backupDuration: '68 hrs',
    chargingDuration: '250 hrs',
    totalPowerCuts: 0,
    totalPowerCutDuration: '0 hours 0 minutes',
    totalBackupCapacity: null,
    currentBackupCapacity: null,
  };

  todayPowerCutDetailsSubscrition!: Subscription;
  siService: SiService = inject(SiService);
  categoryId = input.required<string>();
  installer_id!: string;

  ngOnInit(): void {
    let user: User = this.storageService.getObject(USER_KEY);
    this.installer_id = user.installer_id;
    this.getPowerCutDetails();
  }

  getPowerCutDetails() {
    this.todayPowerCutDetailsSubscrition = this.siService
      .getTodayPowerCutDetails(this.installer_id, this.categoryId())
      .subscribe({
        next: (value: TodayPowerCutDetails) => {
          this.powercutDetailsandSummary = value.data;
        },
        error: (e: Error) => {
          this.toastr.error(e.message);
        },
        complete: () => {
          this.baseUserService.powerCutLoading.next(true);
        },
      });
  }

  ngOnDestroy(): void {
    this.todayPowerCutDetailsSubscrition.unsubscribe();
  }
}
