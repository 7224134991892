import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Location } from '@angular/common';
import { ProductService } from '../../../shared/service/product.service';
import { NGXLogger } from 'ngx-logger';
import { VideoGuideData } from '../../../shared/models/video-guide';
import { BaseComponent } from '../../../base/components/base.component';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { SessionService } from '../../../shared/service/session.service';
@Component({
  selector: 'app-video-guide',
  standalone: true,
  imports: [],
  templateUrl: './video-guide.component.html',
  styleUrl: './video-guide.component.scss',
})
export class VideoGuideComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  productService = inject(ProductService);
  videoGuideData: VideoGuideData[] = [];
  videoGuideSubscription?: Subscription;
  sessionService: SessionService = inject(SessionService);

  constructor(private _location: Location) {
    super();
  }

  ngOnInit(): void {
    this.resolveAllSubscriptions([this.fetchVideoGuide()]);
    this.baseUserService.sessionService.startBnWatch(this.router);
  }

  /**
   * fetch video guide
   */
  fetchVideoGuide() {
    let returnSubject = new Subject<boolean>();
    this.videoGuideSubscription = this.productService
      .fetchVideoGuide()
      .subscribe({
        next: (videoGuideResponse) => {
          this.videoGuideData = videoGuideResponse?.data;
          returnSubject.next(true);
        },
        error: (error) => {
          this.logger.error(error);
          returnSubject.next(true);
        },
        complete: () => {
          returnSubject.complete();
        },
      });
    return returnSubject;
  }

  goBack(): void {
    this.baseUserService.back();
  }
  openLink(item: VideoGuideData) {
    window.open(item.url, '_blank');
  }

  ngOnDestroy(): void {
    this.videoGuideSubscription?.unsubscribe();
    this.baseUserService.sessionService.stopBnTimer();
  }
}
