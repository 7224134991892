import { Pipe, PipeTransform } from '@angular/core';
import { noxMonths } from '../utils/date_utils';

@Pipe({
  name: 'noxDate',
  standalone: true,
})
export class NoxDatePipe implements PipeTransform {
  transform(value: number | string | undefined): string {
    if (typeof value === 'number') {
      const monthNames = noxMonths.map((month) => month.name);
      return monthNames[value];
    } else if (typeof value === 'string') {
      const [hours, minutes] = value.split(':').map(Number);
      const hourText =
        hours > 0 ? `${hours} Hour` + (hours > 1 ? 's' : '') : '';
      const minuteText =
        minutes > 0 ? `${minutes} min` + (minutes > 1 ? 's' : '') : '0';
      if (hourText && minuteText) {
        return `${hourText} ${minuteText}`;
      } else if (minuteText == '0') {
        return '0 min';
      } else {
        return hourText || minuteText;
      }
    } else {
      return '';
    }
  }
}
