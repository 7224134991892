import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { BaseComponent } from '../../../../base/components/base.component';
import { ProductService } from '../../../../shared/service/product.service';
import { CustomerService } from '../../../services/customer.service';
import {
  Assignee,
  AssigneeResult,
} from '../../../../shared/models/assignee-response';
import { USER_KEY } from '../../../../shared/service/storage.service';
import { Subject } from 'rxjs/internal/Subject';
import { CreateTicket } from '../../../../shared/models/create-ticket';
import { ViewTicket } from '../../../../shared/models/view-ticket';
import {
  UpdateTicket,
  UpdateTicketDetail,
} from '../../../../shared/models/update-ticket';
import { identifierName } from '@angular/compiler';

const URL = 'path_to_api';

@Component({
  selector: 'app-ticket-dialog',
  standalone: true,
  imports: [FontAwesomeModule, CommonModule, ReactiveFormsModule],
  templateUrl: './ticket-dialog.component.html',
  styleUrls: ['./ticket-dialog.component.scss'],
})
export class TicketDialogComponent extends BaseComponent {
  userresponse!: Assignee;

  plantName = localStorage.getItem('plantname');
  isOpen = false;
  faClose = faClose;
  ticketForm: FormGroup;
  value = false;
  uploadCounter: Array<number> = [1];
  imageUrl!: any;
  categories = ['Solar'];
  assignees: Array<String> = [];
  priorities = ['P1', 'P2', 'P3', 'P4'];
  ticketStatus = ['Open', 'In Progress', 'Closed'];
  prodService = inject(ProductService);
  customerService = inject(CustomerService);
  public selectedFile!: File;
  form: any;
  newForm!: any;
  image!: string | ArrayBuffer | null;
  imageArray: Array<string> = [];
  @ViewChild('fileInput') fileInput!: ElementRef;
  update!: boolean;
  images: { file: File | null; preview: string | null; isUploaded: boolean }[] =
    [{ file: null, preview: null, isUploaded: false }];
  updatedForm!: UpdateTicketDetail;
  documentId!: string;
  ticketId!: string;

  constructor(private fb: FormBuilder) {
    super();
    this.ticketForm = this.fb.group({
      assignee: ['', [Validators.required]],
      category: ['', [Validators.required]],
      priority: ['', [Validators.required]],
      ticketStatus: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      description: ['', [Validators.required, Validators.maxLength(1000)]],
      comment: ['', [Validators.required]],
    });
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
    this.images = [{ file: null, preview: null, isUploaded: false }];
    this.resetFileInput();
  }
  ngOnInit() {
    this.resolveAllSubscriptions([this.getAssigneeList()]);
  }

  resetFileInput(): void {
    if (this.fileInput?.nativeElement) {
      this.image = '';
      this.fileInput.nativeElement.value = '';
    }
  }

  getAssigneeList() {
    let loaderSubject = new Subject<boolean>();
    let siUserId = this.storageService.getObject(USER_KEY).id;

    this.customerService.getAssigneeDetails(siUserId).subscribe({
      next: (response: Assignee) => {
        loaderSubject.next(true);
        this.userresponse = response;
        for (let x of response) {
          this.assignees.push(x.name);
        }
      },
      error: (error) => {},
      complete: () => {
        loaderSubject.complete();
      },
    });
    return loaderSubject;
  }

  onFileSelected(event: any, index: number) {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.images[index] = {
          file: selectedFile,
          preview: e.target.result,
          isUploaded: false,
        }; // Set the preview for this specific image
      };
      reader.readAsDataURL(selectedFile);
    } else {
      alert('Invalid file type.Only ');
    }
  }

  uploadImage(index: number) {
    const selectedFile = this.images[index]?.file;
    if (selectedFile) {
      this.customerService.uploadImage(selectedFile).subscribe({
        next: (responsedata) => {
          this.imageUrl = responsedata;
          if (this.imageUrl) {
            this.imageArray.push(this.imageUrl);
          }

          this.images[index].isUploaded = true;
        },
        error: (error) => {
          console.error('Error uploading image:', error);
        },
      });
    }
  }

  addImage() {
    if (this.uploadCounter.length < 4) {
      this.uploadCounter.push(1);
      this.images.push({ file: null, preview: null, isUploaded: false });
    }
  }
  submitFormData() {
    let siUserId = this.storageService.getObject(USER_KEY).id;
    let pId = localStorage.getItem('pId');
    let form = this.ticketForm.value;
    let value = this.ticketForm.get('assignee')?.value;
    let assigneeedetails = this.userresponse.find(
      (user) => user.name === value,
    );
    this.newForm = {
      ...form,
      imageUrl: this.imageArray,
      siUserId: siUserId,
      plantId: pId,
      phone: assigneeedetails?.phone ?? '',
      email: assigneeedetails?.email ?? '',
    };

    this.updatedForm = {
      ...form,
      imageUrl: this.imageArray,
      siUserId: siUserId,
      id: this.documentId,
      ticketId: this.ticketId,
      phone: assigneeedetails?.phone,
      email: assigneeedetails?.email,
      plantId: pId,
    };

    if (this.update) {
      this.customerService.updateTicket(this.updatedForm).subscribe({
        next: (response: UpdateTicket) => {
          this.toastr.info(response.message);
          this.ticketForm.reset();
          this.toggle();
        },
        error: (error) => {},
      });
    } else {
      this.customerService.createTicket(this.newForm).subscribe({
        next: (response: CreateTicket) => {
          this.toastr.info(response.message);
          this.ticketForm.reset();
          this.toggle();
        },
        error: (error) => {},
        complete: () => {},
      });
    }
    this.resetFileInput();
  }

  openFormEditable(id: string, update: boolean, documentid: string) {
    this.ticketId = id;
    this.update = update;
    this.documentId = documentid;
    this.customerService.viewTicket(id).subscribe({
      next: (formdata: ViewTicket) => {
        let values = formdata.data;
        this.ticketForm.patchValue({
          assignee: values.assignee,
          category: values.category,
          priority: values.priority,
          ticketStatus: values.ticketStatus,
          subject: values.subject,
          description: values.description,
          comment: values.comment,
        });
        let images = values.imageUrl ?? [];
        this.uploadCounter.push(images.length > 0 ? images.length : 1);

        this.images =
          images.length > 0
            ? images.map((img) => ({
                file: null,
                preview: img,
                isUploaded: true,
              }))
            : [{ file: null, preview: null, isUploaded: false }];
      },
    });
  }
}
