<div
  class="relative flex flex-grow items-center justify-center h-full m-auto dark-gradient-bg"
>
  <div
    class="flex flex-col flex-grow justify-start items-start bg-[#1d232b] rounded-lg absolute top-0 bottom-0 left-0 right-0 mx-5 my-5"
  >
    <div class="flex items-center mt-10 cursor-pointer" (click)="goBack()">
      <img
        class="ml-10"
        src="../../../../assets/images/customer_screens/back arrow.svg"
        alt="Back"
      />
      <span class="text-white ml-4">Back</span>
    </div>
    <div class="flex flex-col items-center w-full h-full">
      <div>
        <h2 class="text-dark-font-heading font-extrabold gradient-header">
          Video Guide
        </h2>
      </div>
      <div class="flex flex-row flex-wrap justify-evenly px-[6%]">
        @for (item of videoGuideData; track $index) {
          <div
            class="flex flex-row card mt-2 cursor-pointer"
            (click)="openLink(item)"
          >
            <div
              class="w-32 relative flex justify-center items-center rounded-md"
            >
              <img
                src="{{ item.image }}"
                alt=" Thumbnail"
                class="w-32 h-20 object-cover rounded-md"
              />
              <img
                class="absolute"
                src="../../../../assets/images/customer_screens/play_icon.svg"
                alt="Play Video"
              />
            </div>
            <div class="flex flex-col ml-8 w-[70%] justify-center align-middle">
              <div>
                <p class="text-wrap text-white line-clamp-3">
                  {{ item.title }}
                </p>
              </div>
              <div
                class="flex flex-row justify-between items-center align-middle text-white opacity-50"
              >
                <div class="flex justify-center">
                  <img
                    src="../../../../assets/images/customer_screens/clock.svg"
                    alt="Video Thumbnail"
                  />
                  <span class="text-dark-content ml-4">{{
                    item.videoDate
                  }}</span>
                </div>
                <div class="hidden">
                  <span class="text-dark-content">Read more</span>
                </div>
              </div>
            </div>
          </div>
          <i class="far fa-clock"> </i>
        }
      </div>
    </div>
  </div>
</div>
