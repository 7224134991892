<div
  class="relative flex flex-grow items-center justify-center h-full w-full m-auto dark-gradient-bg"
>
  <div
    class="flex flex-col flex-grow justify-start items-center bg-[#1d232b] rounded-lg absolute top-0 bottom-0 left-0 right-0 mx-5 my-5"
  >
    <div class="w-full flex flex-row justify-start items-start">
      <div
        class="flex flex-row items-center mt-10 cursor-pointer"
        (click)="goBack()"
      >
        <img
          class="ml-10"
          src="../../../../assets/images/customer_screens/back arrow.svg"
          alt="
        Back"
        />
        <span class="text-white ml-4">Back</span>
      </div>
    </div>
    <div class="flex flex-col mt-10 items-center w-full">
      <div>
        <h2 class="text-lg font-medium gradient-header underline">FAQ</h2>
      </div>
      <div class="w-[80%] flex flex-row my-5 justify-between">
        <div class="flex flex-row space-x-4">
          <div
            class="dark:bg-none px-4 py-[5px] dark:text-white rounded-2xl border-solid border-[0.5px] border-gray-300 flex items-center space-x-2 cursor-pointer"
            [ngClass]="{
              'bg-dark-accent-secondary': selectedType == faqType.faq
            }"
            (click)="updateSelectedType(faqType.faq)"
          >
            <span class="text-sm"> {{ "General Info" }}</span>
          </div>
          <div
            class="dark:bg-none px-4 py-[5px] dark:text-white rounded-2xl border-solid border-[0.5px] border-gray-300 flex items-center space-x-2 cursor-pointer"
            [ngClass]="{
              'bg-dark-accent-secondary': selectedType == faqType.dos
            }"
            (click)="updateSelectedType(faqType.dos)"
          >
            <span class="text-sm"> {{ "Do's" }}</span>
          </div>
          <div
            class="dark:bg-none px-4 py-[5px] dark:text-white rounded-2xl border-solid border-[0.5px] border-gray-300 flex items-center space-x-2 cursor-pointer"
            [ngClass]="{
              'bg-dark-accent-secondary': selectedType == faqType.dont
            }"
            (click)="updateSelectedType(faqType.dont)"
          >
            <span class="text-sm"> {{ "Don't" }}</span>
          </div>
        </div>
        <div class="w-[30%] relative">
          <input
            id="searchbox"
            class="inputs leading-7 rounded-3xl border-[0.2px] border-neutral-50 w-[100%] px-2 bg-[#1d232b] text-white"
            type="text"
            placeholder="Search"
            (keyup)="onSearchFilter(inputValue.value)"
            #inputValue
            required
          />
          <img
            class="absolute top-2 right-[-8px]"
            src="../../../../assets/images/customer_screens/searchicon.svg"
            alt="search"
          />
        </div>
      </div>
    </div>
    <div class="grid grid-cols-2 overflow-auto w-[80%] gap-x-6 gap-y-4 my-4">
      @if (selectedType == faqType.faq) {
        @for (item of faqData; track $index) {
          <div
            class="flex flex-col bg-dark-variant-2 p-5 rounded-xl text-white items-start"
            [ngClass]="{ expanded: item.expand }"
            (click)="toggleAnswer(item)"
          >
            <div class="flex flex-row justify-between w-full">
              <span class="text-wrap text-sm line-clamp-2">{{
                item.question
              }}</span>
              <fa-icon
                [icon]="item.expand ? faChevronUp : faChevronDown"
                class="text-white"
              ></fa-icon>
            </div>
            <div *ngIf="item.expand" class="mt-2">
              <span class="text-wrap text-sm opacity-65">{{
                item.answer
              }}</span>
            </div>
          </div>
        }
      } @else if (selectedType == faqType.dos) {
        @for (item of dosList; track $index) {
          <div
            class="flex flex-col bg-dark-variant-2 p-5 rounded-xl text-white items-start"
          >
            <span class="text-wrap text-sm line-clamp-2">{{ item }}</span>
          </div>
        }
      } @else {
        @for (item of dontList; track $index) {
          <div
            class="flex flex-col bg-dark-variant-2 p-5 rounded-xl text-white items-start"
          >
            <span class="text-wrap text-sm line-clamp-2">{{ item }}</span>
          </div>
        }
      }
    </div>
  </div>
</div>
