/**
 * @ Author: Kunchok Tashi
 * @ Create Time: 2024-03-22 15:37:57
 * @ Modified by: Your name
 * @ Modified time: 2024-07-03 14:42:28
 * @ Description:
 */

import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterLink,
  RouterModule,
  RoutesRecognized,
} from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faPlus,
  faPen,
  faTrash,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import { CommonModule, Location } from '@angular/common';
import { BaseComponent } from '../../../base/components/base.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ProductService } from '../../../shared/service/product.service';
import {
  SolarInfoData,
  SolarPanelType,
} from '../../../shared/models/solar-info';
import {
  ConnectedUserData,
  UserDetails,
} from '../../../shared/models/connected-user';
import {
  ReportInterval,
  ReportIntervalEnum,
} from '../../../shared/models/report-interval';
import { Load, PlantDetail } from '../../../shared/models/plant';
import {
  NoxDialogParam,
  NoxDialogSize,
  NoxDialogType,
} from '../../../shared/models/nox-dialog-param';
import { MatDialog } from '@angular/material/dialog';
import { NoxDialogComponent } from '../../../shared/components/nox-dialog/nox-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../../shared/models/user';
import { TrendsComponent } from '../../../shared/components/trends/trends.component';
import { SessionService } from '../../../shared/service/session.service';
import { Subject, filter, pairwise } from 'rxjs';
import { DateUtils } from '../../../shared/utils/date_utils';
import {
  ChartOptions,
  NoxChartDta,
  NoxChartXY,
} from '../../../shared/models/nox-chart';
import {
  ApexAxisChartSeries,
  ApexXAxis,
  NgApexchartsModule,
} from 'ng-apexcharts';
import { NoxDatePickerComponent } from '../../../shared/components/nox-date-picker/nox-date-picker.component';
import {
  INVERTER_TYPE,
  PLANT_TYPE,
  USER_KEY,
} from '../../../shared/service/storage.service';
import { InverterType, PlantTypeEnum } from '../../../shared/models/stats';

let activatedTab: string = 'siteDetails';
let activatedLineWidth: number = 90;
let activatedLineLeft: number = 18;
@Component({
  selector: 'app-plant-detail',
  standalone: true,
  templateUrl: './plant-detail.component.html',
  styleUrl: './plant-detail.component.scss',
  imports: [
    FontAwesomeModule,
    TranslateModule,
    CommonModule,
    ReactiveFormsModule,
    TrendsComponent,
    RouterLink,
    RouterModule,
    NgApexchartsModule,
    NoxDatePickerComponent,
  ],
})
export class PlantDetailComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  enableAverageTariff(type: string | undefined) {
    switch (type) {
      case this.inverterType.SOLARVERTERPRO:
      case this.inverterType.PCU:
      case this.inverterType.HYBRID:
      case this.inverterType.NON_SOLAR:
        return true;
      default:
        return false;
    }
  }
  enableGridTariff(type: string | undefined) {
    switch (type) {
      case this.inverterType.GTI:
      case this.inverterType.HYBRID:
        return true;
      default:
        return false;
    }
  }
  activeTab: string;
  lineWidth: number;
  lineLeft: number;
  faPlus = faPlus;
  faPen = faPen;
  faTrash = faTrash;
  solarData?: SolarInfoData;
  plantData?: PlantDetail;
  prodService = inject(ProductService);
  activatedRoute = inject(ActivatedRoute);
  connectedUsersData?: ConnectedUserData;
  selectedPlantId?: string = '';
  dialog = inject(MatDialog);
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  expanded: boolean = false;
  noxChartData: NoxChartDta[] = [];
  noxChartXY: NoxChartXY[] = [];
  series: ApexAxisChartSeries = [];
  chartOptions: Partial<ChartOptions> = {};
  plantType = PlantTypeEnum;
  inverterType = InverterType;
  selectedPlantType?: string;
  selectedInverterType?: string;
  isPrimaryUser = false;
  activateTab(tabId: string, event: MouseEvent): void {
    this.activeTab = tabId;
    const target = event.target as HTMLElement;
    this.lineWidth = target.offsetWidth;
    this.lineLeft = target.offsetLeft;
  }
  toggleExpansion() {
    this.expanded = !this.expanded;
  }
  constructor(
    private route: ActivatedRoute,
    private _location: Location,
  ) {
    super();
    this.activeTab = activatedTab;
    this.lineWidth = activatedLineWidth;
    this.lineLeft = activatedLineLeft;
  }

  ngOnInit(): void {
    this.baseUserService.sessionService.startBnWatch(this.router);
    this.selectedPlantType = this.storageService.get(PLANT_TYPE) ?? '';
    this.selectedInverterType = this.storageService.get(INVERTER_TYPE) ?? '';

    this.activatedRoute.params.subscribe((params) => {
      this.selectedPlantId = params['selectedPlantId'];
      if (this.selectedPlantId) {
        this.resolveAllSubscriptions([
          this.fetchPlantData(this.selectedPlantId),
          this.fetchInverterData(this.selectedPlantId),
          this.fetchBatteryData(this.selectedPlantId),
          this.fetchDataLoggerData(this.selectedPlantId),
          this.fetchSolarData(this.selectedPlantId),
          this.fetchSecondaryUser(this.selectedPlantId),
          this.fetchDataLoggerTrends(this.selectedPlantId),
        ]);
      }
      this.routeEventListener();
    });
  }
  routeEventListener() {
    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise(),
      )
      .subscribe((events: RoutesRecognized[]) => {
        if (
          events[0] &&
          events[0].urlAfterRedirects.includes('add-secondary-user')
        ) {
          activatedTab = 'connectedUser';
          activatedLineWidth = 122;
          activatedLineLeft = 607;
        }
      });
  }
  fetchDataLoggerTrends(selectedPlantId: string): Subject<boolean> {
    let loaderSubject = new Subject<boolean>();
    let todayDate = DateUtils.getStartDayTimeStampFromDate(new Date());
    this.prodService.getDataLoggerTrends(selectedPlantId, todayDate).subscribe({
      next: (response) => {
        if (response?.status === 200) {
          this.noxChartXY = [];
          this.noxChartData = [];
          response?.data.forEach((item) => {
            this.noxChartXY.push({
              x: item.time * 1000, // convert to milliseconds
              y: Number(item.wifiSignalStrength),
            });
          });
          this.noxChartData.push({
            name: 'Data logger strength',
            noxXY: this.noxChartXY,
          });
          this.plotBarChart(this.noxChartData);
        }
        loaderSubject.next(true);
      },
      error: (e: Error) => {
        this.logger.error(e.message);
        loaderSubject.next(true);
      },
      complete: () => loaderSubject.complete(),
    });
    return loaderSubject;
  }
  plotBarChart(data: NoxChartDta[]) {
    this.series = data.map((item) => {
      return {
        name: item.name,
        data: item.noxXY.map((item) => {
          return {
            x: item.x,
            y: item.y,
          };
        }),
      };
    });

    let xAxisValue: ApexXAxis = {
      type: 'datetime',
      tickAmount: 8,
      min: data[0].noxXY[0].x,
      max: data[0].noxXY[data[0].noxXY.length - 1].x,
      labels: {
        //format: 'DD/MM/YYYY',
        datetimeUTC: false,
        formatter: function (value, timestamp: number) {
          return DateUtils.formatDateFromLocalEpoch(timestamp, 'hh:mm a');
        },
        style: {
          colors: '#94989E',
          fontSize: '10',
        },
      },

      title: {
        text: 'Time (hh:mm)',
        style: {
          color: '#FFFFFF',
          fontSize: '10',
        },
      },
    };

    this.chartOptions = {
      series: this.series,
      stroke: {
        width: 1,
      },
      chart: {
        type: 'bar',
        width: '100%',
        height: '100%',
        offsetY: -5,
        toolbar: {
          show: true,
          tools: {
            pan: true,
          },
        },
        events: {
          beforeZoom: (e, { xaxis }) => {
            let maindifference =
              new Date(data[0].noxXY[data[0].noxXY.length - 1].x).valueOf() -
              new Date(data[0].noxXY[0].x).valueOf();
            let zoomdifference = xaxis.max - xaxis.min;

            if (zoomdifference > maindifference)
              return {
                // dont zoom out any further
                xaxis: {
                  min: data[0].noxXY[0].x,
                  max: data[0].noxXY[data[0].noxXY.length - 1].x,
                },
              };
            else {
              return {
                // keep on zooming
                xaxis: {
                  min: xaxis.min,
                  max: xaxis.max,
                },
              };
            }
          },
        },
      },
      grid: {
        padding: {
          bottom: 5,
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          colorStops: [
            {
              offset: 0,
              color: '#527563',
              opacity: 1,
            },
            {
              offset: 20,
              color: '#65aabe',
              opacity: 1,
            },
          ],
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: 4,
          borderRadius: 2,
          borderRadiusApplication: 'end',
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        title: {
          text: '', //Y label
          style: {
            color: '#FFFFFF',
            fontSize: '12',
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 400,
          },
        },
        tickAmount: 4,
        min: 0,
        max: 5,
        labels: {
          formatter(val) {
            let strength = 'good';
            if (val <= 1) {
              strength = 'Bad';
            } else if (val <= 2) {
              strength = 'Weak';
            } else if (val <= 3) {
              strength = 'Good';
            } else if (val <= 4) {
              strength = 'Very Good';
            } else if (val <= 5) {
              strength = 'Excellent';
            }
            return strength;
          },
          style: {
            colors: '#94989E',
            fontSize: '12',
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 400,
          },
        },
      },
      xaxis: xAxisValue,
    };
  }

  /**
   *
   * @param selectedPlantId
   */
  fetchInverterData(selectedPlantId: string) {
    let loaderSubject = new Subject<boolean>();
    this.prodService.getInverterDetail(selectedPlantId).subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.inverterForm.patchValue(response.data);
        }
        loaderSubject.next(true);
      },
      error: (e: Error) => {
        this.logger.error(e.message);
        loaderSubject.next(true);
      },
      complete: () => loaderSubject.complete(),
    });
    return loaderSubject;
  }
  /**
   *
   * @param plantId
   */
  fetchPlantData(plantId: string) {
    let loaderSubject = new Subject<boolean>();
    this.prodService.getPlantDetail(plantId).subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.plantData = response.data;
          this.plantForm.patchValue(this.plantData);
          // Clear existing panels array
          const applianceArray = this.plantForm.get(
            'typesOfAppliance',
          ) as FormArray;
          applianceArray.clear();

          // Add panels to the form array
          if (this.plantData.loads) {
            this.plantData.loads.forEach((appliance) => {
              applianceArray.push(this.createLoadFormGroup(appliance));
            });
          }
        }
        loaderSubject.next(true);
      },
      error: (e: Error) => {
        this.logger.error(e.message);
        loaderSubject.next(true);
      },
      complete: () => loaderSubject.complete(),
    });
    return loaderSubject;
  }
  createLoadFormGroup(load: Load) {
    return new FormGroup({
      appliances: new FormControl(load.appliances),
      power: new FormControl(load.power),
      quantity: new FormControl(load.quantity),
      priority: new FormControl(load.priority),
    });
  }
  /**
   *
   * @param plantId
   */
  fetchBatteryData(plantId: string): Subject<boolean> {
    let loaderSubject = new Subject<boolean>();

    this.prodService.getBatteryDetail(plantId).subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.batteryForm.patchValue(response.data);
        }
        loaderSubject.next(true);
      },
      error: (e: Error) => {
        this.logger.error(e.message);
        loaderSubject.next(true);
      },
      complete: () => loaderSubject.complete(),
    });
    return loaderSubject;
  }

  fetchDataLoggerData(plantId: string): Subject<boolean> {
    let loaderSubject = new Subject<boolean>();

    this.prodService.getDataLogger(plantId).subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.dataLoggerForm.patchValue(response.data);
        }
      },
      error: (e: Error) => {
        this.logger.error(e.message);
        loaderSubject.next(true);
      },
      complete: () => loaderSubject.complete(),
    });
    return loaderSubject;
  }

  fetchSecondaryUser(plantId: string): Subject<boolean> {
    let loaderSubject = new Subject<boolean>();
    this.prodService.getConnectedUser(plantId).subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.connectedUsersData = response.data;
          if (this.connectedUsersData.primaryUser) {
            let user: User = this.storageService.getObject(USER_KEY);
            if (this.connectedUsersData.primaryUser.userId == user.id) {
              this.isPrimaryUser = true;
            } else {
              this.isPrimaryUser = false;
            }
          }
        }
        loaderSubject.next(true);
      },
      error: (e: Error) => {
        this.logger.error(e.message);
        loaderSubject.next(true);
      },
      complete: () => loaderSubject.complete(),
    });
    return loaderSubject;
  }

  /**
   *
   * @param plantId
   */
  fetchSolarData(plantId: string): Subject<boolean> {
    let loaderSubject = new Subject<boolean>();

    this.prodService.getSolarDetail(plantId).subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.solarData = response.data;
          this.solarForm.patchValue({
            lighteningArrester: this.solarData.lighteningArrester,
            orientation: this.solarData.orientation,
            purchaseBillImage: this.solarData.purchaseBillImage,
            noofPanelsinSeries: this.solarData.noofPanelsinSeries,
            tiltAngle: this.solarData.tiltAngle,
            totalInstalledCapacity: this.solarData.totalInstalledCapacity,
            solarTrackingSystem: this.solarData.solarTrackingSystem,
            earthing: this.solarData.earthing,
            noofPanelsInParallel: this.solarData.noofPanelsInParallel,
            typeSolarPanels: this.solarData.typeSolarPanels,
            axisVaue: this.solarData.axisVaue,
            installationPlace: this.solarData.installationPlace,
            panelImage: this.solarData.panelImage,
          });
          // Clear existing panels array
          const panelsArray = this.solarForm.get('panels') as FormArray;
          panelsArray.clear();

          // Add panels to the form array
          this.solarData.panels.forEach((panel) => {
            panelsArray.push(this.createPanelFormGroup(panel));
          });
        }
        loaderSubject.next(true);
      },
      error: (e: Error) => {
        this.logger.error(e.message);
        loaderSubject.next(true);
      },
      complete: () => loaderSubject.complete(),
    });
    return loaderSubject;
  }
  createPanelFormGroup(panel: SolarPanelType): FormGroup {
    return new FormGroup({
      brand: new FormControl({ value: panel.brand, disabled: true }),
      capacity: new FormControl({ value: panel.capacity, disabled: true }),
      panelType: new FormControl({ value: panel.panelType, disabled: true }),
      noofPanel: new FormControl({ value: panel.noofPanel, disabled: true }),
      openCircuitVoltage: new FormControl({
        value: panel.openCircuitVoltage,
        disabled: true,
      }),
      shortCircuitCurrent: new FormControl({
        value: panel.shortCircuitCurrent,
        disabled: true,
      }),
      otherBrand: new FormControl({ value: panel.otherBrand, disabled: true }),
      otherType: new FormControl({ value: panel.otherType, disabled: true }),
    });
  }

  setFormData(): void {}

  removeUser(user: UserDetails) {
    let noxDialogData: NoxDialogParam = {
      type: NoxDialogType.confirm,
      title:
        'Are you sure, you want to remove ' +
        user.userName +
        ' as Secondary user?',
      positiveBtnLabel: 'Remove',
      negativeBtnLabel: 'Cancel',
      dialogSize: NoxDialogSize.small,
    };
    const dialogRef = this.dialog.open(NoxDialogComponent, {
      data: noxDialogData,
    });
    dialogRef.componentInstance.positiveListener = () => {
      if (this.connectedUsersData && this.plantData) {
        this.resolveAllSubscriptions([this.removeSecondaryUser(user)]);
      }

      dialogRef.close();
    };
    dialogRef.componentInstance.negativeListener = () => {
      dialogRef.close();
    };
  }
  removeSecondaryUser(user: UserDetails): Subject<boolean> {
    let loaderSubject = new Subject<boolean>();
    this.prodService
      .removeConnectedUser(user.userId, this.plantData!.id)
      .subscribe({
        next: (response) => {
          if (response.status === 200) {
            this.toastr.success('User removed successfully');
            let seocndaryUsers =
              this.connectedUsersData?.seocndaryUsers.filter(
                (item) => item.userId !== user.userId,
              ) || [];
            let primaryUser = this.connectedUsersData!.primaryUser;
            this.connectedUsersData = {
              primaryUser,
              seocndaryUsers,
            };
          }
          loaderSubject.next(true);
        },
        error: () => {
          loaderSubject.next(true);
          loaderSubject.complete();
        },
        complete: () => {
          loaderSubject.complete();
        },
      });
    return loaderSubject;
  }

  deactivatePlant() {
    let noxDialogData: NoxDialogParam = {
      type: NoxDialogType.confirm,
      title: 'Are you sure, you want to deactivate the plant?',
      positiveBtnLabel: 'Deactivate',
      negativeBtnLabel: 'Cancel',
      dialogSize: NoxDialogSize.small,
    };
    const dialogRef = this.dialog.open(NoxDialogComponent, {
      data: noxDialogData,
    });
    dialogRef.componentInstance.positiveListener = () => {
      if (this.plantData) {
        this.spinner.show();
        this.prodService.deactivatePlant(this.plantData.id).subscribe({
          next: (response) => {
            if (response.status === 200) {
              this.toastr.success('Plant deactivated successfully');
              this.router.navigate(['/customer/dashboard'], {
                queryParams: { plantId: this.plantData!.id },
                replaceUrl: true,
              });
            }
            this.spinner.hide();
          },
          error: (err) => {
            this.spinner.hide();
            this.logger.error(err);
          },
          complete: () => {
            this.spinner.hide();
          },
        });
      }

      dialogRef.close();
    };
    dialogRef.componentInstance.negativeListener = () => {
      dialogRef.close();
    };
  }

  downloadReport() {
    if (this.selectedPlantId && this.radioForm.controls.selectedOption.value) {
      let reportInterval: ReportInterval = JSON.parse(
        JSON.stringify(this.radioForm.controls.selectedOption.value),
      );

      let intervalValue = 0;

      switch (reportInterval.key) {
        case ReportIntervalEnum.last_seven:
          intervalValue = 1;
          break;
        case ReportIntervalEnum.last_month:
          intervalValue = 2;
          break;
        case ReportIntervalEnum.last_three_month:
          intervalValue = 3;
          break;
        case ReportIntervalEnum.last_six_month:
          intervalValue = 4;
          break;
        case ReportIntervalEnum.this_year:
          intervalValue = 5;
          break;
        default:
          break;
      }

      this.prodService
        .downloadPlantReport(this.selectedPlantId, intervalValue, 'GMT')
        .subscribe((response) => {
          if (response.status === 200) {
            this.toastr.success('Report sent successfully');
          }
        });
    } else {
      this.toastr.info('Please select the report interval');
    }
  }

  goBack(): void {
    this.baseUserService.back();
  }
  /**
   * @description: Form group for plant details
   */
  plantForm: FormGroup = new FormGroup({
    id: new FormControl({ value: '', disabled: true }),
    plantName: new FormControl(
      { value: '', disabled: true },
      Validators.required,
    ),
    electricityBoard: new FormControl({ value: '', disabled: true }),
    location: new FormControl({ value: '', disabled: true }),
    utilityConsumerNo: new FormControl({ value: '', disabled: true }),
    pinCode: new FormControl({ value: '', disabled: true }),
    currency: new FormControl({ value: '', disabled: true }),
    timeZone: new FormControl({ value: '', disabled: true }),
    installationDate: new FormControl({ value: '', disabled: true }),
    averageTariffRate: new FormControl({ value: 0, disabled: true }),
    gridFeedTariff: new FormControl({ value: 0, disabled: true }),
    averageMonthlyBill: new FormControl({ value: 0, disabled: true }),
    totalInvestment: new FormControl({ value: 0, disabled: true }),
    systemIntegratorId: new FormControl({ value: '', disabled: true }),
    thirdPartyId: new FormControl({ value: '', disabled: true }),
    plantPhoto: new FormControl({ value: '', disabled: true }),
    electricianNumber: new FormControl({ value: '', disabled: true }),
    lat: new FormControl({ value: 0, disabled: true }),
    lon: new FormControl({ value: 0, disabled: true }),
    currencySymbol: new FormControl({ value: '', disabled: true }),
    typesOfAppliance: new FormArray([]),
  });

  /**
   * @description: Form group for inverter details
   */
  inverterForm = new FormGroup({
    model: new FormControl({ value: '', disabled: true }),
    serialNo: new FormControl({ value: '', disabled: true }),
    rating: new FormControl({ value: '', disabled: true }),
    dateOfPurchase: new FormControl({ value: '', disabled: true }),
    currentSetting: new FormControl({ value: '', disabled: true }),
    purchaseBillPhoto: new FormControl({ value: '', disabled: true }),
    inverterPhoto: new FormControl({ value: '', disabled: true }),
  });

  /**
   * @description: Form group for battery details
   */
  batteryForm = new FormGroup({
    totalBatteriesInstalledParallel: new FormControl({
      value: '',
      disabled: true,
    }),
    purchaseBillPhoto: new FormControl({ value: '', disabled: true }),
    batteryPerformanceProfile: new FormControl({ value: '', disabled: true }),
    totalBackUpCapacity: new FormControl({ value: '', disabled: true }),
    batteryPhoto: new FormControl({ value: '', disabled: true }),
    noOfBatteries: new FormControl({ value: 0, disabled: true }),
    singleBatteryVoltage: new FormControl({ value: '', disabled: true }),
    brand: new FormControl({ value: '', disabled: true }),
    batteryType: new FormControl({ value: '', disabled: true }),
    otherBrand: new FormControl({ value: '', disabled: true }),
    otherType: new FormControl({ value: '', disabled: true }),
    serialNo: new FormControl({ value: '', disabled: true }),
    dateOfPurchase: new FormControl({ value: '', disabled: true }),
    capacity: new FormControl({ value: '', disabled: true }),
    totalBatteriesInstalledSeries: new FormControl({
      value: '',
      disabled: true,
    }),
  });

  /**
   * @description: Form group for solar details
   */

  solarForm = new FormGroup({
    lighteningArrester: new FormControl(false),
    orientation: new FormControl({ value: '', disabled: true }),
    purchaseBillImage: new FormControl({ value: '', disabled: true }),
    panels: new FormArray([]),
    noofPanelsinSeries: new FormControl({ value: 0, disabled: true }),
    tiltAngle: new FormControl({ value: 0, disabled: true }),
    totalInstalledCapacity: new FormControl({ value: '', disabled: true }),
    solarTrackingSystem: new FormControl({ value: false, disabled: true }),
    earthing: new FormControl({ value: false, disabled: true }),
    noofPanelsInParallel: new FormControl({ value: 0, disabled: true }),
    typeSolarPanels: new FormControl({ value: 0, disabled: true }),
    axisVaue: new FormControl({ value: 0, disabled: true }),
    installationPlace: new FormControl({ value: 0, disabled: true }),
    panelImage: new FormControl({ value: '', disabled: true }),
  });

  /**
   * @description: Form group for data logger details
   */
  dataLoggerForm = new FormGroup({
    connectivityType: new FormControl({ value: '', disabled: true }),
    firmWareVersion: new FormControl({ value: '', disabled: true }),
    installationDate: new FormControl({ value: '', disabled: true }),
    serialNo: new FormControl({ value: '', disabled: true }),
  });

  radioForm = new FormGroup({
    selectedOption: new FormControl(''),
  });
  options: ReportInterval[] = [
    { key: ReportIntervalEnum.last_seven, value: 'Last 7 Days' },
    { key: ReportIntervalEnum.last_three_month, value: 'Last 3 Month' },

    { key: ReportIntervalEnum.this_year, value: 'This Year' },
    { key: ReportIntervalEnum.last_month, value: 'Last Month' },
    { key: ReportIntervalEnum.last_seven, value: 'Last 6 Month' },
  ];

  ngOnDestroy(): void {
    this.resetTab();
    this.baseUserService.sessionService.stopBnTimer();
  }

  displayImage(url: string) {
    let noxDialogData: NoxDialogParam = {
      type: NoxDialogType.image,
      imageUrl: url,
    };
    this.dialog.open(NoxDialogComponent, {
      data: noxDialogData,
    });
  }
  resetTab() {
    activatedTab = 'siteDetails';
    activatedLineWidth = 90;
    activatedLineLeft = 18;
  }
}
