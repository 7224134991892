<div
  class="flex bg-bgCard text-white h-full flex-col flex-grow overflow-y-auto min-w-[900px] min-h-[620px]"
>
  <div class="flex m-2 items-center">
    <div (click)="back()" class="cursor-pointer">
      <fa-icon [icon]="faChevronLeft" class="mr-1 text-white"></fa-icon>Back
    </div>
  </div>
  <div class="bg-dark-primary flexBetween mt-2 mx-2 p-2">
    <div class="flex ml-4">
      @for (item of alertsTypesData; track $index) {
        <div class="flexBetween mr-5">
          <span
            class="mr-1 text-alert-header text-[14px] leading-[16px] font-normal"
            >{{ item.name }}</span
          >
          <span class="font-semibold ml-2">{{ item.value }}</span>
        </div>
      }
    </div>
    <div class="flex justify-around items-center">
      <div class="relative cursor-pointer" (click)="toggleFilter()">
        @if (showFilter) {
          <div
            class="absolute h-[300px] w-[550px] bg-dark-variant-7 top-[30px] right-[30px] flex rounded-[8px]"
          >
            <div
              class="flex w-[30%] bg-dark-variant-6 flex-col gap-3 p-2 rounded-tr-[25px] rounded-l-[8px]"
            >
              <div class="span1">
                <span>Date</span>
              </div>
              <div class="span2">
                <span>Alert Type</span>
              </div>
              <div class="span3">
                <span>Model Name</span>
              </div>
              <div class="span4">
                <span>Data Logger SN</span>
              </div>
              <div class="span5">
                <span>Customer Details</span>
              </div>
            </div>
            <div
              class="flex w-[70%] bg-transparent flex-col p-2 gap-3"
              (click)="$event.stopPropagation()"
            >
              <div class="flex">
                <input type="date" name="X" id="" />
                <span>to</span>
                <input type="date" name="X" id="" />
              </div>
              <div class="flex justify-between w-[75%]">
                <div class="flexBetween flex-wrap p-1 bg-bgSelect rounded-full">
                  <select
                    name="cars"
                    id="cars"
                    class="w-[144px] bg-bgSelect border-none text-white"
                    (change)="$event.stopPropagation()"
                  >
                    <option value="volvo">Volvo</option>
                    <option value="saab">Saab</option>
                    <option value="mercedes">Mercedes</option>
                    <option value="audi">Audi</option>
                  </select>
                </div>
                <div
                  class="flexBetween flex-wrap p-1 bg-bgSelect rounded-full ml-3"
                >
                  <select
                    name="cars"
                    id="cars"
                    class="w-[144px] bg-bgSelect border-none text-white"
                  >
                    <option value="volvo">Volvo</option>
                    <option value="saab">Saab</option>
                    <option value="mercedes">Mercedes</option>
                    <option value="audi">Audi</option>
                  </select>
                </div>
              </div>
              <div class="flex">
                <div class="flexBetween flex-wrap p-1 bg-bgSelect rounded-full">
                  <select
                    name="cars"
                    id="cars"
                    class="w-[144px] bg-bgSelect border-none text-white"
                  >
                    <option value="volvo">Volvo</option>
                    <option value="saab">Saab</option>
                    <option value="mercedes">Mercedes</option>
                    <option value="audi">Audi</option>
                  </select>
                </div>
              </div>
              <div class="flex">
                <div class="flexBetween flex-wrap p-1 bg-bgSelect rounded-full">
                  <select
                    name="cars"
                    id="cars"
                    class="w-[144px] bg-bgSelect border-none text-white"
                  >
                    <option value="volvo">Volvo</option>
                    <option value="saab">Saab</option>
                    <option value="mercedes">Mercedes</option>
                    <option value="audi">Audi</option>
                  </select>
                </div>
              </div>
              <div class="flex">
                <div class="flexBetween flex-wrap p-1 bg-bgSelect rounded-full">
                  <select
                    name="cars"
                    id="cars"
                    class="w-[144px] bg-bgSelect border-none text-white"
                  >
                    <option value="volvo">Volvo</option>
                    <option value="saab">Saab</option>
                    <option value="mercedes">Mercedes</option>
                    <option value="audi">Audi</option>
                  </select>
                </div>
              </div>
              <div class="flex">
                <button>confirm</button>
                <button class="ml-4">clear</button>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
  <div class="mx-2 h-full flex flex-col flex-grow overflow-y-auto">
    <table class="w-full">
      <thead class="bg-btTh">
        <tr>
          @for (item of alertHeaders; track $index) {
            <th
              scope=""
              class="text-left p-2 font-medium leading-[14px] text-[12px]"
            >
              {{ item }}
            </th>
          }
        </tr>
      </thead>
      <tbody>
        @for (item of alertsData; track $index) {
          <tr>
            @if (item.plantName) {
              <td
                scope="row"
                class="p-3 text-[14px] leading-[16.41px] font-normal"
              >
                {{ item.plantName }}
              </td>
            }
            @if (item.alerts) {
              <td
                scope="row"
                class="p-3 text-[14px] leading-[16.41px] font-normal"
              >
                {{ item.alerts }}
              </td>
            }
            @if (item.type) {
              <td scope="row">
                {{ item.type }}
              </td>
            }

            @if (item.modelName) {
              <td
                scope="row"
                class="p-3 text-[14px] leading-[16.41px] font-normal"
              >
                {{ item.modelName }}
              </td>
            }
            @if (item.dataLoggerSN) {
              <td
                scope="row"
                class="p-3 text-[14px] leading-[16.41px] font-normal"
              >
                {{ item.dataLoggerSN }}
              </td>
            }

            @if (item.occurred) {
              <td
                scope="row"
                class="p-3 text-[14px] leading-[16.41px] font-normal"
              >
                {{ item.occurred }}
              </td>
            }
            @if (item.resolved) {
              <td
                scope="row"
                class="p-3 text-[14px] leading-[16.41px] font-normal"
              >
                {{ item.resolved }}
              </td>
            }
            @if (item.customerDetailsResponse) {
              <td
                scope="row"
                class="p-3 text-[14px] leading-[16.41px] font-normal"
              >
                <div class="flex flex-col">
                  <span class="text-xs">{{
                    item.customerDetailsResponse.name
                  }}</span>
                  <span class="text-xs">{{
                    item.customerDetailsResponse.email
                  }}</span>
                </div>
              </td>
            }
          </tr>
        }
      </tbody>
    </table>
  </div>
</div>
