<div
  [class.open]="isOpen"
  class="side-sheet fixed top-0 bottom-0 h-screen w-1/2 z-30 overflow-y-auto right-[-100%] bg-[#35333c] rounded-lg my-4 py-3 px-5 overflow-scroll"
>
  <!-- Side Sheet Content -->
  <div class="flex flex-col">
    <div class="flex flex-row justify-start items-center space-x-2">
      <fa-icon
        [icon]="faClose"
        class="p-2 text-white cursor-default"
        (click)="toggle()"
      ></fa-icon>
      <span class="text-sm font-medium">String Info</span>
    </div>
    <span class="mt-1 text-xs opacity-50">Connected Device</span>

    <div class="flex flex-row items-center space-x-2 mt-1">
      <span>{{ statsData?.modelName }} | </span>
      <span class="text-xs opacity-50 dark:text-white"
        >Last Updated:
        {{
          (statsData?.stats)!.last_updated_timestamp * 1000
            | date: "dd/MM/yyyy hh:mm:ss a"
        }}
      </span>
    </div>
    <hr class="hidden w-full" />
    <div
      class="grid grid-cols-4 grid-rows-1 mt-3 bg-dark-accent-secondary rounded-lg px-3 py-[3px]"
    >
      <span class="col-span-1">Photovoltaic</span>
      <span class="col-span-1">Voltage (V)</span>
      <span class="col-span-1">Current (A)</span>
      <span class="col-span-1">Power (kW)</span>
    </div>
    <div class="grid grid-cols-4 px-3 gap-y-3 pt-3">
      @for (item of stringInfoData?.strings; track $index) {
        <span class="col-span-1">{{ item.pvNames }}</span>
        <span class="col-span-1">{{ item.pvVolltage }}</span>
        <span class="col-span-1">{{ item.pvCurrent }}</span>
        <span class="col-span-1">{{ item.pvPower }}</span>
      }
    </div>
    <hr class="w-full my-3" />
    <div>
      <span>Trends</span>
      <div>
        <div
          class="flex flex-wrap border-b border-gray-200 dark:border-gray-700"
        >
          <div
            class="flex flex-row justify-center items-center w-full space-x-6"
          >
            <div
              class="dark:bg-none px-4 py-2 dark:text-white rounded-xl border-solid border-[1px] border-gray-300 flex items-center space-x-2 cursor-pointer"
              [ngClass]="{
                'bg-dark-accent-secondary':
                  selectedTab === stringInfoType.VOLTAGE
              }"
              (click)="selectTab(stringInfoType.VOLTAGE)"
            >
              <span class="text-white text-xs"> Voltage (V)</span>
            </div>
            <div
              class="dark:bg-none px-4 py-2 dark:text-white rounded-xl border-solid border-[1px] border-gray-300 flex items-center space-x-2 cursor-pointer"
              [ngClass]="{
                'bg-dark-accent-secondary':
                  selectedTab === stringInfoType.CURRENT
              }"
              (click)="selectTab(stringInfoType.CURRENT)"
            >
              <span class="text-white text-xs"> Current (A)</span>
            </div>
            <div
              class="dark:bg-none px-4 py-2 dark:text-white rounded-xl border-solid border-[1px] border-gray-300 flex items-center space-x-2 cursor-pointer"
              [ngClass]="{
                'bg-dark-accent-secondary': selectedTab === stringInfoType.POWER
              }"
              (click)="selectTab(stringInfoType.POWER)"
            >
              <span class="text-white text-xs"> Power (kW)</span>
            </div>
          </div>
        </div>
        <div class="bg-[#3f3c46] rounded-xl p-2 my-4 mx-4">
          @if (chartOptions && chartOptions.chart?.type && chartOptions.series && chartOptions.series.length > 0) {
            <apx-chart
              [series]="chartOptions.series!"
              [chart]="chartOptions.chart!"
              [xaxis]="chartOptions.xaxis!"
              [yaxis]="chartOptions.yaxis!"
              [tooltip]="chartOptions.tooltip!"
              [dataLabels]="chartOptions.dataLabels!"
              [stroke]="chartOptions.stroke!"
              [plotOptions]="chartOptions.plotOptions!"
              [grid]="chartOptions.grid!"
              [legend]="chartOptions.legend!"
            ></apx-chart>
          } @else {
            <div class="flex flex-grow justify-center items-center">
              <span class="text-xs dark:text-white">{{
                "no_record_found" | translate
              }}</span>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Overlay -->
<div *ngIf="isOpen" class="overlay" (click)="toggle()"></div>
