import { HttpHeaders, HttpInterceptorFn } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { User } from '../models/user';
import { AuthService } from '../service/auth.service';
import { inject } from '@angular/core';
import { StorageService, TOKEN, USER_KEY } from '../service/storage.service';
import { NGXLogger } from 'ngx-logger';

export const headerImplInterceptor: HttpInterceptorFn = (req, next) => {
  const storageService = inject(StorageService);
  const logger = inject(NGXLogger);

  if (!openUrl(req.url)) {
    if (includedUrl(req.url)) {
      let user: User = storageService.getObject(USER_KEY);
      let token = storageService.get(TOKEN) ?? '';
      if (user && token) {
        const authReq = req.clone({
          setHeaders: {
            'Content-Type': 'application/json',
            accept: 'application/json',
            authorization: token,
            'X-UserID': user.id,
          },
        });
        return next(authReq);
      }
    }
  }
  return next(req);
};
function includedUrl(url: string) {
  return (
    url.includes(environment.externalUrls.user.baseUrl) ||
    url.includes(environment.externalUrls.product.baseUrl) ||
    url.includes(environment.externalUrls.system_integration.baseUrl) ||
    url.includes(environment.externalUrls.data_analytics.baseUrl)
  );
}
function openUrl(url: string): boolean {
  const paths = [
    '/loginEmail',
    '/forgot-password-new',
    'otp/validateNew',
    '/password',
    '/forgot-password-new-v1',
  ];
  return paths.some((path) => url.endsWith(path));
}
