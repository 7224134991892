<!--
 * @ Author: Kunchok Tashi
 * @ Create Time: 2024-03-22 15:37:57
 * @ Modified by: Your name
 * @ Modified time: 2024-07-11 12:44:39
 * @ Description:
 -->

<!-- Content -->

<div class="w-full h-full overflow-y-auto min-w-[920px] min-h-[620px]">
  <!-- New code start-->

  <div class="h-full w-full flex flex-row">
    @if (isPlantEmptyUser) {
      <div
        class="flex flex-col flex-grow justify-start items-start bg-[#1d232b] rounded-lg m-5"
      >
        <div class="flex flex-col items-center w-full h-full">
          <div
            class="w-full flex flex-col flex-grow justify-evenly items-center mb-10"
          >
            <div>
              <div
                class="flex flex-col space-x-2 my-4 justify-center items-center gap-y-5"
              >
                <img
                  src="../../../../../assets/svg/add-plant-onboard.svg"
                  class="max-w-72 sm:w-40 md:w-52 lg:w-64"
                  alt="icon"
                />
                <span class="text-white label-xl text-center"
                  >Elevate your energy<br />
                  monitoring experience
                </span>

                <div
                  class="flex flex-row justify-center items-center rounded-xl bg-dark-accent-secondary px-4 py-[10px] text-white cursor-default"
                  (click)="addNewPlantPopup()"
                >
                  <span class="text-xs p-0">{{
                    "Add New Plant" | translate
                  }}</span>
                  <fa-icon
                    [icon]="faChevronRight"
                    class="ml-2 text-xs"
                  ></fa-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    } @else if (isPlantDeactivated) {
      <div
        class="flex flex-col flex-grow justify-start items-start bg-[#1d232b] rounded-lg m-5"
      >
        <div class="flex flex-col items-center w-full h-full">
          <div
            class="w-full flex flex-col flex-grow justify-evenly items-center mb-10"
          >
            <div>
              <div
                class="flex flex-col space-x-2 my-4 justify-center items-center gap-y-1"
              >
                <span class="text-white label-xl"
                  >{{ selectedPlant?.plantName }} is deactivated.</span
                >
                <span class="text-white label-lg opacity-50"
                  >To get the real time monitoring of the plant.</span
                >
                <div
                  class="flex flex-row justify-center items-center rounded-xl bg-dark-accent-secondary px-4 py-[10px] text-white mt-6 cursor-default"
                  (click)="activatePlant()"
                >
                  <span class="text-xs p-0">{{
                    "activate_now" | translate
                  }}</span>
                  <fa-icon
                    [icon]="faChevronRight"
                    class="ml-2 text-xs"
                  ></fa-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    } @else {
      <div class="sm:w-[350px] xl:w-[450px] min-w-[300px] h-full flex">
        <div
          class="p-4 m-2 mr-0 w-full flex flex-col flex-grow dark:bg-btTh rounded-2xl"
        >
          <div>
            @if (user && user.user_type === userType.SYSTEM_INTEGRATOR) {
              <div class="hidden space-x-2" (click)="goBack()">
                <img
                  src="../../../../assets/svg/arrow_back.svg"
                  class="rotate-180"
                  alt="Back"
                />
                <span class="text-gray-100 gradient-text">{{
                  "back" | translate
                }}</span>
              </div>
            }
            <span
              class="font-roboto font-medium tracking-normal text-left dark:text-white gradient-header"
              >{{ "general_info" | translate }}
            </span>

            <!-- General info card one -->
            <div class="grid grid-cols-2 mt-2">
              <div>
                <div>
                  <div>
                    <span
                      class="font-roboto label-xs ml-0 font-normal tracking-normal text-left opacity-50 dark:text-white"
                    >
                      {{ "model_name" | translate }}
                    </span>
                  </div>
                  <div>
                    <span
                      class="font-roboto value-2xs font-normal tracking-normal text-left dark:text-white"
                    >
                      {{ statData?.modelName || "NA" }}
                    </span>
                  </div>
                </div>
                @if (statData?.inverterType !== inverterType.GTI) {
                  <div class="mt-2">
                    <div>
                      <span
                        class="font-roboto label-xs ml-0 font-normal tracking-normal text-left opacity-50 dark:text-white"
                      >
                        {{ "operating_mode" | translate }}
                      </span>
                    </div>
                    <div>
                      <span
                        class="font-roboto value-2xs font-normal tracking-normal text-left dark:text-white"
                      >
                        {{ statData?.operatingMode || "NA" }}
                      </span>
                    </div>
                  </div>
                }
              </div>
              <div class="flex justify-end">
                <img
                  [src]="
                    plantData?.plantPhoto ||
                    '../../../../../assets/images/plantdefault.svg'
                  "
                  alt="preview"
                  class="w-28 h-20 xl:w-36 xl:h-28 rounded-lg cursor-pointer"
                  (click)="
                    plantData?.plantPhoto
                      ? previewImage(plantData?.plantPhoto)
                      : null
                  "
                />
              </div>
            </div>
            @if (
              this.statData?.inverterType === inverterType.SOLARVERTERPRO ||
              this.statData?.inverterType === inverterType.HYBRID ||
              this.statData?.inverterType === inverterType.PCU
            ) {
              <!-- General info card two -->
              <div class="grid grid-cols-3 grid-rows-2 mt-4">
                <div>
                  <div>
                    <div>
                      <span
                        class="font-roboto label-xs ml-0 font-normal tracking-normal text-left opacity-50 dark:text-white"
                      >
                        {{ "pv_voltage" | translate }}
                      </span>
                    </div>
                    <div>
                      <span
                        class="font-roboto value-2xs font-normal tracking-normal text-left dark:text-white"
                      >
                        {{ statData?.solarVoltage?.toString() || "0" }} V
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <div>
                      <span
                        class="font-roboto label-xs ml-0 font-normal tracking-normal text-left opacity-50 dark:text-white"
                      >
                        {{ "pv_current" | translate }}
                      </span>
                    </div>
                    <div>
                      <span
                        class="font-roboto value-2xs font-normal tracking-normal text-left dark:text-white"
                      >
                        {{ statData?.stats?.pvCurrent?.toString() || "0" }} A
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <div>
                      <span
                        class="font-roboto label-xs ml-0 font-normal tracking-normal text-left opacity-50 dark:text-white"
                      >
                        {{ "battery_voltage" | translate }}
                      </span>
                    </div>
                    <div>
                      <span
                        class="font-roboto value-2xs font-normal tracking-normal text-left dark:text-white"
                      >
                        {{ statData?.batteryVoltage?.toString() || "0" }} V
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <div class="leading-none">
                      <span
                        class="font-roboto label-xs ml-0 font-normal tracking-normal text-left opacity-50 dark:text-white"
                      >
                        {{ "battery_charging_current" | translate }}
                      </span>
                    </div>
                    <div>
                      <span
                        class="font-roboto value-2xs font-normal tracking-normal text-left dark:text-white"
                      >
                        {{
                          statData?.stats?.charging_current?.toString() || "0"
                        }}
                        A
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <div class="leading-none">
                      <span
                        class="font-roboto label-xs ml-0 font-normal tracking-normal text-left opacity-50 dark:text-white"
                      >
                        {{ "out_put" | translate }} <br />{{
                          "voltage" | translate
                        }}
                      </span>
                    </div>
                    <div>
                      <span
                        class="font-roboto value-2xs font-normal tracking-normal text-left dark:text-white"
                      >
                        {{ statData?.outputVoltage?.toString() || "0" }} V
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <div class="leading-none">
                      <span
                        class="font-roboto label-xs ml-0 font-normal tracking-normal text-left opacity-50 dark:text-white"
                      >
                        {{ "load_percentage" | translate }}(%)
                      </span>
                    </div>
                    <div>
                      <span
                        class="font-roboto value-2xs font-normal tracking-normal text-left dark:text-white"
                      >
                        {{ statData?.stats?.current_running_load_percentage }} %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            } @else if (this.statData?.plantType === platType.NON_SOLAR) {
              <!-- General info card two -->
              <div class="grid grid-cols-2 grid-rows-2 mt-4">
                <div>
                  <div>
                    <div>
                      <span
                        class="font-roboto label-xs ml-0 font-normal tracking-normal text-left opacity-50 dark:text-white"
                      >
                        {{ "battery_voltage" | translate }}
                      </span>
                    </div>
                    <div>
                      <span
                        class="font-roboto value-2xs font-normal tracking-normal text-left dark:text-white"
                      >
                        {{ statData?.batteryVoltage?.toString() || "0" }} V
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <div>
                      <span
                        class="font-roboto label-xs ml-0 font-normal tracking-normal text-left opacity-50 dark:text-white"
                      >
                        {{ "battery_charging_current" | translate }}
                      </span>
                    </div>
                    <div>
                      <span
                        class="font-roboto value-2xs font-normal tracking-normal text-left dark:text-white"
                      >
                        {{
                          statData?.stats?.charging_current?.toString() || "0"
                        }}
                        A
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <div>
                      <span
                        class="font-roboto label-xs ml-0 font-normal tracking-normal text-left opacity-50 dark:text-white"
                      >
                        {{ "output_voltage" | translate }}
                      </span>
                    </div>
                    <div>
                      <span
                        class="font-roboto value-2xs font-normal tracking-normal text-left dark:text-white"
                      >
                        {{ statData?.outputVoltage?.toString() || "0" }} V
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <div class="leading-none">
                      <span
                        class="font-roboto label-xs ml-0 font-normal tracking-normal text-left opacity-50 dark:text-white"
                      >
                        {{ "load_percentage" | translate }}
                      </span>
                    </div>
                    <div>
                      <span
                        class="font-roboto value-2xs font-normal tracking-normal text-left dark:text-white"
                      >
                        {{
                          statData?.stats?.current_running_load_percentage?.toString() ||
                            "0"
                        }}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
          <hr class="w-full opacity-25 my-5" />
          <div>
            @if (statData?.plantType !== platType.NON_SOLAR) {
              <div class="relative">
                <div class="flex justify-between">
                  <span
                    class="gradient-header font-roboto font-medium tracking-normal text-left text-sm dark:text-white"
                  >
                    {{ "weather_forecast" | translate }}
                  </span>
                  <span
                    class="font-roboto text-xs font-normal leading-4 tracking-normal text-left text-dark-accent-secondary cursor-default"
                    (click)="sideSheet.toggle()"
                  >
                    {{ "more_detail" | translate }}
                  </span>
                </div>
                <app-weather-forecast
                  class="absolute top-0 left-0 right-0"
                ></app-weather-forecast>
              </div>
              <!-- Weather forecast info card one -->
              <div
                class="grid grid-cols-10 grid-rows-1 rounded-2xl bg-[#ebf1f5] dark:bg-dark-gray-light px-3 py-[5px] mt-3"
              >
                @if (currentWeather && currentWeather.main) {
                  <span
                    class="flex items-center justify-center col-span-3 text-[16px] dark:text-white"
                  >
                    {{ currentWeather.main.temp }}°C
                  </span>
                }

                <span
                  class="flex justify-center items-center col-span-3 opacity-50 text-xs dark:text-white"
                >
                  {{ "temperature" | translate }}
                </span>
                <span
                  class="flex items-center justify-center opacity-50 text-xs dark:text-white col-span-1"
                >
                  |
                </span>
                @if (currentWeather && currentWeather.weather) {
                  <span
                    class="flex items-center opacity-50 text-xs dark:text-white col-span-2"
                  >
                    {{ currentWeather!.weather[0].description }}
                  </span>
                }

                @if (
                  currentWeather != undefined && currentWeather.iconUrl != null
                ) {
                  <div class="flex items-center justify-end col-span-1">
                    <img
                      [src]="currentWeather!.iconUrl"
                      alt="Weather"
                      class="w-6 h-6"
                    />
                  </div>
                }
              </div>
            }

            @if (
              (statData && statData.inverterType != inverterType.GTI) ||
              (statData && statData.plantType == platType.NON_SOLAR)
            ) {
              <!-- Insights-->
              <div class="mt-3">
                <span
                  class="gradient-header font-roboto font-medium tracking-normal text-left underline text-sm sp dark:text-white"
                  >{{ "insights" | translate }}
                </span>

                <div
                  class="grid grid-cols-5 dark:bg-dark-gray-light mt-4 rounded-lg p-2"
                >
                  <div class="col-span-1 justify-self-center">
                    <div
                      class="bg-[#ebf8ff] dark:bg-black rounded-full w-9 h-9 flex justify-center items-center"
                    >
                      <img
                        src="../../../../../assets/svg/energy_freedom.svg"
                        alt="insight"
                        class="w-4 h-4"
                      />
                    </div>
                  </div>

                  <div class="col-span-4">
                    <div class="leading-none">
                      @if (statData.plantType === platType.SOLAR) {
                        <span class="text-xs dark:text-white">
                          {{ "energy_freedom" | translate }} <br />
                          {{ statData.solarPecentInConsumption || 0 }}%
                          {{ "self_powered" | translate }}
                        </span>
                        <br />
                        <span
                          class="text-[10px] opacity-50 dark:text-white leading-none"
                        >
                          Yesterday,
                          {{ statData.solarPecentInConsumption || 0 }}% of your
                          home’s power was provided by clean, green solar
                          energy, making a positive impact on the environment.
                        </span>
                      } @else {
                        <span class="text-xs dark:text-white">
                          {{ "Power Backup" | translate }}
                        </span>
                        <br />
                        <span
                          class="text-[10px] opacity-50 dark:text-white leading-none"
                        >
                          Yesterday, your system had provided
                          {{ statData.totalPowerBackup || 0 }}
                          power backup
                        </span>
                      }
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      <div class="flex flex-col flex-grow h-full">
        <div class="h-[10%]">
          <div class="flex justify-between items-center mx-2 rounded-md p-2">
            <div class="flex flex-col justify-start items-start text-lg">
              <div class="flex items-center">
                <span class="plant-name gradient-header font-medium">{{
                  selectedPlant?.plantName
                }}</span>
                @if (!isDeviceOffline) {
                  <img
                    src="../../../../../assets/svg/online.svg"
                    alt="Online"
                    class="ps-2"
                  />
                  @if (statData?.stats?.wifi_signal_strength) {
                    <img
                      [src]="
                        '../../../../../assets/svg/' +
                        getStrengthImage(
                          statData?.stats?.wifi_signal_strength
                        ) +
                        '.svg'
                      "
                      class="h-[15px] mx-2"
                      alt="strength"
                    />
                  }
                } @else {
                  <img
                    src="../../../../../assets/svg/offline.svg"
                    alt="Offline"
                    class="ps-2"
                  />
                  <fa-icon
                    [icon]="faWifi"
                    class="ms-3 text-[#E6E4E4] text-sm"
                  ></fa-icon>
                }
              </div>
              @if (statData) {
                <div>
                  <span class="text-xs dark:text-white">{{
                    statData.deviceId
                  }}</span>

                  <span class="text-xs text-[#7F7F7F] px-1">
                    | &nbsp;Last Updated:
                    {{
                      statData.stats!.last_updated_timestamp
                        ? (statData.stats!.last_updated_timestamp * 1000
                          | date: "dd/MM/yyyy hh:mm:ss a")
                        : "NA"
                    }}
                  </span>
                </div>
              }
            </div>
            <div class="flex items-center py-1 space-x-2">
              <div
                class="hidden dark:bg-none px-3 py-[5px] dark:text-white rounded-xl flex items-center space-x-2 cursor-pointer"
              >
                <a (click)="navigateToSolarRoofTopDetail(plantData)">
                  <span class="gradient-text underline">
                    {{ "Solar Rooftop view" | translate }}</span
                  >
                </a>

                <img
                  src="../../../../../assets/svg/roof_top.svg"
                  alt="arrow"
                  class="w-5"
                />
              </div>

              <div
                class="dark:bg-none px-3 py-[8px] dark:text-white rounded-xl border-solid border-[1px] border-gray-300 flex items-center space-x-2 cursor-pointer"
                (click)="navigateToPlantDetail()"
              >
                <span class="gradient-text">
                  {{ "plant_info" | translate }}</span
                >
                <img
                  src="../../../../../assets/svg/arrow_forward.svg"
                  alt="arrow"
                />
              </div>
              <div
                class="relative px-3 py-[5px] text-black dark:text-white rounded-xl border-solid border-[1px] border-gray-300 flex items-center space-x-2 cursor-pointer"
                (click)="statData ? toggleNotificationDropdown() : null"
                onClickOutside
                (onClickOutside)="clickOutside('NOTIFICATION')"
              >
                <span class="gradient-text">
                  {{ "notifications" | translate }}
                </span>
                <div class="relative">
                  <img
                    src="../../../../../assets/svg/cus_notification.svg"
                    alt="arrow"
                    class="w-5 h-5"
                  />
                  @if (
                    statData && notificationList && notificationList.length > 0
                  ) {
                    <div
                      class="absolute bg-white flex flex-col justify-center items-center rounded-full"
                      [ngClass]="
                        notificationList.length < 10
                          ? 'w-3 h-3 top-[-2px] -right-1'
                          : 'h-[18px] w-[18px] top-[-6px] -right-2'
                      "
                    >
                      <span class="text-black text-[8px] m-0">{{
                        notificationList.length < 10
                          ? notificationList.length
                          : "10+"
                      }}</span>
                    </div>
                  }
                </div>

                <div
                  class="absolute min-w-72 z-20 top-8 end-0 shadow-lg"
                  *ngIf="notificationDropdownVisible"
                  id="dropdown"
                >
                  <!-- Dropdown content -->
                  <div
                    class="flex flex-col mt-2 rounded-lg bg-slateGrey shadow-2xl"
                  >
                    <div
                      class="flex flex-row justify-start items-start space-x-2 ms-2 px-2 py-4"
                    >
                      <img
                        src="../../../../../assets/svg/cus_notification.svg"
                        alt="Notification"
                      />
                      <span class="ps-4"> Notifications </span>
                    </div>
                    <div class="max-h-96 overflow-y-auto max-auto-scroll">
                      @for (item of notificationList; track $index) {
                        <div
                          class="flex flex-row justify-start items-start hover:bg-[#E2F1FC] hover:bg-opacity-10 hover: rounded-md py-2 px-2"
                          (click)="$event.stopPropagation()"
                        >
                          @if (item.data.alertName == "Info Alert") {
                            <img
                              src="../../../../../assets/svg/notification_info.svg"
                              alt="arrow"
                              class="w-8 h-8"
                            />
                          } @else if (
                            item.data.alertName != "Info Alert" &&
                            item.data.blocking == false
                          ) {
                            <img
                              src="../../../../../assets/svg/notification_warning.svg"
                              alt="arrow"
                              class="w-8 h-8"
                            />
                          } @else if (
                            item.data.alertName != "Info Alert" &&
                            item.data.blocking == true
                          ) {
                            <img
                              src="../../../../../assets/svg/notification_alert.svg"
                              alt="arrow"
                              class="w-8 h-8"
                            />
                          }

                          <div class="flex flex-col leading-4 ms-4">
                            <span class="text-xs">{{ item.data.title }}</span>
                            <span class="text-[10px] opacity-50">{{
                              item.data.body
                            }}</span>
                            <span class="text-[10px] opacity-50">{{
                              item.notificationDate
                            }}</span>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        @if (statData === null) {
          <div
            class="flex flex-col flex-grow justify-start items-center bg-white dark:bg-btTh md:mt-0 rounded-2xl m-2 sm:row-span-1 lg:col-span-1"
          >
            <div
              class="flex flex-col justify-center items-center flex-grow gap-y-9 dark:text-white"
            >
              <span class="label-xl"
                >Welcome to {{ selectedPlant?.plantName }} Plant</span
              >
              <img
                src="../../../../../assets/svg/no-stats.png"
                class="max-w-72 sm:w-40 md:w-52 lg:w-64"
                alt="icon"
              />
              <span class="label-lg max-w-80 text-center">
                It will take a while to sync data. Utilize the time to complete
                your profile and plant details.
              </span>
              <div
                class="flex flex-row justify-center items-center rounded-xl bg-dark-accent-secondary px-4 py-[10px] text-white mt-1 cursor-default"
                (click)="navigateToPlantDetail()"
              >
                <span class="text-xs p-0">{{ "plant_info" | translate }}</span>
                <fa-icon [icon]="faChevronRight" class="ml-2 text-xs"></fa-icon>
              </div>
            </div>
          </div>
        } @else if (statData) {
          @if (statData.plantType !== platType.NON_SOLAR) {
            <div class="h-[10%] w-full content-center">
              <!-- Top header -->
              <div
                class="grid grid-rows-1 grid-cols-4 gap-x-4 justify-between mx-3"
              >
                <div
                  class="flex flex-row justify-start items-center bg-white dark:bg-btTh h-10 xl:h-20 md:mt-0 px-4 rounded-2xl p-2 sm:row-span-1 lg:col-span-1"
                >
                  <div class="rounded-lg w-9 h-9">
                    <img
                      src="../../../../../assets/svg/solar.svg"
                      class="w-8 h-8"
                      alt="icon"
                    />
                  </div>
                  <div class="ml-3 leading-none">
                    <span class="label-xs ml-0 opacity-50 dark:text-white">{{
                      "solar_pv_installed" | translate
                    }}</span>
                    <br />
                    <span class="value-xs dark:text-white">{{
                      statData.solarPVInstalled ?? "NA"
                    }}</span>
                  </div>
                </div>
                <div
                  class="flex flex-row justify-start items-center bg-white dark:bg-btTh h-10 xl:h-20 md:mt-0 px-4 rounded-2xl p-2 sm:row-span-1 lg:col-span-1"
                >
                  <div class="rounded-lg w-[50px] h-[50px] mb-5">
                    <ng-lottie [options]="options[0]"></ng-lottie>
                  </div>
                  <div class="ml-3 leading-none">
                    <span class="label-xs ml-0 opacity-50 dark:text-white">{{
                      "savings" | translate
                    }}</span>
                    <br />
                    <span class="value-xs dark:text-white">
                      {{
                        statData.totalSavings
                          ? (statData.currencySymbol ?? "₹") +
                            " " +
                            statData.totalSavings
                          : "NA"
                      }}</span
                    >
                  </div>
                </div>
                <div
                  class="flex flex-row justify-start items-center bg-white dark:bg-btTh h-10 xl:h-20 md:mt-0 px-4 rounded-2xl p-2 sm:row-span-1 lg:col-span-1"
                >
                  <div class="rounded-lg w-8 h-8">
                    <ng-lottie [options]="options[1]"></ng-lottie>
                  </div>
                  <div class="ml-3 leading-none">
                    <span class="label-xs ml-0 opacity-50 dark:text-white">{{
                      "return_on_investment" | translate
                    }}</span>
                    <br />
                    <span class="value-xs dark:text-white"
                      >{{ statData.roi ? statData.roi + "%" : "NA" }}
                    </span>
                  </div>
                </div>
                <div
                  class="flex flex-row justify-start items-center bg-white dark:bg-btTh h-10 xl:h-20 md:mt-0 px-4 rounded-2xl p-2 sm:row-span-1 lg:col-span-1"
                >
                  <div class="rounded-lg w-9 h-9">
                    <img
                      src="../../../../../assets/gif/pie.gif"
                      class="w-9 h-9"
                      alt="Pie chart"
                    />
                  </div>
                  <div class="ml-3 leading-none">
                    <span class="label-xs ml-0 opacity-50 dark:text-white">{{
                      "plant_online_ratio" | translate
                    }}</span>
                    <br />
                    @if (
                      plantRatioData?.daysOnline && plantRatioData?.totalDays
                    ) {
                      <span class="value-xs dark:text-white">{{
                        plantRatioData!.daysOnline +
                          "/" +
                          plantRatioData!.totalDays
                      }}</span>
                      <span class="text-[10px] ml-1 dark:text-white">Days</span>
                    } @else {
                      <span class="value-xs ml-3 dark:text-white">NA</span>
                    }
                  </div>
                </div>
              </div>
            </div>
          }

          <!-- Energy flow and trends -->
          <div class="h-[46%] w-full">
            <div class="flex flex-row h-full w-full">
              <!-- Preview GTI flow -->
              <div class="min-w-[300px] w-[30%] aspect-square relative mt-1">
                <app-energy-flow
                  [statData]="statData"
                  class="h-full"
                ></app-energy-flow>
              </div>
              <!-- Trends -->
              <div
                class="bg-white dark:bg-btTh ml-0 rounded-2xl w-[70%] mx-3 mt-1"
              >
                <app-trends
                  [plantId]="selectedPlantId"
                  [mappingProtocol]="statData.stats.mappingProtocol"
                  [plantType]="statData.plantType"
                ></app-trends>
              </div>
            </div>
          </div>

          <!-- Solar summary and environment benifits -->
          @if (statData.plantType !== platType.NON_SOLAR) {
            <div class="h-[34%]">
              <div class="flex flex-col md:flex-row h-full">
                <div class="flex flex-grow m-2">
                  @if (statData.plantType !== platType.NON_SOLAR) {
                    <!-- Solar summary -->
                    <div
                      class="flex flex-col flex-grow bg-white dark:bg-btTh md:mt-0 rounded-2xl p-4 md:w-[60%]"
                    >
                      <div class="flex items-center">
                        <span
                          class="gradient-header font-roboto font-medium tracking-normal text-left underline dark:text-white"
                          >{{ "solar_summary" | translate }}
                        </span>
                        <img
                          src="../../../../../assets/svg/solar-sun.svg"
                          class="ml-2 h-5 xl:h7"
                          alt="Sun"
                        />
                      </div>
                      @if (statData.plantType != platType.NON_SOLAR) {
                        <div
                          class="grid grid-cols-4 grid-rows-2 w-full gap-y-5 mt-5"
                        >
                          <div
                            class="col-span-1 leading-tight flex flex-col gap-y-[6px]"
                          >
                            <div>
                              <span
                                class="font-roboto label-xs ml-0 font-normal tracking-normal text-left opacity-50 dark:text-white"
                              >
                                {{ "today" | translate }} <br />
                              </span>
                            </div>
                            <div>
                              <span
                                class="font-roboto value-2xs font-normal tracking-normal text-left dark:text-white"
                              >
                                {{
                                  statData.stats.generation
                                    ? statData.stats.generation + " kWh"
                                    : "NA"
                                }}
                              </span>
                            </div>
                          </div>

                          @if (statData.inverterType === inverterType.GTI) {
                            <div
                              class="col-span-1 leading-tight flex flex-col gap-y-[6px]"
                            >
                              <div>
                                <span
                                  class="font-roboto label-xs ml-0 font-normal tracking-normal text-left opacity-50 dark:text-white"
                                >
                                  {{ "This Month" | translate }} <br />
                                </span>
                              </div>
                              <div>
                                <span
                                  class="font-roboto value-2xs font-normal tracking-normal text-left dark:text-white"
                                >
                                  {{ statData.stats.energyThisMonth || "NA" }}
                                </span>
                              </div>
                            </div>
                          }
                          @if (statData.inverterType === inverterType.GTI) {
                            <div
                              class="col-span-1 leading-tight flex flex-col gap-y-[6px]"
                            >
                              <div>
                                <span
                                  class="font-roboto label-xs ml-0 font-normal tracking-normal text-left opacity-50 dark:text-white"
                                >
                                  {{ "This Year" | translate }} <br />
                                </span>
                              </div>
                              <div>
                                <span
                                  class="font-roboto value-2xs font-normal tracking-normal text-left dark:text-white"
                                >
                                  {{ statData.stats.energyThisYear || "NA" }}
                                </span>
                              </div>
                            </div>
                          }

                          @if (
                            statData.inverterType === inverterType.GTI ||
                            statData.inverterType === inverterType.HYBRID ||
                            statData.inverterType ===
                              inverterType.SOLARVERTERPRO ||
                            statData.inverterType === inverterType.PCU
                          ) {
                            <div
                              class="col-span-1 leading-tight flex flex-col gap-y-[6px]"
                            >
                              <div>
                                <span
                                  class="font-roboto label-xs ml-0 font-normal tracking-normal text-left opacity-50 dark:text-white"
                                >
                                  {{ "Lifetime" | translate }} <br />
                                </span>
                              </div>
                              <div>
                                <span
                                  class="font-roboto value-2xs font-normal tracking-normal text-left dark:text-white"
                                >
                                  {{ statData.lifetimeGeneration || "NA" }}
                                </span>
                              </div>
                            </div>
                          }
                          @if (statData.inverterType === inverterType.GTI) {
                            <div
                              class="col-span-1 leading-tight flex flex-col gap-y-[6px]"
                            >
                              <div>
                                <span
                                  class="font-roboto label-xs ml-0 font-normal tracking-normal text-left opacity-50 dark:text-white"
                                >
                                  {{ "Yesterday" | translate }} <br />
                                </span>
                              </div>
                              <div>
                                <span
                                  class="font-roboto value-2xs font-normal tracking-normal text-left dark:text-white"
                                >
                                  {{ statData.stats.energyYesterday || "NA" }}
                                </span>
                              </div>
                            </div>
                          }
                          @if (statData.inverterType === inverterType.GTI) {
                            <div
                              class="col-span-1 leading-tight flex flex-col gap-y-[6px]"
                            >
                              <div>
                                <span
                                  class="font-roboto label-xs ml-0 font-normal tracking-normal text-left opacity-50 dark:text-white"
                                >
                                  {{ "Last Month" | translate }} <br />
                                </span>
                              </div>
                              <div>
                                <span
                                  class="font-roboto value-2xs font-normal tracking-normal text-left dark:text-white"
                                >
                                  {{ statData.stats.energyLastMonth || "NA" }}
                                </span>
                              </div>
                            </div>
                          }

                          @if (statData.inverterType === inverterType.GTI) {
                            <div
                              class="col-span-1 leading-tight flex flex-col gap-y-[6px]"
                            >
                              <div>
                                <span
                                  class="font-roboto label-xs ml-0 font-normal tracking-normal text-left opacity-50 dark:text-white"
                                >
                                  {{ "last_year" | translate }} <br />
                                </span>
                              </div>
                              <div>
                                <span
                                  class="font-roboto value-2xs font-normal tracking-normal text-left dark:text-white"
                                >
                                  {{ statData.stats.energyLastYear || "NA" }}
                                </span>
                              </div>
                            </div>
                          }
                        </div>
                      }
                    </div>
                  }

                  @if (statData.plantType !== inverterType.NON_SOLAR) {
                    <!-- Environment Benefits -->
                    <div
                      class="flex flex-col bg-white dark:bg-btTh ml-3 rounded-2xl p-3 w-[40%]"
                    >
                      <div>
                        <span
                          class="gradient-header font-roboto font-medium tracking-normal text-left underline sp dark:text-white"
                          >{{ "environment_benefits" | translate }}
                        </span>
                      </div>
                      <div class="grid grid-cols-3 grid-rows-1 w-full py-2">
                        <div class="flex flex-col col-span-2 space-y-4">
                          <div class="flex space-x-4">
                            <img
                              src="assets/svg/first.svg"
                              class="w-10"
                              alt="Tree"
                              alt="tree"
                            />
                            <div class="leading-none">
                              <span class="value-xs dark:text-white">{{
                                statData.equivalentTreesPlanted
                              }}</span>
                              <br />
                              <span
                                class="label-xs ml-0 opacity-50 dark:text-white"
                                >{{
                                  "equivalent_trees_planted" | translate
                                }}</span
                              >
                            </div>
                          </div>
                          <div class="flex space-x-4">
                            <img
                              src="assets/svg/second.svg"
                              class="w-10"
                              alt="Tree"
                              alt="tree"
                            />
                            <div class="leading-none">
                              <span class="value-xs dark:text-white">{{
                                statData.co2EmissionSaved
                              }}</span>
                              <br />
                              <span
                                class="label-xs ml-0 opacity-50 dark:text-white"
                                >{{ "co2_emission_saved" | translate }}
                              </span>
                            </div>
                          </div>
                          <div class="flex space-x-4">
                            <img
                              src="assets/svg/last.svg"
                              class="w-10"
                              alt="Tree"
                              alt="tree"
                            />
                            <div class="leading-none">
                              <span class="value-xs dark:text-white"
                                >{{ statData.coalNotBurned }}
                              </span>
                              <br />
                              <span
                                class="label-xs ml-0 opacity-50 dark:text-white"
                                >{{ "call_not_burned" | translate }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-span-1">
                          <img
                            src="../../../../../assets/svg/earth.svg"
                            class="size-20"
                            alt="environment"
                          />
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          }

          @if (
            statData.plantType === platType.NON_SOLAR &&
            statData.stats.mappingProtocol != "BITWISE"
          ) {
            <div class="flex flex-col md:flex-row">
              <div class="flex flex-grow m-2">
                <div
                  class="flex flex-col flex-grow bg-white dark:bg-btTh mt-3 md:mt-0 rounded-2xl p-3 md:w-4/5"
                >
                  <div>
                    <span
                      class="gradient-header font-roboto font-medium tracking-normal text-left underline sp dark:text-white"
                      >{{ "consumption_summary" | translate }}
                    </span>
                  </div>
                  <div class="grid grid-cols-4 grid-rows-1 w-full">
                    <div class="col-span-1 p-4 leading-tight">
                      <div>
                        <span
                          class="font-roboto label-xs ml-0 font-normal tracking-normal text-left opacity-50 dark:text-white"
                        >
                          {{ "Today's Consumption" | translate }}
                        </span>
                      </div>
                    </div>

                    <div class="col-span-1 p-4 leading-tight">
                      <div>
                        <span
                          class="font-roboto value-2xs font-normal tracking-normal text-left dark:text-white"
                        >
                          {{ statData.todayConsumption || "NA" }}
                        </span>
                      </div>
                    </div>
                    <div class="col-span-1 p-4 leading-tight">
                      <div>
                        <span
                          class="font-roboto label-xs ml-0 font-normal tracking-normal text-left opacity-50 dark:text-white"
                        >
                          {{ "Accumulated Consumption" | translate }} <br />
                        </span>
                      </div>
                    </div>
                    <div class="col-span-1 p-4 leading-tight">
                      <div>
                        <span
                          class="font-roboto value-2xs font-normal tracking-normal text-left dark:text-white"
                        >
                          {{ statData.lifetimeConsumption || "NA" }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        }
      </div>
    }
  </div>
  <!-- New code end-->
</div>
