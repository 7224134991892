import moment from 'moment';

export class DateUtils {
  static getLastDay(year?: number, month?: number): Date {
    if (year && month) {
      let firstDayOfNextMonth = new Date(year, month, 1); // Month is 0-based
      let lastDayOfMonth = new Date(firstDayOfNextMonth);
      lastDayOfMonth.setDate(firstDayOfNextMonth.getDate() - 1);
      return lastDayOfMonth;
    } else if (year) {
      var theLast = new Date(year, 11, 31);
      return theLast;
    }
    return new Date();
  }
  static getFirstDay(year?: number, month?: number): Date {
    if (year && month) {
      // Create a date object for the first day of the month
      let firstDayOfMonth = new Date(year, month - 1, 1); // Month is 0-based
      // Create a date object for the first day of the next month
      return firstDayOfMonth;
    } else if (year) {
      var theFirst = new Date(year, 0, 1);
      return theFirst;
    }
    return new Date();
  }
  static getTimestampsForHours(hour: number): number {
    const today: Date = new Date();
    return new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      hour,
    ).getTime();
  }
  static formatTimeFromLocalEpoch(timestamp: number) {
    const date = new Date(timestamp);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let amPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours || 12; // the hour '0' should be '12'
    let minuteInString = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + minuteInString + ' ' + amPm;

    return strTime;
  }
  static formatDateFromLocalEpoch(
    timestamp: number,
    format: string = 'DD/MM/YYYY',
  ): string {
    const date = new Date(timestamp);
    let formatDate = moment(date).format(format);
    return formatDate || '';
  }
  static getMonthName(month: number): string {
    switch (month) {
      case 1:
        return 'Jan';
      case 2:
        return 'Feb';
      case 3:
        return 'Mar';
      case 4:
        return 'Apr';
      case 5:
        return 'May';
      case 6:
        return 'Jun';
      case 7:
        return 'Jul';
      case 8:
        return 'Aug';
      case 9:
        return 'Sept';
      case 10:
        return 'Oct';
      case 11:
        return 'Nov';
      case 12:
        return 'Dec';
      default:
        return '';
    }
  }
  static getStartDayTimeStampFromDate(date: Date): number {
    date.setHours(0, 0, 0, 0);
    return date.getTime() / 1000;
  }
  static calculateHoursDifference(
    currentTime: number,
    responseTime: number,
  ): number {
    return Math.floor((currentTime - responseTime) / (1000 * 60 * 60));
  }
}
export interface INoxMonth {
  year: number;
  months: INoxMonths[];
}
export interface INoxMonths {
  num: number;
  name: string;
}

export interface INoxYear {
  year: number;
}

// Define a class that implements the interface
export class NoxMonth implements INoxMonth {
  year: number;
  months: INoxMonths[];

  constructor(year: number, months: INoxMonths[]) {
    this.year = year;
    this.months = months;
  }

  static noxMonthsData(selectYear?: number, isForward?: boolean): NoxMonth {
    const currentYear = new Date().getFullYear();
    let totalMonth: number = 12;
    totalMonth = new Date().getMonth() + 1;

    return new NoxMonth(currentYear, noxMonths.slice(0, totalMonth) || []);
  }
}

export const noxMonths: INoxMonths[] = [
  { num: 1, name: 'Jan' },
  { num: 2, name: 'Feb' },
  { num: 3, name: 'Mar' },
  { num: 4, name: 'Apr' },
  { num: 5, name: 'May' },
  { num: 6, name: 'Jun' },
  { num: 7, name: 'Jul' },
  { num: 8, name: 'Aug' },
  { num: 9, name: 'Sept' },
  { num: 10, name: 'Oct' },
  { num: 11, name: 'Nov' },
  { num: 12, name: 'Dec' },
];
export class NoxYear implements INoxYear {
  year: number;

  constructor(num: number) {
    this.year = num;
  }

  static yearList(offsetYear?: number, isForward?: boolean): NoxYear[] {
    let currentYear = new Date().getFullYear();
    if (offsetYear) {
      currentYear = offsetYear;
    }

    let twelveYears: NoxYear[] = [];

    for (let i = 0; i < 12; i++) {
      if (isForward) {
        twelveYears.push({ year: currentYear + 1 });
      } else {
        twelveYears.push({ year: currentYear - i });
      }
    }

    return twelveYears.reverse();
  }
}
export function getMonthNameByMonth(number: number) {
  let month = '';
  switch (number) {
    case 1:
      month = 'Jan';
      break;
    case 2:
      month = 'Fab';
      break;
    case 3:
      month = 'Mar';
      break;
    case 4:
      month = 'Apr';
      break;
    case 5:
      month = 'May';
      break;
    case 6:
      month = 'Jun';
      break;
    case 7:
      month = 'Jul';
      break;
    case 8:
      month = 'Aug';
      break;
    case 9:
      month = 'Sept';
      break;
    case 10:
      month = 'Oct';
      break;
    case 11:
      month = 'Nov';
      break;
    case 12:
      month = 'Dec';
      break;
    default:
      break;
  }
  return month;
}
