import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-header-tab',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './header-tab.component.html',
  styleUrl: './header-tab.component.scss',
})
export class HeaderTabComponent {
  @Input() tabs: string[] = [];
  @Input() activeTab: number = 0;
  @Input() isMainTab: boolean = false;
  @Output() tabChange = new EventEmitter<number>();

  selectTab(index: number) {
    this.activeTab = index;
    this.tabChange.emit(index);
  }

  getTabClass(index: number): string {
    if (this.isMainTab) {
      return this.activeTab === index
        ? 'bg-blue-600 text-white rounded-full'
        : ' text-white rounded-full';
    } else {
      return this.activeTab === index
        ? 'border-b-2 border-blue-500 text-blue-500 rounded-full'
        : 'text-white  rounded-full';
    }
  }
}
