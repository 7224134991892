import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ProductService } from '../../../shared/service/product.service';
import { ExploreData } from '../../../shared/models/explore';
import { CommonModule } from '@angular/common';
import { Subject, Subscription } from 'rxjs';
import { BaseComponent } from '../../../base/components/base.component';
import { Router, RouterModule } from '@angular/router';
import { SessionService } from '../../../shared/service/session.service';

@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss',
})
export class AboutUsComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  productService = inject(ProductService);
  exploreList: ExploreData[] = [];
  exploreSubscription?: Subscription;

  sessionService: SessionService = inject(SessionService);
  data = [
    {
      icon: 'assets/images/customer_screens/rectangleimage.svg',
      Title: ' Latest News',
      Description:
        'In publishing and graphic design, Lorem ipsum is a placeholder',
      LastUpdated: '15 minutes ago',
    },
    {
      icon: 'assets/images/customer_screens/rectangleimage.svg',
      Title: ' Latest News',
      Description:
        'In publishing and graphic design, Lorem ipsum is a placeholder',
      LastUpdated: '15 minutes ago',
    },
    {
      icon: 'assets/images/customer_screens/rectangleimage.svg',
      Title: ' Latest News',
      Description:
        'In publishing and graphic design, Lorem ipsum is a placeholder',
      LastUpdated: '15 minutes ago',
    },
  ];
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.sessionService.startBnWatch(this.router);
    this.resolveAllSubscriptions([this.fetchAboutUs()]);
  }
  fetchAboutUs() {
    let returnSubject = new Subject<boolean>();
    this.exploreSubscription = this.productService
      .fetchExplorePost()
      .subscribe({
        next: (response) => {
          if (response?.status === 200) {
            this.exploreList = response?.data;
          }
        },
        complete: () => {
          returnSubject.next(true);
          returnSubject.complete();
        },
      });
    return returnSubject;
  }

  ngOnDestroy(): void {
    this.exploreSubscription?.unsubscribe();
    this.sessionService.stopBnTimer();
  }

  openLink(item: ExploreData) {
    window.open(item.url, '_blank');
  }
}
