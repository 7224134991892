<div
  class="relative flex flex-grow items-center justify-center h-full m-auto dark-gradient-bg"
>
  <div
    class="flex flex-col flex-grow justify-start items-start bg-[#1d232b] rounded-lg absolute top-0 bottom-0 left-0 right-0 mx-5 my-5"
  >
    <div class="flex flex-row h-full">
      <div class="flex flex-col first-part pt-16 pl-16">
        <div class="flex flex-row">
          <h2 class="text-lg font-medium gradient-header underline">
            About us
          </h2>
        </div>

        <p class="text-sm w-11/12 font-normal mt-64 text-white opacity-50">
          Luminous Power Technologies is a leading provider of solar solutions
          in India. We have been in the solar business since 2008 and have
          installed over 1 million solar systems in India. <br />
          <br />
          <br />
          We offer a wide range of solar products, including solar panels,
          inverters, batteries, and mounting structures. We also have a team of
          experienced solar engineers who can help customers design and install
          a solar system that meets their specific needs. <br />
          <br />
          <br />
          We are excited to offer our customers the Luminous solar mobile app.
          This app will allow customers to track their solar generation,
          consumption, power cuts, savings, and more.We believe that the
          Luminous solar mobile app will be a valuable tool for our customers
          and will help them save money, reduce their carbon footprint, and live
          more sustainably.
        </p>

        <div class="flex flex-col mt-10">
          <div class="text-white opacity-50">
            <span>Connect with us &#58;</span>
          </div>
          <div class="flex flex-row mt-3 justify-around w-[130px]">
            <a href="https://www.facebook.com/myluminous/" target="_blank">
              <img
                src="../../../../assets/images/customer_screens/facebook.svg"
                alt="Facebook"
              />
            </a>
            <a
              href="https://www.instagram.com/luminous_officials"
              target="_blank"
            >
              <img
                src="../../../../assets/images/customer_screens/insta.svg"
                alt="Instagram"
              />
            </a>
            <a href="https://twitter.com/myluminous" target="_blank">
              <img
                src="../../../../assets/images/customer_screens/twitter 1.svg"
                alt=" Twitter "
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCw6k_Tm3O0MI23ah2SBYB3Q"
              target="_blank"
            >
              <img
                src="../../../../assets/images/customer_screens/youtube.svg"
                alt="Youtube"
              />
            </a>
          </div>
        </div>
      </div>

      <div class="flex flex-col second-part p-5 text-white w-[350px]">
        <div class="text-lg font-semibold text-dark-font-heading">
          <h2>Blogs</h2>
        </div>
        @if (exploreList.length > 0) {
          <div class="w-full cursor-pointer" (click)="openLink(exploreList[0])">
            <div class="w-full">
              <img
                class="rounded-2xl w-full h-32 object-cover"
                [src]="exploreList[0].image"
                alt="Post"
              />
            </div>
            <div class="mt-2.5">
              <p
                class="text-white font-semibold text-sm text-wrap p-2 line-clamp-2"
              >
                {{ exploreList[0].title }}
              </p>
            </div>
            <div
              class="flex flex-row justify-between w-36 ml-2 text-sm opacity-50"
            >
              <div>
                <span class="text-[10px] text-dark-content opacity-50">{{
                  exploreList[0].date * 1000 | date: "dd/MM/yyyy hh:mm:ss a"
                }}</span>
              </div>
              <div class="h-7 w-[0.1%] bg-white"></div>
              <div class="hidden"><span>New</span></div>
            </div>
          </div>
        }

        <div class="grid gap-1 mt-3 w-[350px] overflow-y-scroll">
          @for (item of exploreList; track $index) {
            <div
              class="flex flex-row card items-stretch p-2.5 rounded-3xl text-xs cursor-pointer"
              (click)="openLink(item)"
            >
              <div>
                <img
                  src="{{ item.image }}"
                  alt="post"
                  class="w-10 h-10 rounded-lg"
                />
              </div>
              <div class="flex flex-col ml-3.5">
                <div class="text-xs font-semibold">
                  <span>{{ item.title }}</span>
                </div>
                <div class="mt-1">
                  <span
                    class="text-[10px] text-dark-content opacity-50 line-clamp-2"
                    >{{ item.description }}</span
                  >
                </div>
                <div class="mt-2">
                  <span class="text-[10px] text-dark-content opacity-50">{{
                    item.date * 1000 | date: "dd/MM/yyyy hh:mm:ss a"
                  }}</span>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>
