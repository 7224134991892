<div
  class="relative flex flex-grow items-center justify-center h-full m-auto dark-gradient-bg"
>
  <div
    class="flex flex-col flex-grow justify-start items-start bg-[#1d232b] rounded-lg absolute top-0 bottom-0 left-0 right-0 mx-5 my-5"
  >
    <div class="flex items-center mt-10 cursor-pointer" (click)="goBack()">
      <img
        class="ml-10"
        src="../../../../assets/images/customer_screens/back arrow.svg"
        alt="Back"
      />
      <span class="text-white ml-4">Back</span>
    </div>
    <div class="flex flex-col items-center w-full h-full">
      <div>
        <h2 class="text-dark-font-heading font-extrabold gradient-header">
          User profile
        </h2>
      </div>
      <div
        class="w-full flex flex-col flex-grow justify-evenly items-center mb-10"
      >
        <div>
          <img
            [src]="
              userData?.user_image
                ? userData?.user_image
                : '../../../../assets/svg/default-user.svg'
            "
            alt="Avator"
            class="h-28 w-28 rounded-full"
            (click)="
              userData?.user_image ? previewImage(userData?.user_image) : null
            "
          />
          <div class="flex flex-row space-x-2 my-2 justify-center items-center">
            <span class="text-white text-[15px]">{{
              userData?.name || "NA"
            }}</span>
            <img
              src="../../../../assets/svg/upload-fota.svg"
              alt="Upload"
              class="hidden"
            />
          </div>
        </div>
        <div class="w-full flex flex-row justify-around">
          <div class="flex flex-row space-x-3">
            <img
              src="../../../../assets/svg/location_gray.svg"
              alt="User"
              class="w-12 h-12"
            />
            <div class="flex flex-col justify-center items-start">
              <span class="gradient-header text-[15px]">Name</span>
              <span class="text-white text-[15px] font-normal">{{
                userData?.name || "NA"
              }}</span>
            </div>
          </div>
          <div class="flex flex-row space-x-3">
            <img
              src="../../../../assets/svg/phone_gray.svg"
              alt="Phone"
              class="w-12 h-12"
            />
            <div class="flex flex-col justify-center items-start">
              <span class="gradient-header text-[15px]">Mobile Number</span>
              <span class="text-white text-[15px] font-normal">{{
                userData?.phone || "NA"
              }}</span>
            </div>
          </div>
          <div class="flex flex-row space-x-3">
            <img
              src="../../../../assets/svg/email_gray.svg"
              alt="Email"
              class="w-12 h-12"
            />
            <div class="flex flex-col justify-center items-start">
              <span class="gradient-header text-[15px]">E-mail</span>
              <span class="text-white text-[15px] font-normal">{{
                userData?.email || "NA"
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
