import { Injectable } from '@angular/core';
import { BaseUserService } from '../../base/services/base-user.service';
import { HttpResponse } from '@angular/common/http';
import { Observable, delay, of } from 'rxjs';
import { User } from '../../shared/models/user';
import { getPlant } from '../../shared/models/plant-detail';
import { DeletePlantResponse } from '../../shared/models/delete-plant';
import { DeletePlantHistory } from '../../shared/models/delete-plant-history';
@Injectable({
  providedIn: 'root',
})
export class SeService extends BaseUserService {
  getPlantDetails(inverter: string): Observable<getPlant> {
    return this.http.get<getPlant>(
      this.env.externalUrls.system_integration.baseUrl +
        '/getPlantByInverterSerialNo?inverterSerialNo=' +
        inverter,
    );
  }

  deletePlant(
    getInverterDetails: string,

    comment: string,
    xUserId: string,
  ): Observable<DeletePlantResponse> {
    return this.http.delete<DeletePlantResponse>(
      this.env.externalUrls.system_integration.baseUrl +
        `/deletePlantAndStoreComment?comment=${comment}&inverterSerialNo=${getInverterDetails}&sEId=${xUserId}`,
    );
  }

  deleteHistory(user: string): Observable<DeletePlantHistory> {
    return this.http.get<DeletePlantHistory>(
      this.env.externalUrls.system_integration.baseUrl +
        `/getPlantDeleteHistory?sEId=${user}`,
    );
  }
}
