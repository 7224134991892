<div
  class="h-full text-white flex flex-col flex-grow overflow-y-auto min-w-[900px] min-h-[620px]"
>
  <div class="flex my-3 ml-2">
    @for (item of navHeaders; track $index) {
      <div class="flex flex-col items-center" (click)="setCurrentUI(item.val)">
        <span
          class="mx-4 text-[14px] leading-[16px] cursor-pointer"
          ngClass="{{
            curTab == item.val ? 'text-white ' : 'text-sub-header-colorss'
          }}"
          >{{ item.label | translate }}</span
        >
      </div>
    }
  </div>
  <div class="flex justify-between dark:bg-dimGrey p-[7px] h-[30px] mx-3">
    <div class="flex justify-center items-center flex-wrap gap-x-3">
      @for (item of plantInfo; track item.icon) {
        <div class="flex justify-between flex-wrap items-center mr-2">
          @if (item.icon) {
            <img src="{{ item.icon }}" class="mr-2 icon-sm" alt="" />
          }
          <span class="label-xs font-medium m-0"
            >{{ item.description }}{{ " (" + item.count + ")" }}</span
          >
        </div>
      } @empty {
        Empty list of users
      }
    </div>
    <div class="flex flex-wrap">
      <div class="flex mr-3">
        <div
          class="relative flex items-center cursor-pointer"
          (click)="toggleCustomize()"
        >
          <span class="custom-font">Customize</span>

          @if (customize) {
            <div
              class="w-[200px] z-10 p-1 bg-slateGrey absolute right-2 top-[25px] rounded-lg flex flex-col"
              (click)="$event.stopPropagation()"
            >
              <span class="font-medium text-center mt-2"
                >Select Display Columns</span
              >
              <div class="flex flex-col justify-center">
                @for (item of filterColumns; track $index) {
                  <div class="flex p-1">
                    <input
                      type="checkbox"
                      [checked]="item.isSelected"
                      [(ngModel)]="item.isSelected"
                      (ngModelChange)="toggleFilterCheckBox($event)"
                    />
                    <span class="ml-2">{{ headerKeyMap[item.name] }}</span>
                  </div>
                }
                <div class="flex justify-around">
                  <button
                    class="btns filterbtns cursor-pointer"
                    (click)="cancelFilter($event)"
                  >
                    Cancel
                  </button>
                  <button
                    class="btns filterbtns cursor-pointer"
                    (click)="confirmHandler($event)"
                  >
                    Confirm
                  </button>
                  <button
                    class="btns filterbtns cursor-pointer"
                    (click)="resetHandler($event)"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      <div class="relative flex items-center cursor-pointer ml-3 mr-3">
        <app-excel-download
          [columns]="columns"
          [apiResponse]="apiResponse"
          [headerStyling]="headerStyling"
          [fileName]="fileName"
          [sheetName]="sheetName"
        >
        </app-excel-download>
      </div>
      <div class="flex justify-around items-center"></div>
    </div>
  </div>

  <div class="text-white mx-3 h-full flex flex-col flex-grow overflow-y-auto">
    @if (curTab == "solar") {
      <div class="table-container">
        <table>
          <thead class="bg-btTh">
            <tr>
              <th>
                <div class="flex">
                  <input
                    class=""
                    type="checkbox"
                    [checked]="selectAll"
                    [(ngModel)]="selectAll"
                    (change)="toggleSelectAll()"
                  />
                  <span
                    class="ml-6 flex justify-center items-center label-xs font-semibold whitespace-nowrap"
                    >Plant Name</span
                  >
                </div>
              </th>
              @for (item of solarPlantListHeaders; track $index) {
                <th scope="col" class="label-xs font-medium whitespace-nowrap cursor-pointer" (click)="sortData(item)">
                  @if (item === "plantHealth") {
                    <div class="flex flex-row justify-center">
                      <span>{{ headerKeyMap[item] }}</span>
                      <img
                        src="../../../../assets/images/infoicon.svg"
                        class="h-[14px] px-2"
                        alt="Info"
                        matTooltip="{{ getTooltipContent(item) }}"
                        matTooltipClass="tooltipClass"
                      />
                    </div>
                  } @else {
                    {{ headerKeyMap[item] }}
                  }
                </th>
              }
            </tr>
          </thead>
          <tbody>
            <tr
              class="cursor-pointer"
              *ngFor="let item of solarPlantManagementData"
              (click)="loadNewTab(item.plantId ? item.plantId : '')"
            >
              <td scope="row" class="text-center abc" *ngIf="item.plantName">
                <div class="flex">
                  <input
                    type="checkbox"
                    class=""
                    [checked]="item.isSelected"
                    [(ngModel)]="item.isSelected"
                    (change)="toggleCheckBox()"
                    (click)="$event.stopPropagation()"
                  />
                  <div
                    class="flex flex-col items-start ml-6 label-xs font-bold whitespace-nowrap"
                  >
                    <span> {{ item.plantName }}</span>
                    <span class="mt-2 text-[11px] font-normal text-start">
                      Last Updated: {{ item.lastUpdated }}</span
                    >
                  </div>
                </div>
              </td>
              <td scope="row" class="text-center" *ngIf="item.status">
                @if (item.status === "Online") {
                  <img
                    src="../../../../assets/svg/online-icon.svg"
                    class="icon-xs"
                    alt=""
                  />
                } @else {
                  <img
                    src="../../../../assets/svg/offline-icon.svg"
                    class="icon-xs"
                    alt=""
                  />
                }
              </td>
              <td
                scope="row"
                class="text-start label-xs"
                *ngIf="item.dataloggerSN"
              >
                {{ item.dataloggerSN }}
              </td>
              <td
                scope="row"
                class="text-start label-xs whitespace-nowrap"
                *ngIf="item.modelName"
              >
                {{ item.modelName }}
              </td>
              <td
                scope="row"
                class="text-start label-xs whitespace-nowrap"
                *ngIf="item.inverterRating"
              >
                {{ item.inverterRating }}
              </td>
              <td
                scope="row"
                class="text-start label-xs whitespace-nowrap"
                *ngIf="item.solarPVInstalled"
              >
                {{ item.solarPVInstalled }}
              </td>
              <td
                scope="row"
                class="text-start label-xs whitespace-nowrap"
                *ngIf="item.solarPower"
              >
                {{ item.solarPower }}
              </td>

              <td
                scope="row"
                class="text-start label-xs whitespace-nowrap"
                *ngIf="item.todayGeneration"
              >
                {{ item.todayGeneration }}
              </td>
              <td
                scope="row"
                class="text-start label-xs whitespace-nowrap"
                *ngIf="item.lifetimeGeneration"
              >
                {{ item.lifetimeGeneration }}
              </td>
              <td
                scope="row"
                class="text-start label-xs whitespace-nowrap"
                *ngIf="item.plantHealth"
              >
                {{ item.plantHealth }}
              </td>
              <td
                scope="row"
                class="text-start label-xs whitespace-nowrap"
                *ngIf="item.alert"
              >
                {{ item.alert }}
              </td>
              <td
                scope="row"
                class="text-start label-xs whitespace-nowrap"
                *ngIf="item.customerName"
              >
                {{ item.customerName }}
              </td>
              <td
                scope="row"
                class="text-start label-xs whitespace-nowrap"
                *ngIf="item.savings != null && item.savings >= '0'"
              >
                {{ item.savings }}
              </td>

              <td
                scope="row"
                class="text-start label-xs whitespace-nowrap"
                *ngIf="item.roi != null && item.roi >= 0"
              >
                {{ item.roi }} %
              </td>
              <td
                scope="row"
                class="text-start label-xs whitespace-nowrap"
                *ngIf="item.treesPlanted != null && item.treesPlanted >= 0"
              >
                {{ item.treesPlanted }}
              </td>
              <td
                scope="row"
                class="text-start label-xs whitespace-nowrap"
                *ngIf="item.co2Saved"
              >
                {{ item.co2Saved }}
              </td>
              <td
                scope="row"
                class="text-start label-xs whitespace-nowrap"
                *ngIf="item.coalNotburned"
              >
                {{ item.coalNotburned }}
              </td>
              <td
                scope="row"
                class="text-start label-xs whitespace-nowrap"
                *ngIf="item.dataloggerSignalStrength"
              >
                {{ item.dataloggerSignalStrength }}
              </td>
              <td
                scope="row"
                class="text-start label-xs whitespace-nowrap"
                *ngIf="item.plantCreationDate"
              >
                {{ item.plantCreationDate }}
              </td>
              <td
              scope="row"
              class="text-start label-xs whitespace-nowrap"
              *ngIf="item.totalDays!==undefined && item.onlineDays!==undefined"
            >
              {{ item.onlineDays+'/'+ item.totalDays }}
            </td>
            </tr>
          </tbody>
        </table>
      </div>
    }

    @if (curTab == "non_solar") {
      <div class="table-container">
        <table>
          <thead class="cczzz">
            <tr>
              <th>
                <div class="flex">
                  <input
                    class=""
                    type="checkbox"
                    [checked]="selectAll"
                    [(ngModel)]="selectAll"
                    (change)="toggleSelectAll()"
                  />
                  <span
                    class="ml-6 flex justify-center items-center label-xs font-semibold"
                    >Plant Name</span
                  >
                </div>
              </th>
              @for (item of nonSolarPlantListHeaders; track $index) {
                <th scope="col" class="label-xs font-medium whitespace-nowrap cursor-pointer" (click)="sortData(item)">
                  {{ headerKeyMap[item] }}
                </th>
              }
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let item of non_solarPlantManagementData"
              class="cursor-pointer"
              (click)="loadNewTab(item.plantId ? item.plantId : '')"
            >
              <td scope="row" class="text-center abc" *ngIf="item.plantName">
                <div class="flex">
                  <input
                    type="checkbox"
                    [checked]="item.isSelected"
                    [(ngModel)]="item.isSelected"
                    (change)="toggleCheckBox()"
                    (click)="$event.stopPropagation()"
                  />
                  <div
                    class="flex flex-col items-start ml-6 label-xs font-bold whitespace-nowrap"
                  >
                    <span> {{ item.plantName }}</span>
                    <span class="mt-2 text-[11px] font-normal text-start">
                      Last Updated: {{ item.lastUpdated }}</span
                    >
                  </div>
                </div>
              </td>
              <td
                scope="row"
                class="text-center label-xs font-normal whitespace-nowrap"
                *ngIf="item.status"
              >
                @if (item.status === "Online") {
                  <img
                    src="../../../../assets/svg/online-icon.svg"
                    class="icon-xs"
                    alt=""
                  />
                } @else {
                  <img
                    src="../../../../assets/svg/offline-icon.svg"
                    class="icon-xs"
                    alt=""
                  />
                }
              </td>
              <td
                scope="row"
                class="text-start label-xs whitespace-nowrap"
                *ngIf="item.dataloggerSN"
              >
                {{ item.dataloggerSN }}
              </td>
              <td
                scope="row"
                class="text-start label-xs whitespace-nowrap"
                *ngIf="item.modelName"
              >
                {{ item.modelName }}
              </td>
              <td
                scope="row"
                class="text-start label-xs whitespace-nowrap"
                *ngIf="item.inverterRating"
              >
                {{ item.inverterRating }}
              </td>
              <td
                scope="row"
                class="text-start label-xs whitespace-nowrap"
                *ngIf="item.plantHealth"
              >
                {{ item.plantHealth }}
              </td>
              <td
                scope="row"
                class="text-start label-xs whitespace-nowrap"
                *ngIf="item.batteryVoltage"
              >
                {{ item.batteryVoltage }}
              </td>

              <td
                scope="row"
                class="text-start label-xs whitespace-nowrap"
                *ngIf="item.batteryState"
              >
                {{ item.batteryState }}
              </td>
              <td
                scope="row"
                class="text-start label-xs whitespace-nowrap"
                *ngIf="item.powerCutDuration"
              >
                {{ item.powerCutDuration }}
              </td>
              <td
                scope="row"
                class="text-start label-xs whitespace-nowrap"
                *ngIf="item.noofPowerCut != null && item.noofPowerCut >= 0"
              >
                {{ item.noofPowerCut }}
              </td>
              <td
                scope="row"
                class="text-start label-xs whitespace-nowrap"
                *ngIf="item.totalBackupCapacity"
              >
                {{ item.totalBackupCapacity }}
              </td>
              <td
                scope="row"
                class="text-start label-xs whitespace-nowrap"
                *ngIf="item.currentBackupCapacity"
              >
                {{ item.currentBackupCapacity }}
              </td>

              <td
                scope="row"
                class="text-start label-xs whitespace-nowrap"
                *ngIf="item.alert"
              >
                {{ item.alert }}
              </td>
              <td
                scope="row"
                class="text-start label-xs whitespace-nowrap"
                *ngIf="item.customerName"
              >
                {{ item.customerName }}
              </td>
              <td
                scope="row"
                class="text-start label-xs whitespace-nowrap"
                *ngIf="item.gridVoltage"
              >
                {{ item.gridVoltage }}
              </td>
              <td
                scope="row"
                class="text-start label-xs font-normal whitespace-nowrap"
                *ngIf="item.plantCreationDate"
              >
                {{ item.plantCreationDate }}
              </td>
              <td
              scope="row"
              class="text-start label-xs whitespace-nowrap"
              *ngIf="item.totalDays!==undefined && item.onlineDays!==undefined"
            >
              {{ item.onlineDays+'/'+ item.totalDays }}
            </td>
            </tr>
          </tbody>
        </table>
      </div>
    }
    <div class="flex justify-center mt-2 w-[full]">
      <div class="flex">
        <div class="flex min-w-[70px] justify-around">
          <button
            class="bg-btTh"
            (click)="getPrevPage()"
            [disabled]="
              (this.prevSolarPage < 0 && this.curTab == 'solar') ||
              (this.prevNonsolarPage < 0 && this.curTab == 'non_solar')
            "
          >
            <fa-icon [icon]="faAngleLeft" class="text-white"></fa-icon>
          </button>
          <button
            (click)="getNextPage()"
            class="bg-btTh"
            [disabled]="displayNext == true"
          >
            <fa-icon [icon]="faAngleRight" class="text-white"></fa-icon>
          </button>
        </div>

        <div class="flex flex-wrap p-1 bg-bgSelect rounded-full ml-3">
          <select
            class="w-[144px] text-btnGrey bg-bgSelect btns"
            [(ngModel)]="pageSize"
            (change)="togglePageSize($event)"
            #pageSizeHandler
          >
            <option value="20">20/page</option>
            <option value="50">50/page</option>
            <option value="100">100/page</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>
