import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MapInfoWindow,
  GoogleMap,
  MapAdvancedMarker,
} from '@angular/google-maps';
import { BaseComponent } from '../../../base/components/base.component';
import { CommonModule } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { PlantDetail } from '../../models/plant';
import { loadScript } from '../../utils/common-utils';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-solar-rooftop',
  standalone: true,
  imports: [
    GoogleMap,
    MapInfoWindow,
    GoogleMap,
    CommonModule,
    MapAdvancedMarker,
    TranslateModule,
  ],
  templateUrl: './solar-rooftop.component.html',
  styleUrl: './solar-rooftop.component.scss',
})
export class SolarRooftopComponent extends BaseComponent implements OnDestroy {
  options: google.maps.MapOptions = {
    center: { lat: 28.449264, lng: 77.0742105 },
    zoom: 22,
    mapId: 'MAP_ID',
    mapTypeId: 'satellite',
    maxZoom: 22,
    minZoom: 8,
  };
  plantDetails: PlantDetail[] = [];
  @ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow;
  private onDestroy$ = new Subject<void>();
  plantData?: PlantDetail | null;

  ngOnInit() {
    this.updateMapMarkerOnDataReceived();
  }
  updateMapMarkerOnDataReceived() {
    this.baseUserService.currentData
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => (this.plantData = data));

    const parser = new DOMParser();
    const svgString = `<?xml version="1.0" ?><svg height="32" version="1.1" width="32" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"><g transform="translate(0 -1028.4)"><path d="m12.031 1030.4c-3.8657 0-6.9998 3.1-6.9998 7 0 1.3 0.4017 2.6 1.0938 3.7 0.0334 0.1 0.059 0.1 0.0938 0.2l4.3432 8c0.204 0.6 0.782 1.1 1.438 1.1s1.202-0.5 1.406-1.1l4.844-8.7c0.499-1 0.781-2.1 0.781-3.2 0-3.9-3.134-7-7-7zm-0.031 3.9c1.933 0 3.5 1.6 3.5 3.5 0 2-1.567 3.5-3.5 3.5s-3.5-1.5-3.5-3.5c0-1.9 1.567-3.5 3.5-3.5z" fill="#c0392b"/><path d="m12.031 1.0312c-3.8657 0-6.9998 3.134-6.9998 7 0 1.383 0.4017 2.6648 1.0938 3.7498 0.0334 0.053 0.059 0.105 0.0938 0.157l4.3432 8.062c0.204 0.586 0.782 1.031 1.438 1.031s1.202-0.445 1.406-1.031l4.844-8.75c0.499-0.963 0.781-2.06 0.781-3.2188 0-3.866-3.134-7-7-7zm-0.031 3.9688c1.933 0 3.5 1.567 3.5 3.5s-1.567 3.5-3.5 3.5-3.5-1.567-3.5-3.5 1.567-3.5 3.5-3.5z" fill="#e74c3c" transform="translate(0 1028.4)"/></g></svg>`;

    this.logger.info(JSON.stringify(this.plantData));
    if (this.plantData && this.plantData.lat && this.plantData.lon) {
      this.options = {
        center: { lat: this.plantData.lat, lng: this.plantData.lon },
        zoom: 22,
        mapId: 'MAP_ID',
        mapTypeId: 'satellite',
        maxZoom: 22,
        minZoom: 8,
      };
      this.plantDetails.push(this.plantData);
      this.plantData.content = parser.parseFromString(
        svgString,
        'image/svg+xml',
      ).documentElement;
    }
  }

  /**
   *
   * @param marker Open marker window
   */
  onMarkerClick(marker: MapAdvancedMarker) {
    this.infoWindow.options = {
      maxWidth: 400,
      pixelOffset: new google.maps.Size(0, -30),
    };
    this.infoWindow.open(marker);
  }

  /**
   * clean subscription
   */
  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
