<div class="flex flex-col flex-grow h-full">
  <div class="hidden mb-5 mt-4">
    <h5
      class="flex gap-1 p-3 font-sans text-base font-normal text-blue-gray-700"
    >
      <div class="hidden grid place-items-end">
        <div
          (click)="collapsed.set(!collapsed())"
          [ngClass]="{
            'expand-arrow': !collapsed(),
            'collapse-arrow': collapsed()
          }"
        >
          <img
            [src]="
              collapsed() ? 'assets/svg/expand.svg' : 'assets/svg/collapse.svg'
            "
            alt="Menu icon"
            class="w-[26px] text-white"
          />
        </div>
      </div>
    </h5>
  </div>

  <!-- Translucent overlay element -->
  <div
    class="flex flex-col justify-between gap-1 p-3 font-sans text-base font-normal text-blue-gray-700 h-full mt-14"
  >
    <div>
      @for (item of menuItems(); track $index) {
        @if (item.enable) {
          @if (item.position === "Top") {
            @if (item.route === "dashboard/customer-dashboard") {
              <div
                role="button"
                class="group relative flex items-center w-full py-3 pl-2 leading-tight transition-all md outline-none text-start hover:bg-gray-800 hover:rounded-r-2xl active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900 menu-item cursor-default"
                routerLinkActive="selected-menu-item"
                #rla="routerLinkActive"
                (click)="
                  !item.disableOption && isPlantExist(plantList)
                    ? null
                    : routeToCustomerDashboard(item.route)
                "
                [ngClass]="{ tooltip: true, 'selected-menu-item': isActive }"
              >
                <div class="grid mr-4">
                  @if (!item.disableOption && isPlantExist(plantList)) {
                    <div
                      class="flex flex-col justify-start items-start flex-grow plant-menu-dropdown w-[230px] text-center text-white text-xs rounded-tr-lg rounded-br-lg gap-2 opacity-1 invisible dark:bg-dimGrey z-20 pr-4 py-4"
                    >
                      <span
                        class="text-sm font-bold px-2 text-dark-accent-secondary pl-7"
                        >Plant List</span
                      >
                      <div
                        class="w-full flex flex-row justify-center items-center space-x-4"
                      >
                        <div
                          class="dark:bg-none px-4 py-[4px] dark:text-white rounded-xl border-solid border-[0.5px] border-gray-300 flex items-center space-x-2 cursor-pointer"
                          [ngClass]="{
                            'bg-dark-accent-secondary': isPrimaryPlant === true
                          }"
                          (click)="togglePrimaryPlant()"
                        >
                          <span> {{ "Primary" }}</span>
                        </div>
                        <div
                          class="dark:bg-none px-4 py-[3px] dark:text-white rounded-xl border-solid border-[0.5px] border-gray-300 flex items-center space-x-2 cursor-pointer"
                          [ngClass]="{
                            'bg-dark-accent-secondary': isPrimaryPlant !== true
                          }"
                          (click)="togglePrimaryPlant()"
                        >
                          <span> {{ "Secondary" }}</span>
                        </div>
                      </div>
                      <div>
                        <div
                          class="fixed w-[230px] text-center text-white text-xs rounded-tr-lg rounded-br-lg gap-2 opacity-1 dark:bg-dimGrey pr-4 pb-4"
                        >
                          @if (isPrimaryPlant) {
                            <div
                              class="max-h-64 overflow-scroll w-full max-auto-scroll"
                            >
                              @for (
                                plant of plantList?.primaryPlant;
                                track $index
                              ) {
                                <a (click)="navigateToDashboard(plant)">
                                  <div
                                    class="text-xs text-left cursor-pointer hover:bg-dimGrey hover:opacity-50 mt-2 px-4"
                                  >
                                    <div
                                      class="flex flex-row justify-between items-center"
                                    >
                                      <div class="flex flex-row">
                                        <fa-icon
                                          [icon]="faCircle"
                                          class="text-[10px]"
                                          [ngClass]="
                                            plant.online
                                              ? 'text-green-500'
                                              : 'text-red-500'
                                          "
                                        ></fa-icon>

                                        <div class="pl-4">
                                          <span>{{ plant.plantName }}</span>
                                        </div>
                                      </div>
                                      @if (selectedPlant?.id === plant.id) {
                                        <fa-icon
                                          [icon]="faCircleCheck"
                                          class="text-dark-accent-secondary text-[15px] mr-2"
                                        ></fa-icon>
                                      }
                                    </div>

                                    <span
                                      class="w-full pt-1 pl-[25px] leading-none text-[10px] text-left text-gray-50 opacity-50"
                                      >{{ plant.inverterType }}</span
                                    >
                                  </div>
                                </a>
                              }
                            </div>
                          }

                          @if (!isPrimaryPlant) {
                            <div
                              class="max-h-64 overflow-scroll w-full max-auto-scroll"
                            >
                              @for (
                                plant of plantList?.secondaryPlant;
                                track $index
                              ) {
                                <a (click)="navigateToDashboard(plant)">
                                  <div
                                    class="text-xs text-left cursor-pointer hover:bg-dimGrey hover:opacity-50 mt-2 px-4"
                                    (click)="selectPlant(plant.id)"
                                  >
                                    <div
                                      class="flex flex-row justify-between items-center"
                                    >
                                      <div class="flex flex-row">
                                        <fa-icon
                                          [icon]="faCircle"
                                          class="text-[10px]"
                                          [ngClass]="
                                            plant.online
                                              ? 'text-green-500'
                                              : 'text-red-500'
                                          "
                                        ></fa-icon>

                                        <div class="pl-4">
                                          <span>{{ plant.plantName }}</span>
                                        </div>
                                      </div>

                                      @if (selectedPlant?.id === plant.id) {
                                        <fa-icon
                                          [icon]="faCircleCheck"
                                          class="text-dark-accent-secondary text-[15px] mr-2"
                                        ></fa-icon>
                                      }
                                    </div>

                                    <span
                                      class="w-full pt-1 pl-[25px] leading-none text-[10px] text-left text-gray-50 opacity-50"
                                      >{{ plant.inverterType }}</span
                                    >
                                  </div>
                                </a>
                              }
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  }

                  <img
                    matListItemIcon
                    src="assets/svg/{{ item.icon }}.svg"
                    alt="{{ item.icon }}"
                    class="w-[26px]"
                    [ngClass]="{ 'svg-icon-active': rla.isActive || isActive }"
                  />
                </div>

                @if (!collapsed()) {
                  <span
                    matListItemTitle
                    class="text-white text-opacity-50 text-sm truncate group-hover:text-opacity-100"
                    >{{ item.label | translate }}</span
                  >
                }
              </div>
            } @else {
              <div
                role="button"
                class="group relative flex items-center w-full py-3 pl-2 leading-tight transition-all md outline-none text-start hover:bg-gray-800 hover:rounded-r-2xl active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900 menu-item cursor-default"
                [routerLink]="item.route"
                routerLinkActive="selected-menu-item"
                #rla="routerLinkActive"
                [ngClass]="{ tooltip: true }"
              >
                <div class="grid mr-4 place-items-center">
                  <div
                    class="tooltip-content w-32 text-center py-2 dark:bg-dark-accent text-white text-xs p-1 rounded opacity-0 invisible transition-all absolute top-0 left-full ml-2 before:border-t-transparent before:border-b-transparent before:border-l-transparent before:border-r-dark-accent z-20"
                  >
                    <span class="tooltiptext">
                      {{ item.label | translate }}</span
                    >
                  </div>
                  <img
                    matListItemIcon
                    src="assets/svg/{{ item.icon }}.svg"
                    alt="{{ item.icon }}"
                    class="w-[26px]"
                    [ngClass]="{ 'svg-icon-active': rla.isActive }"
                  />
                </div>

                @if (!collapsed()) {
                  <span
                    matListItemTitle
                    class="text-white text-opacity-50 text-sm truncate group-hover:text-opacity-100"
                    >{{ item.label | translate }}</span
                  >
                }
              </div>
            }
          }
        }
      }
    </div>

    <div>
      @for (item of menuItems(); track $index) {
        @if (item.position === "Bottom" && item.enable) {
          <div
            role="button"
            class="group relative flex items-center w-full py-3 pl-2 leading-tight transition-all md outline-none text-start hover:bg-gray-800 hover:rounded-r-2xl active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900 menu-item cursor-default"
            routerLinkActive="selected-menu-item"
            #rla="routerLinkActive"
            [ngClass]="{ tooltip: true }"
          >
            <div class="grid mr-4 place-items-center">
              @if (item.route === "help") {
                <div
                  class="py-4 flex flex-col justify-start items-start menu-dropdown w-32 text-center dark:bg-dimGrey text-white text-xs rounded-tr-lg rounded-br-lg gap-2 opacity-0 invisible transition-all absolute top-0 left-full before:border-t-transparent before:border-b-transparent before:border-l-transparent before:border-r-dark-accent z-20"
                >
                  <span
                    class="w-full pt-1 px-2 text-xs text-left"
                    routerLink="contact-us"
                    >Contact us</span
                  >
                  <span
                    class="w-full pt-1 px-2 text-xs text-left"
                    routerLink="faqs"
                    >FAQ</span
                  >
                  <span
                    class="w-full pt-1 px-2 text-xs text-left"
                    routerLink="video-guide"
                    >Video guide</span
                  >
                </div>
              } @else if (item.route === "setting") {
                <div
                  class="py-3 flex flex-col justify-start items-start menu-dropdown w-32 text-center dark:bg-dimGrey text-white text-xs rounded-tr-lg rounded-br-lg gap-2 opacity-0 invisible transition-all absolute top-0 left-full before:border-t-transparent before:border-b-transparent before:border-l-transparent before:border-r-dark-accent z-20"
                >
                  <span
                    class="w-full px-2 text-xs text-left"
                    routerLink="change-password"
                    >{{ "change_password" | translate }}</span
                  >
                </div>
              } @else {
                <div
                  class="tooltip-content w-32 text-center py-2 dark:bg-dark-accent text-white text-xs p-1 rounded opacity-0 invisible transition-all absolute top-0 left-full ml-2 before:border-t-transparent before:border-b-transparent before:border-l-transparent before:border-r-dark-accent z-20"
                >
                  <span class="tooltiptext"> {{ item.label | translate }}</span>
                </div>
              }
              <img
                matListItemIcon
                src="assets/svg/{{ item.icon }}.svg"
                alt="{{ item.icon }}"
                class="w-[26px]"
                [ngClass]="{ 'svg-icon-active': rla.isActive }"
              />
            </div>

            @if (!collapsed()) {
              <span
                matListItemTitle
                class="text-white text-opacity-50 text-sm truncate group-hover:text-opacity-100"
                >{{ item.label | translate }}</span
              >
            }
          </div>
        }
      }
    </div>
  </div>
</div>
