import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import {
  StorageService,
  USER_KEY,
} from '../../../shared/service/storage.service';
import { User } from '../../../shared/models/user';
import { BaseComponent } from '../../../base/components/base.component';
import { SessionService } from '../../../shared/service/session.service';
import { Router } from '@angular/router';
import {
  NoxDialogParam,
  NoxDialogType,
} from '../../../shared/models/nox-dialog-param';
import { NoxDialogComponent } from '../../../shared/components/nox-dialog/nox-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-user-profile',
  standalone: true,
  imports: [],
  templateUrl: './user-profile.component.html',
  styleUrl: './user-profile.component.scss',
})
export class UserProfileComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  dialog = inject(MatDialog);

  previewImage(url?: string) {
    let noxDialogData: NoxDialogParam = {
      type: NoxDialogType.image,
      imageUrl: url,
    };
    this.dialog.open(NoxDialogComponent, {
      data: noxDialogData,
    });
  }
  userData?: User | null;

  constructor(private _location: Location) {
    super();
    this.userData = this.storageService.getObject(USER_KEY);
  }
  goBack(): void {
    this.baseUserService.back();
  }

  ngOnInit(): void {
    this.baseUserService.sessionService.startBnWatch(this.router);
  }
  ngOnDestroy(): void {
    this.baseUserService.sessionService.stopBnTimer();
  }
}
