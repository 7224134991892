import { Routes } from '@angular/router';
import { HomeDashboardComponent } from './shared/components/dashboard/home-dashboard/home-dashboard.component';
import { SolarDashboardComponent } from './shared/components/dashboard/solar-dashboard/solar-dashboard.component';
import { NonSolarDashboardComponent } from './shared/components/dashboard/non-solar-dashboard/non-solar-dashboard.component';
import { BmsDashboardComponent } from './shared/components/dashboard/bms-dashboard/bms-dashboard.component';
import { LayoutComponent } from './shared/components/layout/layout.component';
import { CustomerDashboardComponent } from './shared/components/dashboard/customer-dashboard/customer-dashboard.component';
import { PlantComponent } from './shared/components/plant/plant.component';
import { PlantDetailComponent } from './customer/components/plant-detail/plant-detail.component';
import { authGuard, authGuardSI } from './shared/guard/auth.guard';
import { PlantManagementComponent } from './shared/components/plant-management/plant-management.component';
import { AlertsComponent } from './shared/components/alerts/alerts.component';
import { FAQsComponent } from './customer/components/faqs/faqs.component';
import { VideoGuideComponent } from './customer/components/video-guide/video-guide.component';
import { ChangePasswordComponent } from './customer/components/change-password/change-password.component';
import { ContactUsComponent } from './customer/components/contact-us/contact-us.component';
import { UserProfileComponent } from './customer/components/user-profile/user-profile.component';
import { AboutUsComponent } from './customer/components/about-us/about-us.component';
import { AddSecondaryUserComponent } from './customer/components/add-secondary-user/add-secondary-user.component';
import { SolarRooftopComponent } from './shared/components/solar-rooftop/solar-rooftop.component';
import { HistoryComponent } from './service-engineer/components/history/history.component';
import { DeleteplantComponent } from './service-engineer/components/deleteplant/deleteplant.component';
import { AnalyticsComponent } from './customer/components/analytics/analytics.component';

export const routes: Routes = [
  // lazy loaded dashboard module
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },

  {
    path: 'system-integrator',
    component: LayoutComponent,
    canActivate: [authGuardSI],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        children: [
          {
            path: '',
            redirectTo: 'home',
            pathMatch: 'full',
          },
          {
            path: 'home',
            component: HomeDashboardComponent,
          },
          {
            path: 'solar',
            component: SolarDashboardComponent,
          },
          {
            path: 'non-solar',
            component: NonSolarDashboardComponent,
          },
          {
            path: 'bms',
            component: BmsDashboardComponent,
          },
        ],
      },
      {
        path: 'contact-us',
        component: ContactUsComponent,
      },

      {
        path: 'faqs',
        component: FAQsComponent,
      },

      {
        path: 'video-guide',
        component: VideoGuideComponent,
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent,
      },
      {
        path: 'about-us',
        component: AboutUsComponent,
      },
      {
        path: 'plant-management',
        component: PlantManagementComponent,
      },
      {
        path: 'user-profile',
        component: UserProfileComponent,
      },
      {
        path: 'alerts/:type',
        component: AlertsComponent,
      },
    ],
  },
  {
    path: 'customer',
    canActivate: [authGuard],
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        component: CustomerDashboardComponent,
      },
      {
        path: 'plant-detail/:selectedPlantId',
        component: PlantDetailComponent,
      },
      {
        path: 'contact-us',
        component: ContactUsComponent,
      },
      {
        path: 'user-profile',
        component: UserProfileComponent,
      },
      {
        path: 'faqs',
        component: FAQsComponent,
      },

      {
        path: 'video-guide',
        component: VideoGuideComponent,
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent,
      },
      {
        path: 'about-us',
        component: AboutUsComponent,
      },
      {
        path: 'add-secondary-user',
        component: AddSecondaryUserComponent,
      },
      {
        path: 'solar-rooftop',
        component: SolarRooftopComponent,
      },
      {
        path: 'analytics',
        component: AnalyticsComponent,
      },
    ],
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./shared/components/login/login.component').then(
        (m) => m.LoginComponent,
      ),
  },
  {
    path: 'forgot-password',
    loadComponent: () =>
      import(
        './shared/components/forget-password/forget-password.component'
      ).then((m) => m.ForgetPasswordComponent),
  },

  {
    path: 'service-engineer',
    component: LayoutComponent,
    canActivate: [authGuardSI],
    children: [
      {
        path: '',
        redirectTo: 'delete-plant',
        pathMatch: 'full',
      },

      {
        path: 'delete-plant',
        component: DeleteplantComponent,
      },
      {
        path: 'history',
        component: HistoryComponent,
      },
      {
        path: 'contact-us',
        component: ContactUsComponent,
      },

      {
        path: 'faqs',
        component: FAQsComponent,
      },

      {
        path: 'video-guide',
        component: VideoGuideComponent,
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent,
      },
      {
        path: 'about-us',
        component: AboutUsComponent,
      },
      {
        path: 'plant-management',
        component: PlantManagementComponent,
      },
      {
        path: 'user-profile',
        component: UserProfileComponent,
      },
    ],
  },
];
