import { Component, Input, OnInit, inject } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { CustomerService } from '../../../services/customer.service';
import { FormDetail, ViewTicket } from '../../../../shared/models/view-ticket';
@Component({
  selector: 'app-viewticket',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './viewcomponent.component.html',
  styleUrl: './viewcomponent.component.scss',
})
export class ViewticketComponent implements OnInit {
  plantInformation?: FormDetail;

  ngOnInit(): void {}
  isOpen: any;
  counter = [1, 2, 3, 4];
  formDetail!: FormDetail;
  plantName = localStorage.getItem('plantname');
  customerService: CustomerService = inject(CustomerService);
  imageArray: Array<string> = [];
  toggle() {
    this.isOpen = !this.isOpen;
  }
  viewTicket(id: string) {
    this.customerService.viewTicket(id).subscribe({
      next: (response: ViewTicket) => {
        this.plantInformation = response.data;
        if (this.plantInformation.imageUrl) {
          this.imageArray = this.plantInformation.imageUrl;
        }
      },
    });
  }
}
