import { Component, OnDestroy, OnInit, inject, input } from '@angular/core';
import { EChartsOption } from 'echarts';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { BaseComponent } from '../../../base/components/base.component';
import { TranslateModule } from '@ngx-translate/core';
import {
  TodayAlertDetails,
  TodayAlertDetailsData,
} from '../../models/today-alert-details';
import { NavigationStart, RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { SiService } from '../../../system-integrator/services/si.service';
import { AuthService } from '../../service/auth.service';
import { User } from '../../models/user';
import { USER_KEY } from '../../service/storage.service';

@Component({
  selector: 'app-today-alerts',
  standalone: true,
  imports: [NgxEchartsDirective, TranslateModule, RouterModule],
  templateUrl: './today-alerts.component.html',
  styleUrl: './today-alerts.component.scss',
  providers: [provideEcharts()],
})
export class TodayAlertsComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  options!: EChartsOption;

  alertsDetails: TodayAlertDetailsData = {
    normalAlerts: 0,
    abnormalAlerts: 0,
    abnormalAlertsPlantCount: 0,
    normalAlertsPlantCount: 0,
  };

  todayAlertsSubscription!: Subscription;
  siService: SiService = inject(SiService);
  categoryId = input.required<string>();
  installer_id!: string;
  alertType: String = '';

  ngOnInit(): void {
    this.installer_id = this.authService.loggedUser.installer_id;

    this.getTodayAlerts();
    switch (this.categoryId()) {
      case '1':
        this.alertType = 'all';
        break;
      case '2':
        this.alertType = 'solar';
        break;
      case '3':
        this.alertType = 'non_solar';

        break;

      default:
        break;
    }
  }

  fillGraph() {
    this.options = {
      title: {
        left: 'center',
      },

      tooltip: {
        trigger: 'item',
      },
      series: [
        {
          type: 'pie',
          radius: '65%',
          center: ['50%', '50%'],
          selectedMode: 'single',
          color: ['#CC5151', '#0051BA'],
          data: [
            {
              value: this.alertsDetails.abnormalAlerts,
              name: 'Abnormal Alerts',
              label: { backgroundColor: 'transparent', color: '#fff' },
            },
            {
              value: this.alertsDetails.normalAlerts,
              name: 'Normal Alerts',
              label: { backgroundColor: 'transparent', color: '#fff' },
            },
          ],
          itemStyle: {
            borderColor: '#212932', // Border color
            borderWidth: 3, // Border width
          },

          emphasis: {
            itemStyle: {
              borderWidth: 0,
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };
  }

  getTodayAlerts() {
    this.todayAlertsSubscription = this.siService
      .getTodayAlertDetails(this.installer_id, this.categoryId())
      .subscribe({
        next: (value: TodayAlertDetails) => {
          if (value.data != null) {
            this.alertsDetails = value.data;
            this.fillGraph();
          }
        },
        error: (e: Error) => {
          this.toastr.error(e.message);
        },
        complete: () => {
          this.baseUserService.todayAlertsLoading.next(true);
        },
      });
  }

  ngOnDestroy(): void {
    this.todayAlertsSubscription.unsubscribe();
  }
}
