<div class="h-screen overflow-hidden">
  <!-- App header components -->
  <app-header class="h-14"></app-header>
  <div class="flex flex-row calc-h-full-minus-7vh">
    <!-- Adjust 60px according to the height of your header -->
    <div
      class="bg-light-variant-1 dark:bg-dimGrey duration-300 relative rounded-tr-2xl rounded-br-2xl"
      [style.width]="sideNavWidth()"
    >
      <app-sidebar [collapsed]="collapsed"></app-sidebar>
    </div>
    <div class="flex flex-col flex-grow overflow-y-auto max-auto-scroll">
      <div class="flex flex-col flex-grow overflow-y-auto max-auto-scroll">
        <!-- App sub header components -->
        @if (!isViewAsCustomer && isDashboard) {
          <!-- <app-sub-header class="sticky top-0"></app-sub-header> -->
          <app-sub-header></app-sub-header>
        }
        <!-- Content -->
        <div class="h-full">
          <router-outlet class="overflow-y-auto w-full h-full"></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
