import { Injectable, inject } from '@angular/core';
import { BaseUserService } from '../../base/services/base-user.service';
import { TotalPlantsSI } from '../../shared/models/total-plants-si';
import { PlantHealthDetails } from '../../shared/models/plant-health-details';
import { AllPlantDetails } from '../../shared/models/all-plant-details';
import { PlantListDetails } from '../../shared/models/plant-list-details';
import { TodayAlertDetails } from '../../shared/models/today-alert-details';
import { TodayAlertsListDetails } from '../../shared/models/today-alerts-list-details';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TodayPowerCutDetails } from '../../shared/models/today-power-cut-details';
import {
  PlantManagementSolar,
  PlantManagementNonSolar,
  non_solarplantResponseList,
  solarplantResponseList,
  PlantManagementCount,
} from '../../shared/models/plant-management';
import { BaseUtilService } from '../../base/services/base-util.service';
import {
  non_solarFilterColumns,
  solarFilterColumns,
} from '../constants/si.constants';
import { filterColumns } from '../../shared/interfaces/filter-columns';

@Injectable({
  providedIn: 'root',
})
export class SiService extends BaseUserService {
  utilService: BaseUtilService = inject(BaseUtilService);

  getAllPlantDetails(
    installerId: string,
    inverterType: string,
  ): Observable<AllPlantDetails> {
    let url =
      this.env.externalUrls.system_integration.baseUrl +
      `/getAllPlantDetails?installerId=${installerId}&inverterType=${inverterType}`;
    return this.http.get<AllPlantDetails>(url);
  }

  getTotalPlantsSI(
    installerId: string,
    inverterType: string,
  ): Observable<TotalPlantsSI> {
    let url =
      this.env.externalUrls.system_integration.baseUrl +
      `/getTotalPlantsSI?installerId=${installerId}&inverterType=${inverterType}`;
    return this.http.get<TotalPlantsSI>(url);
  }

  getPlantHealthDetails(
    installerId: string,
    inverterType: string,
  ): Observable<PlantHealthDetails> {
    let url =
      this.env.externalUrls.system_integration.baseUrl +
      `/getPlantHealthDetails?installerId=${installerId}&inverterType=${inverterType}`;
    return this.http.get<PlantHealthDetails>(url);
  }

  getTodayAlertDetails(
    installerId: string,
    inverterType: string,
  ): Observable<TodayAlertDetails> {
    let url =
      this.env.externalUrls.system_integration.baseUrl +
      `/getTodayAlertsDetails?installerId=${installerId}&inverterType=${inverterType}`;
    return this.http.get<TodayAlertDetails>(url);
  }
  getTodayAlertsListDetails(
    installerId: string,
    inverterType: string,
  ): Observable<TodayAlertsListDetails> {

    let url =
      this.env.externalUrls.system_integration.baseUrl +
      `/getTodayAlertsListDetails?installerId=${installerId}&inverterType=${inverterType}`;
    return this.http.get<TodayAlertsListDetails>(url);
  }

  getTodayPowerCutDetails(
    installerId: string,
    inverterType: string,
  ): Observable<TodayPowerCutDetails> {
    let url =
      this.env.externalUrls.system_integration.baseUrl +
      `/getTodayPowerCutDetails?installerId=${installerId}&inverterType=${inverterType}`;
    return this.http.get<TodayPowerCutDetails>(url);
  }

  getPlantListDetails(
    installerId: string,
    plantType: string,
  ): Observable<PlantListDetails> {
    let url =
      this.env.externalUrls.system_integration.baseUrl +
      `/getPlantListDetails?installerId=${installerId}&plantType=${plantType}`;
    return this.http.get<PlantListDetails>(url);
  }

  getSolarPlantMangementData(
    installerId: string,
    pageSize: number,
    continuationToken: string,
  ): Observable<PlantManagementSolar> {
    const headerDict = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      continuationToken: continuationToken,
    };

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    let url =
      this.env.externalUrls.system_integration.baseUrl +
      `/getPlantListDetailsV1?installerId=${installerId}&inverterType=1&pagesize=${pageSize}`;
    return this.http.get<PlantManagementSolar>(url, requestOptions);
  }
  getNonSolarPlantMangementData(
    installerId: string,
    pageSize: number,
    continuationToken: string,
  ): Observable<PlantManagementNonSolar> {
    const headerDict = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      continuationToken: continuationToken,
    };

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    let url =
      this.env.externalUrls.system_integration.baseUrl +
      `/getPlantListDetailsV1?installerId=${installerId}&inverterType=2&pagesize=${pageSize}`;
    return this.http.get<PlantManagementNonSolar>(url, requestOptions);
  }

  getPlantManagementCount(
    installerId: string,
    inverterType: string,
  ): Observable<PlantManagementCount> {
    let url =
      this.env.externalUrls.system_integration.baseUrl +
      `/getPlantManagementCount?installerId=${installerId}&inverterType=${inverterType}`;
    return this.http.get<PlantManagementCount>(url);
  }

  checkSolarRecord(val: solarplantResponseList) {
    const correctSolarKeys = solarFilterColumns.map(
      (x: filterColumns) => x.name,
    );

    let inputObjKeys = Object.keys(val);
    let pos: number = 0;
    for (var key of correctSolarKeys) {
      pos = inputObjKeys.indexOf(key);
      if (pos === -1) {
        switch (key) {
          case 'plantName':
            val[key] = 'NA';
            break;
          case 'location':
            val[key] = 'NA';
            break;
          case 'status':
            val[key] = 'NA';
            break;
          case 'dataloggerSN':
            val[key] = 'NA';
            break;
          case 'modelName':
            val[key] = 'NA';
            break;
          case 'inverterRating':
            val[key] = 'NA';
            break;
          case 'solarPVInstalled':
            val[key] = 'NA';
            break;
          case 'solarPower':
            val[key] = 'NA';
            break;
          case 'plantHealth':
            val[key] = 'NA';
            break;
          case 'todayGeneration':
            val[key] = 'NA';
            break;
          case 'lifetimeGeneration':
            val[key] = 'NA';
            break;
          case 'alert':
            val[key] = 'NA';
            break;
          case 'customerName':
            val[key] = 'NA';
            break;
          case 'savings':
            val[key] = 'NA';
            break;
          case 'roi':
            val[key] = 0;
            break;
          case 'treesPlanted':
            val[key] = 0;
            break;
          case 'co2Saved':
            val[key] = 'NA';
            break;
          case 'coalNotburned':
            val[key] = 'NA';
            break;
          case 'dataloggerSignalStrength':
            val[key] = 'NA';
            break;
          case 'plantCreationDate':
            val[key] = 'NA';
            break;
        }
      }
    }
    return val;
  }

  checkNonSolarRecord(val: non_solarplantResponseList) {
    let corrrectNonSolarKeys = non_solarFilterColumns.map(
      (x: filterColumns) => x.name,
    );

    let inputObjKeys = Object.keys(val);
    let pos: number = 0;
    for (var key of corrrectNonSolarKeys) {
      pos = inputObjKeys.indexOf(key);
      if (pos === -1) {
        switch (key) {
          case 'plantName':
            val[key] = 'NA';
            break;
          case 'lastUpdated':
            break;
          case 'location':
            val[key] = 'NA';
            break;
          case 'status':
            val[key] = 'NA';
            break;
          case 'dataloggerSN':
            val[key] = 'NA';
            break;
          case 'modelName':
            val[key] = 'NA';
            break;
          case 'inverterRating':
            val[key] = 'NA';
            break;
          case 'plantHealth':
            val[key] = 'NA';
            break;
          case 'alert':
            val[key] = 'NA';
            break;
          case 'customerName':
            val[key] = 'NA';
            break;
          case 'savings':
            val[key] = 0;
            break;
          case 'roi':
            val[key] = 0;
            break;
          case 'plantCreationDate':
            val[key] = 'NA';
            break;
          case 'batteryVoltage':
            val[key] = 'NA';
            break;
          case 'batteryState':
            val[key] = 'NA';
            break;
          case 'powerCutDuration':
            val[key] = 'NA';
            break;
          case 'noofPowerCut':
            val[key] = 0;
            break;
          case 'totalBackupCapacity':
            val[key] = 'NA';
            break;
          case 'currentBackupCapacity':
            val[key] = 'NA';
            break;
          case 'gridVoltage':
            val[key] = 'NA';
            break;
        }
      }
    }

    return val;
  }
}
