import { Component, OnInit, inject } from '@angular/core';
import { SeService } from '../../services/se.service';
import { getPlant, PlantDetails } from '../../../shared/models/plant-detail';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { NGXLoggerConfigEngineFactory } from 'ngx-logger';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PopupComponent } from '../popup/popup.component';
import { BaseComponent } from '../../../base/components/base.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-deleteplant',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  templateUrl: './deleteplant.component.html',
  styleUrl: './deleteplant.component.scss',
})
export class DeleteplantComponent extends BaseComponent {
  plantDetail?: PlantDetails;

  public seService: SeService = inject(SeService);
  inverterForm = new FormGroup({
    inverterId: new FormControl('', [
      Validators.required,

      Validators.pattern('^[a-zA-Z0-9]*$'),
      Validators.minLength(14),
      Validators.maxLength(14),
    ]),
  });

  public getInverterDetails: any = this.inverterForm.value;

  callAlert() {
    let inverter = this.inverterForm.value;
    this.spinner.show();
    this.seService
      .getPlantDetails(
        inverter.inverterId ? inverter.inverterId.toUpperCase() : '',
      )
      .subscribe({
        next: (value: getPlant) => {
          if (value.status == 200) {
            if (value.data == null) {
              this.toastr.info(value.message);
            } else {
              this.plantDetail = value.data;
            }
          }
          this.spinner.hide();
        },
        error: (value: getPlant) => {
          if (value.status == 400) {
            this.spinner.hide();
          }
        },
        complete: () => {
          this.spinner.hide();
        },
      });
  }

  constructor(private dialog: MatDialog) {
    super();
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = '300px';
    dialogConfig.data = {
      value: this.inverterForm.value.inverterId,
    };
    let ref = this.dialog.open(PopupComponent, dialogConfig);

    ref.afterClosed().subscribe((data) => {
      if (data && data == 1) {
        this.inverterForm.reset();
        this.plantDetail = undefined;
      }
    });
  }
}
