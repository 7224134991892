import {
  Component,
  Input,
  OnInit,
  ViewChild,
  effect,
  input,
} from '@angular/core';

import {
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexChart,
  ChartComponent,
  NgApexchartsModule,
  ApexFill,
  ApexStroke,
  ApexGrid,
  ApexResponsive,
} from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
  stroke: ApexStroke;
  grid: ApexGrid;
  responsive: ApexResponsive[];
};
@Component({
  selector: 'app-radial-bar',
  standalone: true,
  imports: [NgApexchartsModule],
  templateUrl: './radial-bar.component.html',
  styleUrl: './radial-bar.component.scss',
})
export class RadialBarComponent implements OnInit {
  @ViewChild('radialChart') chart!: ChartComponent;
  public chartOptions!: Partial<ChartOptions>;
  dataLabel = input.required<string>();
  dataSeries = input.required<number>();

  constructor() {
    effect(() => {
      this.chartOptions = {
        series: [this.dataSeries()],
        chart: {
          foreColor: '#50BF75',
          type: 'radialBar',
        },
        responsive: [
          {
            breakpoint: 900,
            options: {
              plotOptions: {
                radialBar: {
                  dataLabels: {
                    value: {
                      fontSize: '11px',
                    },
                    name: {
                      fontSize: '10px',
                    },
                  },
                },
              },
            },
          },
          {
            breakpoint: 1000,
            options: {
              plotOptions: {
                radialBar: {
                  dataLabels: {
                    value: {
                      fontSize: '12px',
                    },
                    name: {
                      fontSize: '11px',
                    },
                  },
                },
              },
            },
          },
        ],
        plotOptions: {
          radialBar: {
            track: {
              background: 'blue',
            },
            offsetY: 2,
            hollow: {
              size: '70%',
            },
            dataLabels: {
              value: {
                offsetY: -19,
                color: '#50BF75',
                fontWeight: 'bold',
                fontSize: '18px',
              },
              name: {
                offsetY: 18,
                fontWeight: 400,
                color: '#fff',
                fontSize: '14px',
                fontFamily: 'Roboto',
              },
            },
          },
        },
        grid: {
          padding: {
            top: -15,
            left: -15,
            right: -15,
            bottom: -15,
          },
        },
        labels: [this.dataLabel()],
        fill: {
          colors: ['#50BF75'],
        },
        stroke: {
          lineCap: 'round',
        },
      };
    });
  }

  ngOnInit(): void {
    this.chartOptions = {
      series: [this.dataSeries()],
      chart: {
        foreColor: '#50BF75',
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          track: {
            background: 'blue',
          },
          hollow: {
            size: '70%',
          },
          dataLabels: {
            value: {
              offsetY: -19,
              color: '#fff',
              fontWeight: 'bold',
              fontSize: '18px',
            },
            name: {
              offsetY: 18,
              fontWeight: 400,
              color: '#fff',
              fontSize: '14px',
              fontFamily: 'Roboto',
            },
          },
        },
      },
      labels: [this.dataLabel()],
      fill: {
        colors: ['#50BF75'],
      },
      stroke: {
        lineCap: 'round',
      },
    };
  }
}
