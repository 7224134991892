<div
  class="h-[100%] p-4 dark:bg-dimGrey text-white justify-center items-center"
>
  <div class="flex">
    <div>
      <label for="addcommentbox ml-[15%]"
        >State Reason <span class="text-[#ff0000]"> *</span>
      </label>
    </div>
    <div class="ml-[2%]"><span> &#58; </span></div>
  </div>
  <div class="h-[80%] w-[97%] mt-[4%] border-none">
    <form class="h-[100%]" [formGroup]="deleteForm">
      <div class="h-full border-none">
        <div class="h-[75%] flex flex-col">
          <textarea
            id="addcommentbox"
            type="text"
            required
            formControlName="commentBox"
            class="bg-dark-gray-content text-white border-none rounded-sm h-[100%] w-[100%] p-2"
          ></textarea>
          @if (
            deleteForm.controls.commentBox.invalid &&
            deleteForm.controls.commentBox.touched
          ) {
            <div class="mt-2">
              <span
                *ngIf="deleteForm.controls.commentBox.errors?.['required']"
                class="text-red-500 text-xs"
              >
                Comment is required
              </span>

              <span
                *ngIf="deleteForm.controls.commentBox.errors?.['minlength']"
                class="text-red-500 text-xs"
              >
                Comment must have at least 20 characters
              </span>
            </div>
          }
        </div>

        <div class="flex flex-row space-x-2 h-[15%] mt-4 w-[30%]">
          <button
            (click)="showConfirmationDialog()"
            class="text-white rounded-xl px-4 py-[5px] dark:bg-[#CC5151] border-none h-[100%]"
            [disabled]="!deleteForm.valid"
            [ngClass]="{
              'opacity-50 ': !deleteForm.valid
            }"
          >
            Confirm
          </button>
          <button
            class="bg-transparent px-4 py-[5px] dark:text-white rounded-xl border-solid border-[0.5px] border-gray-300 flex items-center space-x-2 cursor-pointer"
            (click)="cancelDialog()"
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
