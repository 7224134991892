import { CommonModule, Location } from '@angular/common';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BaseComponent } from '../../../../base/components/base.component';
import { ProductService } from '../../../service/product.service';
import { InverterType, PlantTypeEnum, StatsData } from '../../../models/stats';
import {
  INVERTER_TYPE,
  PLANT_TYPE,
  USER_KEY,
} from '../../../service/storage.service';
import { PlantItemData, PlantListData } from '../../../models/plants';

import { WeatherService } from '../../../service/weather.service';
import { WeatherResponse } from '../../../models/weather';
import {
  NotificationData,
  NotificationResponseData,
} from '../../../models/notification';
import { DateUtils, NoxMonth, NoxYear } from '../../../utils/date_utils';
import { TrendSelection, TrendType } from '../../../models/enum';
import { NoxDropdown, getTrendsDropDown } from '../../../models/dropdown';
import { EnergyFlowComponent } from '../../../../customer/components/energy-flow/energy-flow.component';
import { NoxDatePickerComponent } from '../../nox-date-picker/nox-date-picker.component';
import { ClickOutsideDirective } from '../../../utils/click.outside.directive';
import { User, UserType } from '../../../models/user';
import { environment } from '../../../../../environments/environment';
import { WeatherForecastComponent } from '../../weather-forecast/weather-forecast.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { PlantDetail } from '../../../models/plant';
import { TrendsComponent } from '../../trends/trends.component';
import { LottieComponent, AnimationOptions } from 'ngx-lottie';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChevronRight, faWifi } from '@fortawesome/free-solid-svg-icons';
import { PlantOnlineData } from '../../../models/plant-online-ratio';
import { Subject, takeUntil } from 'rxjs';
import { number } from 'echarts';
import {
  NoxDialogParam,
  NoxDialogType,
} from '../../../models/nox-dialog-param';
import { MatDialog } from '@angular/material/dialog';
import { NoxDialogComponent } from '../../nox-dialog/nox-dialog.component';

@Component({
  selector: 'app-customer-dashboard',
  standalone: true,
  templateUrl: './customer-dashboard.component.html',
  styleUrl: './customer-dashboard.component.scss',
  imports: [
    RouterModule,
    CommonModule,
    TranslateModule,
    EnergyFlowComponent,
    NoxDatePickerComponent,
    ClickOutsideDirective,
    WeatherForecastComponent,
    MatSidenavModule,
    LottieComponent,
    TrendsComponent,
    FontAwesomeModule,
  ],
})
export class CustomerDashboardComponent
  extends BaseComponent
  implements OnDestroy, OnInit
{
  previewImage(url?: string) {
    let noxDialogData: NoxDialogParam = {
      type: NoxDialogType.image,
      imageUrl: url,
    };
    this.dialog.open(NoxDialogComponent, {
      data: noxDialogData,
    });
  }
  addNewPlantPopup() {
    let noxDialogData: NoxDialogParam = {
      type: NoxDialogType.info,
      title: 'Adding New Plant?',
      messageList: [
        'Currently, adding a new plant is only available through the mobile app.',
        'To add new plant, ensure that your device is powered on.',
        'Check the functionality of the router connected to your device. ',
        'Check if the router has internet connection.',
        'Confirm your device is in range of the router. ',
        'Confirm the signal strength is good, or if any obstruction is affecting data transmission.',
        "Confirm whether the wifi router's name or password has been changed. If yes, then please configure the device. ",
      ],
      disclaimer:
        "During configuration, ensure you have the latest ConnectX app installed and that you're near the device with a strong WiFi signal.",
    };
    const dialogRef = this.dialog.open(NoxDialogComponent, {
      data: noxDialogData,
    });
  }

  activatePlant() {
    if (this.selectedPlant) {
      this.spinner.show();
      this.prodService.activatePlant(this.selectedPlant!.id).subscribe({
        next: (res) => {
          this.spinner.hide();
          if (res.status == 200 || res.status == 201) {
            this.isPlantDeactivated = false;
            this.toastr.success(res.message);
            this.resolveAllSubscriptions([
              this.fetchStats(this.selectedPlant!.id),
            ]);
          }
        },
        error: (err) => {
          this.logger.error(err);
          this.spinner.hide();
        },
      });
    }
  }
  getStrengthImage(strength: number | undefined) {
    let imageName = 'strength-' + strength;

    return imageName;
  }
  dropdownVisible = false;
  dialog = inject(MatDialog);

  yearDropdownVisible = false;
  notificationDropdownVisible = false;

  enableTrendDropdown = false;
  statData?: StatsData | null;
  isNav: number = 0;
  faChevronRight = faChevronRight;

  prodService = inject(ProductService);
  weatherService = inject(WeatherService);
  plantList?: PlantListData;
  selectedPlantId: string = '';
  currentWeather?: WeatherResponse;
  notificationList?: NotificationData[];

  trendSelection = TrendSelection.DAY;
  trendType = TrendType.POWER_GENERATION;
  platType = PlantTypeEnum;
  noxMonthsData = NoxMonth.noxMonthsData();
  yearList = NoxYear.yearList();
  todayDate = new Date();
  trendDropdown: NoxDropdown[] = getTrendsDropDown();
  selectedTrend!: NoxDropdown;

  inverterType = InverterType;
  selectedPlant?: PlantDetail | null;
  isDeviceOffline: boolean = true;
  plantRatioData?: PlantOnlineData;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  userType = UserType;
  plantData?: PlantDetail;
  isPlantDeactivated = false;
  isPlantEmptyUser = false;

  @ViewChild(WeatherForecastComponent) sideSheet!: WeatherForecastComponent;

  options: AnimationOptions[] = [
    {
      path: '/assets/lottie/pig-save.json',
    },
    {
      path: '/assets/lottie/roi.json',
    },
    {
      path: '/assets/lottie/pie-chart.json',
    },
  ];
  faWifi = faWifi;
  user: User | undefined;
  location = inject(Location);
  private destroy$ = new Subject<void>();

  constructor(private route: ActivatedRoute) {
    super();

    this.translateService.setDefaultLang('en');
    this.user = this.storageService.getObject(USER_KEY);
  }

  ngOnInit(): void {
    this.selectedTrend = this.trendDropdown[0];
    this.baseUserService.sessionService.startBnWatch(this.router);

    /**
     * fetch plant list
     */
    this.resolveAllSubscriptions([this.fetchPlantList()]);

    /**
     * Fetch data from query params
     */
    this.route.queryParams.subscribe((params) => {
      let plantId = params['plantId'];
      localStorage.setItem('pId', params['plantId']);
      this.prodService.plantList.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next: (plantResponse) => {
          if (plantResponse) {
            this.plantList = plantResponse;
            if (!plantId) {
              if (this.plantList.primaryPlant.length > 0) {
                plantId = this.plantList.primaryPlant[0].id;
              } else if (this.plantList.secondaryPlant.length) {
                plantId = this.plantList.secondaryPlant[0].id;
              } else {
                this.toastr.info('No plant found');
              }
            }

            const combinedPlantList: PlantItemData[] = [
              ...this.plantList.primaryPlant,
              ...this.plantList.secondaryPlant,
            ];
            const selectedPlant = (id: string): PlantItemData | undefined => {
              return combinedPlantList.find((plant) => plant.id === id);
            };
            this.isPlantDeactivated = selectedPlant(plantId)?.deleted ?? false;
            if (this.user?.user_type != UserType.SYSTEM_INTEGRATOR) {
              this.isPlantEmptyUser =
                this.plantList?.primaryPlant.length == 0 &&
                this.plantList?.secondaryPlant.length == 0;
            }
            if (!this.isPlantDeactivated && !this.isPlantEmptyUser) {
              this.resolveAllSubscriptions([this.fetchStats(plantId)]);
            }
          }
        },
        error: (err) => {},
      });
    });
    this.prodService.selectedPlant.subscribe((plant) => {
      this.selectedPlant = plant;
      const plantName = plant?.plantName;
      const string = '';
      localStorage.setItem('plantname', plantName ? plantName : '');
    });
  }

  fetchPlantList() {
    let loaderSubject = new Subject<boolean>();
    let user: User = this.storageService.getObject(USER_KEY);

    this.prodService.getPlantList(user.id).subscribe({
      next: (plantResponse) => {
        if (
          plantResponse.status == 204 &&
          this.user?.user_type != UserType.SYSTEM_INTEGRATOR
        ) {
          this.toastr.info('No plant found');
          this.plantList = {
            primaryPlant: [],
            secondaryPlant: [],
          };
          this.isPlantEmptyUser = true;
        } else {
          this.plantList = plantResponse.body?.data;
        }

        loaderSubject.next(true);
      },
      error: (err) => {
        this.logger.error(err);
        loaderSubject.next(true);
      },
      complete: () => loaderSubject.complete(),
    });
    return loaderSubject;
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.destroy$.next();
    this.destroy$.complete();
    this.baseUserService.sessionService.stopBnTimer();
  }

  /**
   * Fetch plant overview data one after another
   */
  fetchPlantOverview() {
    this.fetchPlantDetail();
    this.fetchNotification();
    this.fetchPlantOnlineRatio();
  }

  /**
   * Plant online ratio
   */
  fetchPlantOnlineRatio() {
    this.prodService
      .getPlantOnlineRatio(this.selectedPlantId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (plantResponse) => {
          this.plantRatioData = plantResponse.data;
        },
        error: (err) => {
          this.logger.error(err);
        },
      });
  }

  /**
   * Fetch particular data of plant
   */
  fetchPlantDetail() {
    //
    this.prodService
      .getPlantDetail(this.selectedPlantId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (plantResponse) => {
          this.plantData = plantResponse.data;
          this.fetchWeather(this.plantData.lat, this.plantData.lon);
        },
        error: (err) => {
          this.logger.error(err);
        },
      });
  }

  /**
   * Fetch notification of plant
   */
  fetchNotification() {
    let user: User = this.storageService.getObject(USER_KEY);

    this.prodService
      .getNotifications(this.selectedPlantId, user.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (notificationResponse: NotificationResponseData) => {
          let notifications: NotificationData[] = [];
          notificationResponse?.data.forEach((notification) => {
            notification.notificationDate = DateUtils.formatDateFromLocalEpoch(
              notification.data.timestamp * 1000,
              'DD/MM/YYYY hh:mm a',
            );
            notifications.push(notification);
          });
          this.notificationList = notifications;
        },
        error: (err) => {
          this.logger.error(err);
        },
      });
  }

  /** Fetch weather from open weather API */
  fetchWeather(lat: number, lng: number) {
    this.weatherService
      .getWeatherByLatLng(
        lat,
        lng,
        'metric',
        environment.externalUrls.openWeather.apiKey,
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (weatherResponse) => {
          const iconUrl = `https://openweathermap.org/img/wn/${weatherResponse.weather[0].icon}@2x.png`; // Construct the icon URL
          weatherResponse.iconUrl = iconUrl;
          this.currentWeather = weatherResponse;
        },
        error: (err) => {
          this.logger.error(err);
        },
      });
  }

  /**
   * Fetch stats of plant
   * @param plantId
   */
  fetchStats(plantId: string) {
    this.notificationList = [];
    this.isDeviceOffline = true;
    let loaderSubject = new Subject<boolean>();
    loaderSubject.next(true);
    loaderSubject.complete();
    this.prodService
      .getStats(plantId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (statResponse) => {
          this.statData = statResponse.data;
          this.storageService.set(INVERTER_TYPE, this.statData.inverterType);
          if (this.statData.inverterType == 'GTI') {
            this.baseUserService.inverterType.next(true);
          } else {
            this.baseUserService.inverterType.next(false);
          }

          this.storageService.set(PLANT_TYPE, this.statData.plantType);
          this.selectedPlantId = plantId;
          let strength = this.statData.stats.wifi_signal_strength;
          localStorage.setItem('strength', JSON.stringify(strength));
          localStorage.setItem('deviceId', statResponse.data.deviceId);
          this.isDeviceOffline = this.checkDeviceOffline();
          localStorage.setItem(
            'devicestatus',
            JSON.stringify(this.isDeviceOffline),
          );
          this.fetchPlantOverview();
          loaderSubject.next(true);
        },
        error: (err) => {
          this.statData = null;
          this.selectedPlantId = plantId;

          this.logger.error(err);
          loaderSubject.next(true);
          this.fetchPlantOverview();
          loaderSubject.complete();
        },
        complete: () => loaderSubject.complete(),
      });
    return loaderSubject;
  }

  /**
   *
   * @param language Switch language
   */
  override switchLanguage(language: string) {
    this.translateService.use(language);
  }

  /**
   * Toggle notification
   */
  toggleNotificationDropdown() {
    this.notificationDropdownVisible = !this.notificationDropdownVisible;
  }

  /**
   * Navigate to plant detail
   */
  navigateToPlantDetail() {
    this.router.navigate(['customer/plant-detail', this.selectedPlantId], {
      state: { reset: false },
    });
  }

  navigateToSolarRoofTopDetail(arg0: PlantDetail | undefined) {
    if (arg0 && arg0.lat && arg0.lon) {
      this.baseUserService.selectedPlantDetail.next(arg0);
      this.router.navigate(['customer/solar-rooftop']);
    } else {
      this.toastr.info('Plant not able to locate');
    }
  }

  /**
   *
   * @returns
   */
  private checkDeviceOffline(): boolean {
    if (!this.statData?.stats) {
      return true;
    }
    // Current timestamp
    const currentTime: number = Date.now();

    // Response timestamp
    const responseTime: number =
      this.statData?.stats.last_updated_timestamp * 1000;

    // Difference in hours
    const hours: number = DateUtils.calculateHoursDifference(
      currentTime,
      responseTime,
    );

    // If offline for more than 1 hour, short circuit and turn off wifi
    if (hours > 1) {
      return true;
    }
    return false;
  }
  clickOutside(selection: string) {
    if (selection === 'YEARLY') {
      this.yearDropdownVisible = false;
    } else if (selection === 'MONTHLY') {
      this.dropdownVisible = false;
    } else if (selection === 'NOTIFICATION') {
      this.notificationDropdownVisible = false;
    }
  }
  goBack(): void {
    if (this.user && this.user?.user_type === UserType.SYSTEM_INTEGRATOR) {
      let url = `/system-integrator/plant-management`;
      this.baseUserService.router.navigate([url]);
    }
  }
}
