import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { StorageService, TOKEN } from '../service/storage.service';
import { NgxSpinnerService } from 'ngx-spinner';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const toastr = inject(ToastrService);
  const router = inject(Router);
  const storageService = inject(StorageService);
  const logger = inject(NGXLogger);
  const spinner = inject(NgxSpinnerService);

  return next(req).pipe(
    catchError((err) => {
      const token = storageService.get(TOKEN);
      if (err instanceof HttpErrorResponse) {
        let errorMessage = err.error?.message;
        if (errorMessage == null || errorMessage == undefined) {
          if (err.status.toString().startsWith('20')) {
            next(req);
          } else {
            logger.info(err);
            /**
             * backend send data in different error format, hence mapping the error message
             */
            if (err.error) {
              if (err.error['cause']) {
                errorMessage = err.error['cause'];
              } else if (err.status === 0) {
                errorMessage = 'Unknown error occur';
              } else {
                errorMessage = err.error;
              }
            } else {
              switch (err.status) {
                case 400:
                  errorMessage =
                    'Bad Request: The server cannot process the request due to a client error.';
                  break;
                case 401:
                  errorMessage =
                    'Unauthorized: Username or password is incorrect';

                  break;
                case 403:
                  errorMessage =
                    'Forbidden: The client does not have permission to access the requested resource.';
                  break;
                case 404:
                  errorMessage =
                    'Not Found: The requested resource could not be found on the server.';
                  break;
                case 408:
                  errorMessage =
                    'Request Timeout: The server did not receive a complete request within the time it was prepared to wait.';
                  break;
                case 409:
                  //errorMessage = 'Request failed: Client failed to verify '; // comment out since handle individually

                  break;
                case 500:
                  errorMessage =
                    'Internal Server Error: The server encountered an unexpected condition that prevented it from fulfilling the request.';
                  break;
                case 503:
                  errorMessage =
                    'Service Unavailable: The server is currently unable to handle the request due to temporary overload or maintenance of the server.';
                  break;
                default:
                  errorMessage = 'An error occurred. Please try again later.';
                  break;
              }
            }
          }
        }
        if (errorMessage) {
          // Show the error message at the end
          if (!skippedError(err, errorMessage)) {
            toastr.error(errorMessage);
          }

          if (err.status === 401) {
            storageService.clean();
            router.navigate(['/login'], {
              queryParams: { type: 'customer' },
            });
          }
          spinner.hide();
        }
      }

      // Re-throw the error to propagate it further
      return throwError(() => err);
    }),
  );
};
/**
 * Method wast introduce since backend is sending 400 code even when the
 * request is success in fetch state details for no record
 *
 * @param err
 * @param errorMessage
 * @returns
 */
function skippedError(err: HttpErrorResponse, errorMessage: string) {
  if (err.status === 400) {
    if (errorMessage.includes('Plant Stats Details Not Found')) {
      return true;
    }
  }
  return false;
}
