<div class="main w-full h-full overflow-scroll">
  <div
    class="w-full h-full block flex-col items-center justify-center min-w-[920px] min-h-[650px]"
    style="text-align: -webkit-center"
  >
    <!-- Header -->
    <div class="w-[99%] h-[10%]">
      <app-plant-details [categoryId]="'3'"></app-plant-details>
    </div>
    <!-- First row -->
    <div class="w-[99%] h-[45%] items-center flex flex-row space-x-[1%] mt-4">
      <div
        class="w-[67%] h-[95%] dark:bg-btTh flex items-center rounded-2xl p-4"
      >
        <div
          class="flex flex-row rounded-2xl dark:bg-btTh text-white h-full w-full"
        >
          <div class="w-[50%]">
            <div class="flex flex-col h-full">
              <div class="flex justify-start pt-3 pl-3">
                <span class="gradient-header">{{
                  "non_solar" | translate
                }}</span>
              </div>
              <div class="flex-1 flex-grow content-center">
                <div class="flex flex-row justify-evenly items-center">
                  <div class="relative flex justify-center items-center">
                    <img
                      src="../../../../../assets/svg/non-solar-home.svg"
                      class="w-[70%]"
                      alt="Solar home"
                    />
                    <div class="h-14 absolute -bottom-8 -right-4">
                      <div class="relative flex justify-center items-center">
                        <img
                          src="../../../../../assets/svg/non-solar-battery.svg"
                          alt="Battery"
                          class="w-[80%]"
                        />
                        <span
                          class="absolute transform translate-x-[-50%] translate-y-[-50%]"
                          >{{
                            powerCutDetailsAndSummary?.backupCapacityPercentage ??
                              "0"
                          }}
                          %</span
                        >
                        <!-- New overlapping div -->
                        <div
                          class="absolute top-0 left-[10px] w-full h-full flex flex-grow"
                        >
                          <div
                            class="bg-green-500 opacity-50 my-[2px] me-[6px]"
                            [ngStyle]="{
                              width: calculateProgress(
                                powerCutDetailsAndSummary?.backupCapacityPercentage ??
                                  0
                              )
                            }"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="flexDirCol justify-center items-start xl:space-y-7 lg:space-y-5 space-y-3"
                  >
                    <div>
                      <span class="label-sm text-dark-gray-content"
                        >Total Backup Capacity</span
                      >
                    </div>
                    <div>
                      <span class="value-small">
                        {{
                          powerCutDetailsAndSummary?.totalBackupCapacity ?? "NA"
                        }}</span
                      >
                    </div>
                    <hr class="w-[100%] opacity-30" />
                    <div>
                      <span class="label-sm text-dark-gray-content"
                        >Current Backup Capacity</span
                      >
                    </div>
                    <div>
                      <span class="value-small">
                        {{
                          powerCutDetailsAndSummary?.currentBackupCapacity ??
                            "NA"
                        }}</span
                      >
                    </div>
                  </div>
                  <div
                    class="bg-slate-400 w-[1.5px] md:ml-6 lg:ml-9 h-[11rem] opacity-45"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-[50%] h-full">
            <app-powercut-and-summary
              [categoryId]="'3'"
            ></app-powercut-and-summary>
          </div>
        </div>
      </div>
      <div class="w-[32%] h-[95%] dark:bg-btTh items-center rounded-2xl p-4">
        <div class="h-full flex flex-col">
          <div class="flex justify-between pt-3 pl-3">
            <div
              class="gradient-header leading-[18px] font-medium text-base flex"
            >
              <span class="gradient-header"> Plant Health</span>
            </div>
          </div>
          <div class="h-full flex flex-grow gap-10">
            <div
              class="flex flex-col justify-center items-center w-[30%] text-white"
            >
              <app-radial-bar
                [dataLabel]="'Healthy'"
                [dataSeries]="nonSolarPlant.healthyPlantPercent ?? 0"
              ></app-radial-bar>
            </div>
            <div class="flexDirCol justify-center w-[55%] space-y-4">
              <div class="flex justify-between text-white">
                <div>
                  <span class="label-sm text-dark-gray-content"
                    >Total Plants
                  </span>
                </div>

                <div>
                  <span class="value-xs">
                    {{ nonSolarPlant.totalPlant ?? "NA" }}</span
                  >
                </div>
              </div>

              <div class="flex justify-between text-white">
                <div>
                  <span class="label-sm text-dark-gray-content">
                    Healthy Plants</span
                  >
                </div>

                <div>
                  <span class="value-xs">
                    {{ nonSolarPlant.healthyPlant ?? "NA" }}</span
                  >
                </div>
              </div>

              <div class="flex justify-between text-white">
                <div>
                  <span class="label-sm text-dark-gray-content"
                    >Unhealthy Plants</span
                  >
                </div>

                <div>
                  <span class="value-xs">
                    {{ nonSolarPlant.unhealthyPlant ?? "NA" }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Second row -->
    <div class="w-[99%] h-[45%] items-center flex flex-row space-x-[1%] mt-8">
      <div class="w-[67%] h-[95%] dark:bg-btTh items-center rounded-2xl p-4">
        <app-trends [categoryId]="'2'"></app-trends>
      </div>
      <div class="w-[32%] h-[95%] dark:bg-btTh items-center rounded-2xl p-4">
        <app-today-alerts [categoryId]="'3'"></app-today-alerts>
      </div>
    </div>
  </div>
</div>
