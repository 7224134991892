import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { SubHeaderComponent } from '../sub-header/sub-header.component';
import {
  faRotateRight,
  faDownload,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BaseComponent } from '../../../base/components/base.component';
import { CommonModule } from '@angular/common';
import { SiService } from '../../../system-integrator/services/si.service';
import {
  TodayAlertDetails,
  TodayAlertDetailsData,
} from '../../models/today-alert-details';
import { AlertTypes } from '../../interfaces/alert-types';
import {
  TodayAlerts,
  TodayAlertsListDetails,
} from '../../models/today-alerts-list-details';
import { BaseUtilService } from '../../../base/services/base-util.service';
import { ActivatedRoute, Params } from '@angular/router';
import {
  alertHeaders,
  sharedheaderKeyMap,
} from '../../constants/shared.constants';
import { plainObject } from '../../interfaces/plain-obj';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-alerts',
  standalone: true,
  imports: [SubHeaderComponent, FontAwesomeModule, CommonModule],
  templateUrl: './alerts.component.html',
  styleUrl: './alerts.component.scss',
})
export class AlertsComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  faDownload = faDownload;
  faRotateRight = faRotateRight;
  faChevronLeft = faChevronLeft;
  todayAlertDetailsSubscription!: Subscription;
  todayAlertsListDetailsSubscription!: Subscription;

  showFilter: boolean = false;
  category: string = '1';

  alertsDetails: TodayAlertDetailsData = {
    normalAlerts: 0,
    abnormalAlerts: 0,
    abnormalAlertsPlantCount: 0,
    normalAlertsPlantCount: 0,
  };

  alertsTypesData: Array<AlertTypes> = [
    {
      name: 'Total Alerts',
      value: 160,
    },
    {
      name: 'Normal Alerts',
      value: 16,
    },
    {
      name: 'Abnormal Alerts',
      value: 144,
    },
  ];

  alertsData: Array<TodayAlerts> = [];

  alertHeaders: Array<string> = alertHeaders;

  alertKeyMap: plainObject = sharedheaderKeyMap;
  installer_id: string = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private Si_service: SiService,
    private util_service: BaseUtilService,
  ) {
    super();
  }

  ngOnInit(): void {
    let user = this.authService.loggedUser;

    this.activatedRoute.params.subscribe((params: Params) => {
      let type = params['type'];
      switch (type) {
        case 'all':
          this.category = '1';
          break;
        case 'solar':
          this.category = '2';
          break;
        case 'non_solar':
          this.category = '3';
          break;

        default:
          break;
      }

      // Use receivedData as needed
    });
    this.baseUserService.sessionService.startBnWatch(this.router);

    this.installer_id = user.installer_id;
    this.resolveAllSubscriptions([this.getTodayAlerts()]);

    let x = this.util_service.unixToDate(1713173144);
  }

  back() {
    this.baseUserService.location.back();
  }

  toggleFilter() {
    this.showFilter = !this.showFilter;
  }

  getTodayAlerts() {
    let loaderSubject = new Subject<boolean>();
    this.todayAlertDetailsSubscription = this.Si_service.getTodayAlertDetails(
      this.installer_id,
      this.category,
    ).subscribe({
      next: (val: TodayAlertDetails) => {
        if (val.data != null) {
          this.alertsDetails = val.data;
        }
        this.alertsTypesData.forEach((x: AlertTypes) => {
          switch (x.name) {
            case 'Total Alerts':
              x.value =
                this.alertsDetails.abnormalAlerts +
                this.alertsDetails.normalAlerts;
              break;
            case 'Normal Alerts':
              x.value = this.alertsDetails.normalAlerts;
              break;
            case 'Abnormal Alerts':
              x.value = this.alertsDetails.abnormalAlerts;
              break;

            default:
              break;
          }
          loaderSubject.next(true);
        });
      },
      error: (e: Error) => {
        this.logger.error(e.message);
        loaderSubject.next(true);
      },
      complete: () => loaderSubject.complete(),
    });

    this.todayAlertsListDetailsSubscription =
      this.Si_service.getTodayAlertsListDetails(
        this.installer_id,
        this.category,
      ).subscribe({
        next: (x: TodayAlertsListDetails) => {
          this.alertsData = this.convertTime(x.data);
        },
      });
    return loaderSubject;
  }

  convertTime(data: Array<TodayAlerts>) {
    data.forEach((element: TodayAlerts) => {
      element.occurred = this.util_service.unixToDate(+element.occurred);
      element.resolved = element.resolved
        ? this.util_service.unixToDate(+element.resolved)
        : '-';
    });
    return data;
  }

  ngOnDestroy(): void {
    this.todayAlertDetailsSubscription.unsubscribe();
    this.todayAlertsListDetailsSubscription.unsubscribe();
    this.baseUserService.sessionService.stopBnTimer();
  }
}
