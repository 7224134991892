<ngx-spinner
  bdColor="rgba(0, 0, 0, 1)"
  size="medium"
  color="#fff"
  type="square-spin"
  [fullScreen]="true"
>
  <p style="color: white">Loading...</p>
</ngx-spinner>
<router-outlet></router-outlet>
