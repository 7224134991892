import {
  INR_CRORE,
  INR_LAKH,
  INR_THOUSAND,
} from '../constants/shared.constants';

export class NumberUtil {
  static formatCurrencyNumber(number: number) {
    if (typeof number !== 'number') {
      return 'Invalid input'; // return error message if the argument is not a number
    }

    let formattedNumber = '';
    if (number > INR_CRORE) {
      formattedNumber = (number / INR_CRORE).toFixed(2) + ' Cr';
    } else if (number > INR_LAKH) {
      formattedNumber = (number / INR_LAKH).toFixed(2) + ' Lac';
    } else if (number > INR_THOUSAND) {
      formattedNumber = (number / INR_THOUSAND).toFixed(2) + ' K';
    } else {
      formattedNumber = number.toFixed(2); // Keep the number as is if it's less than a thousand
    }

    return formattedNumber;
  }
}
