<div
  class="datepicker-container"
  onClickOutside
  (onClickOutside)="clickOutside()"
>
  <span
    type="text"
    (click)="toggleDatePicker()"
    readonly
    class="cursor-default bg-transparent border-none text-white text-xs"
    >{{ selectedDate | date: "dd/MM/yyyy" }}</span
  >

  @if (enableDatePicker) {
    <div class="datepicker">
      <div
        class="datepicker-header flex flex-row justify-center items-center pb-2 space-x-3"
      >
        <img
          src="../../../../assets/svg/arrow_back.svg"
          alt="Back"
          (click)="prevMonth()"
        />

        <div>
          <select
            hidden
            [(ngModel)]="month"
            (change)="changeMonth($any($event.target).value)"
            disabled="true"
          >
            <option
              *ngFor="let month of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]"
              [value]="month"
            >
              {{ month }}
            </option>
          </select>
          <input
            hidden
            type="number"
            [(ngModel)]="year"
            (change)="changeYear($any($event.target).value)"
            readonly
          />
        </div>
        <span class="text-white text-xs">{{ month | noxDate }}</span>
        <span class="text-white text-xs">{{ year }}</span>

        <img
          src="../../../../assets/svg/arrow_forward.svg"
          alt="Next"
          (click)="
            checkNextMonthStatus(
              daysInMonth[daysInMonth.length - 1],
              month,
              year
            )
              ? nextMonth()
              : null
          "
          [class]="
            checkNextMonthStatus(
              daysInMonth[daysInMonth.length - 1],
              month,
              year
            )
              ? 'opacity-100'
              : 'opacity-50'
          "
        />
      </div>

      <div class="days cursor-auto">
        <span>Su</span>
        <span>Mo</span>
        <span>Tu</span>
        <span>We</span>
        <span>Th</span>
        <span>Fr</span>
        <span>Sa</span>
      </div>

      <div class="dates">
        <!-- 
        pass date
       -->
        @for (day of daysInPrevMonth; track $index) {
          <button [disabled]="true">
            {{ day }}
          </button>
        }
        <!-- 
        this month date
       -->

        @for (day of daysInMonth; track $index) {
          <button
            (click)="selectDate(day)"
            [disabled]="getDate(day, year, month) > todayDate"
          >
            {{ day }}
          </button>
        }
        <!-- 
        future month date
       -->
        @for (day of daysInNextMonth; track $index) {
          <button [disabled]="true">
            {{ day }}
          </button>
        }
      </div>
    </div>
  }
</div>
