import { InverterType } from './stats';

export enum TrendSelection {
  DAY = 'DAY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
  CUSTOM = 'CUSTOM',
}

export enum TrendType {
  POWER_GENERATION = 'POWER_GENERATION',
  POWER_CONSUMPTION = 'POWER_CONSUMPTION',
  POWER_CUT = 'POWER_CUT',
}

export enum StringInfoType {
  VOLTAGE = 'VOLTAGE',
  CURRENT = 'CURRENT',
  POWER = 'POWER',
}
export enum FAQType {
  faq,
  dos,
  dont,
}
export enum NoxInverterType {
  GTI = 'GTI',
  PCU = 'PCU',
  REGALIA_W = 'REGALIA W',
  REGALIA_F = 'REGALIA F',
  ZELIO = 'ZELIO',
  ZELIO_WIFI_V2 = 'ZELIO WIFI V2',
  ICRUZE = 'ICRUZE',
  SOLARVERTERPRO = 'SOLARVERTERPRO',
  HYBRID = 'HYBRID',
  LIIONSOLARVERTER = 'LIIONSOLARVERTER',
  LITHIUMVERTER = 'LITHIUMVERTER',
  OPTIMUS = 'OPTIMUS',
  BATTERY = 'BATTERY',
}

export enum NoxAlertType {
  SHORT_CIRCUIT = 'Short Circuit',
  INVERTER_OVERHEATED = 'Inverter Overheated',
  SHORT_CIRCUIT_AND_INVERTER_OVERHEATED = 'Short Circuit and Inverter Overheated',
  OFFLINE = 'Offline',
}
