<div class="py-[8px] rounded-full bg-[#434E5D]">
  <a
    *ngFor="let tab of tabs; let i = index"
    (click)="selectTab(i)"
    [class]="getTabClass(i)"
    class="px-10 py-[10px] cursor-pointer transition duration-300"
  >
    <span class="label-sm"> {{ tab }}</span>
  </a>
</div>
