<!-- Change password div -->

<div
  class="relative flex flex-grow items-center justify-center h-full m-auto dark-gradient-bg"
>
  <div
    class="flex flex-col flex-grow justify-start items-start bg-[#1d232b] rounded-lg absolute top-0 bottom-0 left-0 right-0 mx-5 my-5"
  >
    <div
      class="flex flex-row items-center mt-10 cursor-pointer"
      (click)="goBack()"
    >
      <img
        class="ml-10"
        src="../../../../assets/images/customer_screens/back arrow.svg"
        alt="Back Arrow"
      />
      <span class="text-white ml-4">Back</span>
    </div>
    <div class="flex flex-col items-center justify-center w-full h-full">
      <div
        class="flex flex-row justify-center items-center flex-grow absolute end-0 start-0 p-5 mb-10"
      >
        <div class="hidden md:w-2/5 md:flex justify-center items-center">
          <img
            src="../../../../assets/svg/forget_password.svg"
            class="mx-auto transform -translate-x-1/2 object-fill w-3/6 h-3/6"
            alt="Forget Password"
          />
        </div>
        <div class="relative w-full md:w-3/5 h-full">
          <div class="h-full flex flex-col justify-center items-center">
            <img
              src="../../../../../assets/svg/forget_password.svg"
              alt="Forget Password"
              class="block md:hidden h-32 w-32 object-fill mb-4"
            />
            <form
              [formGroup]="changePasswordForm"
              class="mt-5"
              (ngSubmit)="onSubmit()"
            >
              <div class="flex flex-col mb-6">
                <span
                  class="text-lg font-medium mb-2 text-white w-64 md:w-auto gradient-text"
                  >{{ "change_password" | translate }}</span
                >
                <label
                  class="block text-white mb-2 opacity-80 mt-8"
                  for="currentPassword"
                  >{{ "current_password" | translate }}</label
                >
                <div class="flex flex-col md:flex-row flex-grow md:space-x-4">
                  <input
                    formControlName="currentPassword"
                    class="w-64 box-border border border-gray-300 rounded-2xl py-2 px-3 text-white leading-tight bg-[#212932]"
                    id="currentPassword"
                    type="password"
                    placeholder="******"
                  />
                </div>
                <div
                  *ngIf="
                    changePasswordForm.controls.currentPassword.errors?.[
                      'required'
                    ] && changePasswordForm.controls.currentPassword.touched
                  "
                >
                  <p class="text-red-500 text-xs">
                    Current password is required.
                  </p>
                </div>
              </div>
              <div class="mb-1 mt-8">
                <div class="flex flex-row">
                  <div>
                    <label
                      class="block text-white font mb-2 opacity-80"
                      for="password"
                      >{{ "New Password" | translate }}</label
                    >
                    <input
                      formControlName="password"
                      class="w-64 box-border border border-gray-300 rounded-2xl py-2 px-3 text-white leading-tight bg-[#212932]"
                      id="password"
                      type="password"
                      placeholder="********"
                    />
                    <div
                      *ngIf="
                        changePasswordForm.controls.password.errors?.[
                          'required'
                        ] && changePasswordForm.controls.password.touched
                      "
                    >
                      <p class="text-red-500 text-xs">Password is required.</p>
                    </div>
                    <div
                      *ngIf="
                        changePasswordForm.controls.password.errors?.[
                          'minlength'
                        ] && changePasswordForm.controls.password.touched
                      "
                    >
                      <p class="text-red-500 text-xs">
                        Password must be at least 8 characters long.
                      </p>
                    </div>
                  </div>
                  <div class="ml-4">
                    <label
                      class="block text-white font mb-2 opacity-80"
                      for="confirmPassword"
                      >{{ "confirm_password" | translate }}</label
                    >
                    <input
                      formControlName="confirmPassword"
                      class="w-64 box-border border border-gray-300 rounded-2xl py-2 px-3 text-white leading-tight bg-[#212932]"
                      id="confirmPassword"
                      type="password"
                      placeholder="********"
                    />
                    <div
                      *ngIf="
                        changePasswordForm.controls.confirmPassword.errors?.[
                          'required'
                        ] && changePasswordForm.controls.confirmPassword.touched
                      "
                    >
                      <p class="text-red-500 text-xs">
                        Confirm password is required.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <button
                class="bg-dark-accent-secondary hover:bg-blue-700 text-white font py-2 px-4 rounded-xl md:w-auto w-full border-none mt-4"
                type="submit"
                [ngClass]="{
                  ' opacity-35': !changePasswordForm.valid
                }"
                [disabled]="!changePasswordForm.valid"
              >
                {{ "update_password" | translate }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
