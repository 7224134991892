@switch (data.type) {
  @case (noxAlertType.image) {
    <div class="max-w-[720px] max-h-[500px] relative">
      <div class="flex flex-row items-end relative">
        <div class="top-shadow"></div>
        <div class="absolute top-0 right-0 w-full">
          <div class="flex flex-row pt-3 pr-3 justify-end items-end">
            <fa-icon
              [icon]="faClose"
              class="text-white"
              (click)="closeDialog()"
            ></fa-icon>
          </div>
        </div>
        <!-- Shadow overlay -->
        <img
          [src]="data.imageUrl"
          alt="preview"
          class="max-w-[720px] max-h-[500px]"
        />
      </div>
    </div>
  }
  @default {
    <div
      class="flex flex-col px-4 py-7 bg-[#29323E]"
      [ngClass]="{ 'max-w-[650px]': data.dialogSize == dialogSize.medium }"
      [ngClass]="{ 'max-w-[350px]': data.dialogSize == dialogSize.small }"
    >
      <div class="flex flex-row items-end">
        @if (data.type == noxAlertType.none) {
          <div class="absolute top-0 right-0 w-full">
            <div class="flex flex-row justify-between p-4">
              <span class="text-white text-[15px]">{{ data.title }}</span>

              <fa-icon
                [icon]="faClose"
                class="text-white"
                (click)="closeDialog()"
              ></fa-icon>
            </div>
          </div>
        } @else {
          <div class="absolute top-0 right-0 w-full">
            <div class="flex flex-row justify-between p-4">
              <span class="text-white label-xl">Alert</span>
              <fa-icon
                [icon]="faClose"
                class="text-white"
                (click)="closeDialog()"
              ></fa-icon>
            </div>
          </div>

          <span class="text-white text-[15px] mt-5">{{ data.title }}</span>
        }
      </div>
      @if (data.messageList) {
        <div class="flex flex-col pt-4">
          @for (item of data.messageList; track $index) {
            <span class="dark:text-white mt-4">
              {{ $index + 1 }}. {{ item }}
            </span>
          }
        </div>
      }
      @if (data.disclaimer) {
        <div class="flex flex-col pt-2">
          <span class="text-white text-sm opacity-50">Note</span>
          <span class="dark:text-white">{{ data.disclaimer }}</span>
        </div>
      }
      @if (data.type == "confirm") {
        <div class="flex flex-row justify-center items-center space-x-4 mt-7">
          <div
            class="dark:bg-[#CC5151] px-4 py-[5px] dark:text-white rounded-xl flex items-center space-x-2 cursor-pointer"
            (click)="positiveListener()"
          >
            <span> {{ data.positiveBtnLabel ?? "Remove" }}</span>
          </div>
          <div
            class="dark:bg-none px-4 py-[5px] dark:text-white rounded-xl border-solid border-[0.5px] border-gray-300 flex items-center space-x-2 cursor-pointer"
            (click)="negativeListener()"
          >
            <span> {{ data.negativeBtnLabel ?? "Cancel" }}</span>
          </div>
        </div>
      }
    </div>
  }
}
