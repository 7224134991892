import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  NoxDialogParam,
  NoxDialogSize,
  NoxDialogType,
} from '../../models/nox-dialog-param';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-nox-dialog',
  standalone: true,
  imports: [FontAwesomeModule, CommonModule],
  templateUrl: './nox-dialog.component.html',
  styleUrl: './nox-dialog.component.scss',
})
export class NoxDialogComponent {
  cancel() {}
  faClose = faClose;
  data: NoxDialogParam;
  dialogSize = NoxDialogSize;
  noxAlertType = NoxDialogType;
  constructor(
    @Inject(MAT_DIALOG_DATA) data: NoxDialogParam,
    public dialogRef: MatDialogRef<NoxDialogComponent>,
  ) {
    this.data = data;
  }
  closeDialog() {
    this.dialogRef.close();
  }
  positiveListener() {
    /**
     * Listener added in component
     */
  }
  negativeListener() {
    /**
     * Listener added in component
     */
  }
}
