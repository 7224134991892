import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  inject,
  signal,
} from '@angular/core';
import { TodayAlertsComponent } from '../../today-alerts/today-alerts.component';
import { TrendsComponent } from '../../trends/trends.component';
import { PowercutAndSummaryComponent } from '../../powercut-and-summary/powercut-and-summary.component';
import { PlantDetailsComponent } from '../../plant-details/plant-details.component';
import { TranslateModule } from '@ngx-translate/core';
import { RadialBarComponent } from '../../radial-bar/radial-bar.component';
import { Subject, takeUntil } from 'rxjs';
import { SiService } from '../../../../system-integrator/services/si.service';
import { BaseComponent } from '../../../../base/components/base.component';
import { TodayAlertDetails } from '../../../models/today-alert-details';
import { TotalPlantsSI } from '../../../models/total-plants-si';
import { AllPlantDetails } from '../../../models/all-plant-details';
import { PlantHealthDetails } from '../../../models/plant-health-details';
import {
  TodayPowerCutDetails,
  TodayPowerCutDetailsData,
} from '../../../models/today-power-cut-details';
import { Router } from '@angular/router';
import { SessionService } from '../../../service/session.service';
import { AuthService } from '../../../service/auth.service';
import { CommonModule } from '@angular/common';
import { convertValueToBoundary } from '../../../utils/common-utils';

@Component({
  selector: 'app-non-solar-dashboard',
  standalone: true,
  templateUrl: './non-solar-dashboard.component.html',
  styleUrl: './non-solar-dashboard.component.scss',
  imports: [
    TodayAlertsComponent,
    TrendsComponent,
    PowercutAndSummaryComponent,
    PlantDetailsComponent,
    TranslateModule,
    RadialBarComponent,
    CommonModule,
  ],
})
export class NonSolarDashboardComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private siService = inject(SiService);
  private installerId = '';
  powerCutDetailsAndSummary?: TodayPowerCutDetailsData;
  nonSolarPlant: {
    totalPlant?: number;
    healthyPlant?: number;
    unhealthyPlant?: number;
    healthyPlantPercent?: number;
  } = {};
  constructor() {
    super();
  }
  isData = new Subject<boolean>();
  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.baseUserService.sessionService.startBnWatch(this.router);
    this.installerId = this.authService.loggedUser.installer_id;

    this.fetchApiData();
    /**
     * Timer listener
     */
    this.baseUserService.timerService
      .getTimerObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((fetchData) => {
        if (fetchData) {
          this.fetchApiData();
        }
      });
  }
  fetchApiData() {
    this.resolveAllSubscriptions([
      this.fetchTotalNonSolarPlantDetails(),
      this.fetchTodayNonSolarPowerCutDetails(),
    ]);
    this.loadData(
      [
        this.baseUserService.todayAlertsLoading,
        this.baseUserService.trendsLoading,
        this.baseUserService.powerCutLoading,
        this.baseUserService.plantDetailsLoading,
      ],
      this.isData,
    );
  }

  /**
   * Fetch total plant details
   */
  fetchTotalNonSolarPlantDetails() {
    let loaderSubject = new Subject<boolean>();
    this.siService
      .getTotalPlantsSI(this.installerId, '3')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (value: TotalPlantsSI) => {
          if (value.data != null) {
            this.nonSolarPlant.totalPlant = value.data.totalPlants;
            this.fetchNonSolarPlantHealthDetails();
          }
          loaderSubject.next(true);
        },
        error: (e: Error) => {
          this.logger.error(e.message);
          loaderSubject.next(true);
        },
        complete: () => loaderSubject.complete(),
      });
    return loaderSubject;
  }

  /**
   * Fetch non-solar today alerts detail
   */
  fetchTodayAlertsDetail() {
    let loaderSubject = new Subject<boolean>();
    this.siService
      .getTodayAlertDetails(this.installerId, '3')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (value: TodayAlertDetails) => {
          if (value.data != null) {
            this.baseUserService.todayAlerts.next(value.data);
          }
          loaderSubject.next(true);
        },
        error: (e: Error) => {
          this.logger.error(e.message);
          loaderSubject.next(true);
        },
        complete: () => loaderSubject.complete(),
      });
    return loaderSubject;
  }

  /**
   * Fetch non-solar plant details
   */
  fetchNonSolarPlantDetail() {
    this.siService
      .getAllPlantDetails(this.installerId, '3')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (value: AllPlantDetails) => {},
        error: (e: Error) => {
          this.logger.error(e.message);
        },
      });
  }

  /**
   * Fetch non-solar plant health details
   */
  fetchNonSolarPlantHealthDetails() {
    let loaderSubject = new Subject<boolean>();

    this.siService
      .getPlantHealthDetails(this.installerId, '3')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (value: PlantHealthDetails) => {
          this.nonSolarPlant.healthyPlantPercent = Math.floor(
            value.data.nonSolarPlantsHealth,
          );
          if (this.nonSolarPlant.totalPlant != null) {
            this.nonSolarPlant.healthyPlant = Math.round(
              this.nonSolarPlant.totalPlant *
                (this.nonSolarPlant.healthyPlantPercent / 100),
            );
            this.nonSolarPlant.unhealthyPlant =
              this.nonSolarPlant.totalPlant - this.nonSolarPlant.healthyPlant;
          }
          loaderSubject.next(true);
        },
        error: (error) => {
          this.logger.error(error);
          loaderSubject.next(true);
        },
        complete: () => loaderSubject.complete(),
      });
    return loaderSubject;
  }

  /**
   * Fetch today power cut details
   */
  fetchTodayNonSolarPowerCutDetails() {
    let loaderSubject = new Subject<boolean>();
    this.siService
      .getTodayPowerCutDetails(this.installerId, '3')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (value: TodayPowerCutDetails) => {
          /*
          set battery progress bar width
          */
          if (value.data) {
            this.powerCutDetailsAndSummary = value.data;
          }

          loaderSubject.next(true);
        },
        error: (e: Error) => {
          this.logger.error(e.message);
          loaderSubject.next(true);
        },
        complete: () => loaderSubject.complete(),
      });
    return loaderSubject;
  }

  calculateProgress(arg0?: number): string {
    let pixel = convertValueToBoundary(arg0 ?? 0, 0, 100, 0, 60);
    return pixel + 'px';
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.destroy$.next();
    this.destroy$.complete();
    this.baseUserService.sessionService.stopBnTimer();
  }
}
