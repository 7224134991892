import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Location } from '@angular/common';
import { SessionService } from '../../../shared/service/session.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../../base/components/base.component';

@Component({
  selector: 'app-contact-us',
  standalone: true,
  imports: [],
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss',
})
export class ContactUsComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  sendEmail() {
    window.location.href = 'mailto:care@luminousindia.com';
  }

  constructor(private _location: Location) {
    super();
  }
  goBack(): void {
    this.baseUserService.back();
  }

  ngOnInit(): void {
    this.baseUserService.sessionService.startBnWatch(this.router);
  }
  ngOnDestroy(): void {
    this.baseUserService.sessionService.stopBnTimer();
  }
}
